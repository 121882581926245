/*
* --------------------------------------------------
* This file contains all styles related to the loader component.
* --------------------------------------------------
*/

/* default loader settings */
.br-loader {
  position: relative;

  display: inline-block;
  width: 5em;
  height: 1em;

  font-size: 1rem;
}

.br-loader_xs {
  font-size: 0.5rem;
}

.br-loader_s {
  font-size: 0.75rem;
}

.br-loader_l {
  font-size: 1.25rem;
}

.br-loader_xl {
  font-size: 1.5rem;
}

.br-loader div {
  position: absolute;
  top: calc( 50% - 0.5em );

  width: 1em;
  height: 1em;

  color: inherit;
  font-size: inherit;

  background: currentColor;
  border-radius: 50%;

  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.br-loader div:nth-child(1) {
  left: 0.5em;

  animation: br-loader1 0.6s infinite;
}

.br-loader div:nth-child(2) {
  left: 0.5em;

  animation: br-loader2 0.6s infinite;
}

.br-loader div:nth-child(3) {
  left: 2em;

  animation: br-loader2 0.6s infinite;
}

.br-loader div:nth-child(4) {
  left: 3.5em;

  animation: br-loader3 0.6s infinite;
}

@keyframes br-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes br-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes br-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.5em, 0);
  }
}
