.pager {
  display: flex;
  align-items: center;

  &__btn {
    font-size: 14px;
    font-weight: bold;
    border: dashed #666;
    border-width: 0 0 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    text-transform: uppercase;
    color: #666;
    background-color: transparent;
    cursor: pointer;
    white-space: nowrap;
    margin: 0 10px;

    &.btn_disabled,
    &[disabled],
    &.btn_disabled:hover,
    &[disabled]:hover {
      border-color: transparent;
      color: #bbb;
      cursor: default;
    }

    @media (min-resolution: 1.2dppx) and (max-resolution: 1.25dppx) {
     .page_with-sidebar & {
       font-size: 12px;
      }
    }

    @media print {
      display: none;
    }
  }

  &__btn:hover {
    border-color: transparent;
    color: #888;
  }

  &__btn-arr {
    text-decoration: none;
    font: bold 20px var(--font-family-monospace);

    @media (min-resolution: 1.2dppx) and (max-resolution: 1.25dppx) {
     .page_with-sidebar & {
       font-size: 16px;
       line-height: 18px;
      }
    }

    line-height: 14px;
  }

  &__btn-arr_next {
    margin-left: 5px;
  }

  &__btn-arr_prev {
    margin-right: 5px;
  }

  &__prev-buttons {
    display: flex;

    @media print {
      display: none;
    }
  }

  &__content {
    display: flex;
    margin: 0 10px;

    &:empty {
      display: none;
    }
  }

  &__next-buttons {
    display: flex;

    @media print {
      display: none;
    }
  }

  &__page-counter {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    text-align: center;
    margin-top: 0.2rem;
    color: var(--brand-highlight);
    user-select: none;
  }

  &__ins {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;

    @media (min-resolution: 1.2dppx) and (max-resolution: 1.25dppx) {
     .page_with-sidebar & {
       font-size: 13px;
      }
    }
  }

  &__separator {
    font-size: 14px;
    margin: 0 5px;
  }
}

.temul-pager.center-block{
  margin: auto;
}
