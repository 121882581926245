.dropdown-dialog-portal__dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--z-terminal-header) + 10);

  background-color: rgba(55, 55, 55, 0.5);
}

.dropdown-dialog-portal,
.dropdown-dialog-portal__alignment {
  --dropdown-animation-duration: 0.2s;
  --dropdown-dialog-bg: #ffffff;
}

.dropdown-dialog-portal__activator {
  display: inline-block;
  min-width: 1rem;
  min-height: 1rem;
}

.dropdown-dialog-portal__alignment {
  position: fixed;
  z-index: calc(var(--z-terminal-header) + 11);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}

.dropdown-dialog-portal__body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.dropdown-dialog-portal__body-wrap {
  background-color: var(--dropdown-dialog-bg);
  border-radius: 1.5rem;
  position: relative;
  animation-name: dialog-portal-bottom;
  animation-duration: var(--dropdown-animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin-top: 0.625rem;
  padding: 1.25rem;
}

.dropdown-dialog-portal__body-wrap_dropdown-top {
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropdown-dialog-portal__body-arrow {
  position: absolute;
  display: block;
  top: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-dialog-bg);
  border-left: 0.625rem solid transparent;
}

.dropdown-dialog-portal__body-arrow_dropdown-top {
  position: absolute;
  display: block;
  bottom: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-dialog-bg);
  border-left: 0.625rem solid transparent;
}

.dropdown-dialog-portal__close {
  position: absolute;
  top: 1.125rem;
  right: 0.75rem;

  display: block;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.25rem;

  color: #000000;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;

  border-radius: 50%;
}

.dropdown-dialog-portal__close:hover,
.dropdown-dialog-portal__close:focus {
  color: var(--dialog-color-hover);
  outline: none;
}

.dropdown-dialog-portal__close-icon {
  color: inherit;
  font-size: 1.25rem;
}

.dropdown-dialog-portal__footer {
  padding-top: 1rem;

  font-size: 0.875rem;
  text-align: center;
  text-transform: uppercase;
}

@keyframes dialog-portal-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
