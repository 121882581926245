/*
**
TWTT
- BAV SCREEN
- MYFLIGHTS SCREEN
- MYTOURISTS SCREEN
- UPLOAD SCREEN
*/

.av-sel-date {
  &__nav {
    position: relative;

    display: inline-block;
    width: 0.5rem;
    height: 0.75rem;

    vertical-align: middle;

    cursor: pointer;

    &_prev:before,
    &_next:after {
      position: absolute;
      top: 50%;
      left: 50%;

      display: block;
      box-sizing: border-box;
      width: 0.6rem;
      height: 0.6rem;

      margin-top: -0.3rem;
      margin-left: -0.3rem;

      content: '';

      border: solid var(--brand-secondary);

      transform-origin: 50% 50% 0;
    }

    &_prev_date {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &_next_date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &_prev:before {
      border-width: 2px 0 0 2px;
      transform: rotate(-45deg);
    }

    &_next:after {
      border-width: 2px 2px 0 0;
      transform: rotate(45deg);
    }

    &_prev_light:before {
      border-color: var(--color-lblue);
    }

    &_next_light:after {
      border-color: var(--color-lblue);
    }

    &_disabled {
      cursor: default;
      opacity: .5;
    }

    &_date {
      padding: 1rem;
    }
  }

  &__date {
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    background: transparent;
    border: none;
    text-transform: uppercase;
    width: 5em;

    &:hover {
      color: var(--brand-highlight);
    }
  }

  &__day,
  &__time {
    font-weight: bold;
  }
}
