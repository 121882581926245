/*
* --------------------------------------------------
* This file contains all styles related to the overlay component.
* --------------------------------------------------
*/

/* default overlay settings */
.br-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-overlay);
  background-color: var(--bg-overlay);
  pointer-events: none;

  &_fixed {
    position: fixed;
  }

  &_blur {
    backdrop-filter: blur(1px);
  }
}
