.br-selector-chevron__activator {
  text-align: center;
  min-width: 40px;
  margin: 0 0.25rem;
  line-height: 1.3;
  overflow: hidden;
  font-weight: bold;
  font-family: var(--font-family-sans-serif-arial);
  border-bottom: 1px solid #cccccc;
  border-bottom-color: var(--brand-highlight);
  height: 1.313rem;
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  padding: 0.125rem 1rem 0.125rem 0.25rem;
  flex-grow: 1;
}

.br-selector-chevron__activator_change{
  color: var(--brand-highlight);
}

.br-selector-chevron__activator_icon{
  display: flex;
  min-width: 50px;
  border-bottom: none;
}

.br-selector-chevron__activator:before {
  content: "";
  display: inline;
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  right: 0.1rem;
  top: 40%;
  color: #999999;
  border-style: solid;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.br-selector-chevron__prefix {
  display: flex;
  align-items: center;
}

.br-selector-chevron__prefix-text {
  margin-left: 0.5rem;
}

.br-selector-chevron__activator_nounderline {
  border-bottom: none;
}
