.screen_sm {
  .screen__body {
    pre {
      font-size: 28px;
    }

    .server_message {
      margin: 20px;
    }
  }
}
