:root {
  --g-select-colors--lightest: rgba(60, 60, 60, 0.26);
  --g-select-colors--light: rgba(60, 60, 60, 0.5);
  --g-select-colors--dark: #333;
  --g-select-colors--darkest: rgba(0, 0, 0, 0.15);

  /* Search Input */
  --g-select-search-input-color: inherit;
  --g-select-search-input-bg: rgb(255, 255, 255);
  --g-select-search-input-placeholder-color: inherit;

  /* Font */
  --g-select-font-size: 1rem;
  --g-select-line-height: 1.4;

  /* Disabled State */
  --g-select-state-disabled-bg: rgb(248, 248, 248);
  --g-select-state-disabled-color: var(--g-select-colors--light);
  --g-select-state-disabled-controls-color: var(--g-select-colors--light);
  --g-select-state-disabled-cursor: not-allowed;

  /* Borders */
  --g-select-border-color: var(--g-select-colors--lightest);
  --g-select-border-width: 1px;
  --g-select-border-style: solid;
  --g-select-border-radius: 4px;

  /* Actions: house the component controls */
  --g-select-actions-padding: 4px 6px 0 3px;

  /* Component Controls: Clear, Open Indicator */
  --g-select-controls-color: var(--g-select-colors--light);
  --g-select-controls-size: 1;
  --g-select-controls--deselect-text-shadow: 0 1px 0 #fff;

  /* Selected */
  --g-select-selected-bg: #f0f0f0;
  --g-select-selected-color: var(--g-select-colors--dark);
  --g-select-selected-border-color: var(--g-select-border-color);
  --g-select-selected-border-style: var(--g-select-border-style);
  --g-select-selected-border-width: var(--g-select-border-width);

  /* Dropdown */
  --g-select-dropdown-bg: #fff;
  --g-select-dropdown-color: inherit;
  --g-select-dropdown-z-index: 1000;
  --g-select-dropdown-min-width: 160px;
  --g-select-dropdown-max-height: 350px;
  --g-select-dropdown-box-shadow: 0px 3px 6px 0px var(--g-select-colors--darkest);

  /* Options */
  --g-select-dropdown-option-bg: #000;
  --g-select-dropdown-option-color: var(--g-select-dropdown-color);
  --g-select-dropdown-option-padding: 3px 20px;

  /* Active State */
  --g-select-dropdown-option--active-bg: #5897fb;
  --g-select-dropdown-option--active-color: #fff;

  /* Deselect State */
  --g-select-dropdown-option--deselect-bg: #fb5858;
  --g-select-dropdown-option--deselect-color: #fff;

  /* Transitions */
  --g-select-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --g-select-transition-duration: 150ms;
}


.v-select {
  position: relative;
  font-family: inherit
}

.v-select, .v-select * {
  box-sizing: border-box
}

@-webkit-keyframes gSelectSpinner {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

@keyframes gSelectSpinner {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.g-select__fade-enter-active, .g-select__fade-leave-active {
  pointer-events: none;
  transition: opacity .15s cubic-bezier(1, .5, .8, 1)
}

.g-select__fade-enter, .g-select__fade-leave-to {
  opacity: 0
}

.g-select--disabled .g-select__clear, .g-select--disabled .g-select__dropdown-toggle, .g-select--disabled .g-select__open-indicator, .g-select--disabled .g-select__search, .g-select--disabled .g-select__selected {
  cursor: not-allowed;
  background-color: #f8f8f8
}

.v-select[dir=rtl] .g-select__actions {
  padding: 0 3px 0 6px
}

.v-select[dir=rtl] .g-select__clear {
  margin-left: 6px;
  margin-right: 0
}

.v-select[dir=rtl] .g-select__deselect {
  margin-left: 0;
  margin-right: 2px
}

.v-select[dir=rtl] .g-select__dropdown-menu {
  text-align: right
}

.g-select__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: 0 0 4px;
  background: none;
  border: 1px solid rgba(60, 60, 60, .26);
  border-radius: 4px;
  white-space: normal
}

.g-select__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative
}

.g-select__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px
}

.g-select--searchable .g-select__dropdown-toggle {
  cursor: text
}

.g-select--unsearchable .g-select__dropdown-toggle {
  cursor: pointer
}

.g-select--open .g-select__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.g-select__open-indicator {
  fill: rgba(60, 60, 60, .5);
  transform: scale(1);
  transition: transform .15s cubic-bezier(1, -.115, .975, .855);
  transition-timing-function: cubic-bezier(1, -.115, .975, .855)
}

.g-select--open .g-select__open-indicator {
  transform: rotate(180deg) scale(1)
}

.g-select--loading .g-select__open-indicator {
  opacity: 0
}

.g-select__clear {
  fill: rgba(60, 60, 60, .5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px
}

.g-select__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .15);
  border: 1px solid rgba(60, 60, 60, .26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff
}

.g-select__no-options {
  text-align: center
}

.g-select__dropdown-option {
  line-height: 1.42857143;
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  white-space: nowrap
}

.g-select__dropdown-option:hover {
  cursor: pointer
}

.g-select__dropdown-option--highlight {
  background: #5897fb;
  color: #fff
}

.g-select__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, .5)
}

.g-select__dropdown-option--disabled:hover {
  cursor: inherit
}

.g-select__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, .26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0;
  padding: 0 .25em;
  z-index: 0
}

.g-select__deselect {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, .5);
  text-shadow: 0 1px 0 #fff
}

.g-select--single .g-select__selected {
  background-color: transparent;
  border-color: transparent
}

.g-select--single.g-select--open .g-select__selected {
  position: absolute;
  opacity: .4
}

.g-select--single.g-select--searching .g-select__selected {
  display: none
}

.g-select__search::-webkit-search-cancel-button {
  display: none
}

.g-select__search::-ms-clear, .g-select__search::-webkit-search-decoration, .g-select__search::-webkit-search-results-button, .g-select__search::-webkit-search-results-decoration {
  display: none
}

.g-select__search, .g-select__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1
}

.g-select__search::-webkit-input-placeholder {
  color: inherit
}

.g-select__search::-moz-placeholder {
  color: inherit
}

.g-select__search:-ms-input-placeholder {
  color: inherit
}

.g-select__search::-ms-input-placeholder {
  color: inherit
}

.g-select__search::placeholder {
  color: inherit
}

.g-select--unsearchable .g-select__search {
  opacity: 1
}

.g-select--unsearchable:not(.g-select--disabled) .g-select__search:hover {
  cursor: pointer
}

.g-select--single.g-select--searching:not(.g-select--open):not(.g-select--loading) .g-select__search {
  opacity: .2
}

.g-select__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: .9em solid hsla(0, 0%, 39.2%, .1);
  border-left-color: rgba(60, 60, 60, .45);
  transform: translateZ(0);
  -webkit-animation: gSelectSpinner 1.1s linear infinite;
  animation: gSelectSpinner 1.1s linear infinite;
  transition: opacity .1s
}

.g-select__spinner, .g-select__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em
}

.g-select--loading .g-select__spinner {
  opacity: 1
}
