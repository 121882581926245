<script>
const tr = require('l10n').translator;

import debounce from "lodash/function/debounce";

const FeatureLoader = require('tais-feature-loader/feature-loader');
const FeatureVersions = require('tais-model/feature-versions');
const toggler = require('tais-pages/components/toggle');
const headers = require('tais-model/feature-config/headers');

const mapGetters = require('vuex').mapGetters;
const mapState = require('vuex').mapState;
const mapMutations = require('vuex').mapMutations;

const featureInList = require('tais-vue/parts/features-config/feature-in-list.vue');
const tagsPanel = require('tais-vue/parts/features-config/tags-panel.vue');
const saveElement = require('tais-vue/parts/features-config/footer/save-element.vue');
const confirmModal = require("tais-vue/components/confirm-modal.vue");

const labels = {
  all: tr.msg('Все'),
  featuresWithErrors: tr.msg('Фичи с ошибками'),
  noStableVersion: tr.msg('Нет версий stable'),
  noBetaVersion: tr.msg('Нет версии beta'),
  localChanged: tr.msg('Измененные локально'),
  onLoginChanged: tr.msg('Измененные при логине'),
  featuresWithoutErrors: tr.msg('Фичи без ошибок'),
  screenTitle: tr.msg('Настройка версий фич'),
  searchPlaceholder: tr.msg('Поиск по фичам (номер или название)'),
  compareFeature: tr.msg('Сравнить'),
  resetAll: tr.msg('Сбросить все'),
  updatePage: tr.msg('Обновить страницу')
};

export default {

  components: {
    confirmModal: confirmModal.default,
    featureInList: featureInList.default,
    tagsPanel: tagsPanel.default,
    saveElement: saveElement.default
  },

  data: function() {
    return {
      allowSaveFiles: true,
      isCheckedActualityFeatures: false,
      classes: {
        tabCls: 'pwd-config-top__group-color-item',
        selectedTab: 'pwd-config-top__group-color-item_selected'
      },
      dependencies: false,
      allTabs: [],
      labels: labels,
      headers: headers,
      messages: {
        noFeatures: tr.msg('Нет фич, подходящих под заданные условия фильтрации'),
        confirm: tr.msg('Все измененные локально версии фич будут сброшены к оригинальным версиям, вы согласны ?')
      },
      showCompareModal: false,
      showConfirmModal: false
    };
  },

  computed: {
    ...mapGetters({
      featuresOrigin: "featuresOrigin",
      filteredFeatures: 'filteredFeatures',
      terminalData: 'terminalData',
      categoryCounts: 'categoryCounts'
    }),

    ...mapState({
        searchQuery(state) {
          return state.searchQuery || '';
        },

        selectedCategory(state) {
          return state.selectedCategory || '';
        }
      }
    ),

    featuresList() {
      if (this.dependencies) {
        return this.$store.getters.getFeatures.filter(feature => Object.keys(this.dependencies).includes(feature.name));
      }

      return this.filteredFeatures;
    },

    tabsConfig() {
      return [
        {
          param: 'allFeature',
          index: 0,
          cls: 'pwd-config-top__color_all',
          text: this.labels.all
        },
        {
          param: 'hasErrors',
          index: 5,
          cls: 'pwd-config-top__color_has-error',
          text: this.labels.featuresWithErrors
        },
        {
          param: 'missStable',
          index: 3,
          cls: 'pwd-config-top__color_no-stable',
          text: this.labels.noStableVersion
        },
        {
          param: 'missBeta',
          index: 4,
          cls: 'pwd-config-top__color_no-beta',
          text: this.labels.noBetaVersion
        },
        {
          param: 'hasLocalChanges',
          index: 1,
          cls: 'pwd-config-top__color_local-changed',
          text: this.labels.localChanged
        },
        {
          param: 'hasLoginChanges',
          index: 2,
          cls: 'pwd-config-top__color_on-login-changed',
          text: this.labels.onLoginChanged
        },
        {
          param: 'differentVersion',
          index: 6,
          cls: 'pwd-config-top__color_different',
          text: this.labels.featuresWithoutErrors
        }
      ];
    }
  },

  methods: {
    ...mapMutations({
      setSearchQuery: 'setSearchQuery',
      setSelectedCategory: 'setSelectedCategory',
      updateAllFeatures: 'updateAllFeatures'
    }),

    showDependencies: function(dependencies, name) {
      const dep = $.extend(dependencies, {});
      dep[name] = true;
      this.dependencies = dep;
    },

    scrollToFeature: function(name) {
      const allTab = document.querySelector('[data-change="allFeature"]');
      if (allTab) {
        const event = {
          currentTarget: allTab
        };

        this.selectCategory(event);
        this.setSearchQuery('');

        this.$nextTick(() => {
          const feature = document.querySelector(`[data-feature="${name}"]`);
          if (feature) {
            feature.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest"
            });

            feature.classList.add('the-feature-you-were-looking-for');
            setTimeout(() => {
              feature.classList.remove('the-feature-you-were-looking-for');
            }, 1500);
          }
        });
      }

    },

    debouncedSearch: debounce(function(value) {
      this.setSearchQuery(value);
    }, 500),

    onResetFeaturesClick: function() {
      FeatureVersions.reset();
      FeatureLoader.loadAllFeatures(this.terminalData)
        .then(data => {
          this.updateAllFeatures(data);
          return null;
        });
    },

    onApplyFeaturesClick: function(event) {
      window.location.reload(true);
    },

    selectCategory: function(event) {
      const tab = event.currentTarget;
      this.unselectTab();
      this.selectTab(tab);
      this.setSelectedCategory(tab.dataset.change);
    },

    unselectTab: function() {
      this.allTabs.removeClass(this.classes.selectedTab);
    },

    selectTab: function(tab) {
      $(tab).addClass(this.classes.selectedTab);
    },

    onShowCompare: function() {
      this.$emit('show-compare-modal');
    },

    confirm: function(confirmStatus) {
      if (confirmStatus) {
        this.onResetFeaturesClick();
      }

      this.showConfirmModal = false;
    }
  },

  mounted: function() {
    this.allTabs = $(this.$el).find('.' + this.classes.tabCls);
    this.selectTab(this.allTabs.first());
    toggler($(this.$refs.saveButtonContainer));
  }
};
</script>

<template>
  <div class="feature-config__outer">
    <header class="screen__header">
      <div class="screen__header-cont">
        <section class="pwd-config-header">
          <h1 class="screen__name">
            <span class="screen__name-title">
              {{ labels.screenTitle }}
            </span>
            <span class="screen__name-subtitle"></span>
          </h1>
          <div class="pwd-config-header__wrapper">
            <tags-panel/>
            <div class="pwd-config-header__search pwd-config-top__feature-search">
              <label class="pwd-config-header__search-label">
                <input
                  :value="searchQuery"
                  @input="debouncedSearch($event)"
                  class="pwd-config-header__search-field"
                  :placeholder="labels.searchPlaceholder"
                >
              </label>
            </div>
          </div>

        </section>
      </div>
    </header>

    <section class="pwd-config-top">
      <div class="pwd-config-top__controls">
        <ul class="pwd-config-top__group-colors">
          <li
            v-for="(tab, index) in tabsConfig"
            :key="'tab_'+ index"
            @click="selectCategory"
            class="pwd-config-top__group-color-item"
            :data-change="tab.param"
            :data-index="tab.index"
          >
            <ins :class="'count-features pwd-config-top__color ' + tab.cls">
              {{ categoryCounts[tab.param] }}
            </ins>
            <dfn class="pwd-config-top__group-color-text">{{ tab.text }}</dfn>
          </li>
        </ul>
      </div>
    </section>

    <section class="pwd-config-top__headers-wrap">
      <div class="pwd-config-top__headers">
        <h4 class="pwd-config-top__section-head">{{ headers.featureDescription }}</h4>
        <h4 class="pwd-config-top__section-head">{{ headers.versions }}</h4>
        <h4 class="pwd-config-top__section-head">{{ headers.actions }}</h4>
        <h4 class="pwd-config-top__section-head">{{ headers.status }}</h4>
      </div>
    </section>

    <div class="screen__body wrapper">
      <div class="screen__scroller">
        <div class="twx-features">
          <template v-if="featuresList.length > 0">
            <feature-in-list
              v-for="feature in filteredFeatures"
              :feature="feature"
              :key="feature.name"
              @showDependencies="showDependencies"
              @scroll-to-feature="scrollToFeature"
            />
          </template>
          <div
            v-else
            class="twx-features__no-features"
          >
            {{ messages.noFeatures }}
          </div>
        </div>
      </div>
    </div>

    <div class="screen__footer pwd-config-footer twx-footer-flex">
      <div v-if="allowSaveFiles" class="text-left twx-footer-flex">
        <save-element/>
        <div class="input-load-wrapper">
          <button @click="onShowCompare" class="btn btn_lg btn_primary compare-button">
            {{ labels.compareFeature }}
          </button>
        </div>
      </div>
      <div class="flex_width-available text-right">
        <span class="link link_warn pwd-config-footer__errors"></span>
        <button @click="() => showConfirmModal = true" class="btn btn_lg btn-reset-all-features">
          {{ labels.resetAll }}
        </button>
        <button @click="onApplyFeaturesClick" class="btn btn_lg btn_primary btn-apply">
          {{ labels.updatePage }}
        </button>
      </div>
    </div>

    <confirm-modal
      :questions="messages.confirm"
      :show-modal="showConfirmModal"
      @close="()=>showConfirmModal = false"
      @confirm="confirm"
    />
  </div>
</template>

<style scoped lang="cssnext">
.wrapper {
  height: 65vh;
}

.text-left,
.text-right {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}

.count-features {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
</style>
