<template>
  <div class="bp-tooltip">
    <slot></slot>
    <slot name="ms1"></slot>
    <slot name="ms2"></slot>
    <slot name="ms3"></slot>
    <slot name="ms4"></slot>
    <slot name="ms5"></slot>
  </div>
</template>

<script>
module.exports = {};
</script>

<style lang="cssnext" scoped>
.bp-tooltip {
  position: absolute;
  z-index: 2;

  top: calc(100% + 10px);
  left: 50%;

  transform: translateX(-50%);

  padding: 10px 15px;
  border-radius: 10px;

  background: rgba(82, 82, 82, 1);
  color: white;

  display: none;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &:after {
    content: '';
    display: block;

    width: 0;
    height: 0;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 8px solid rgba(82, 82, 82, 1);

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -99%);
  }

}
</style>
