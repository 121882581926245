/*
* --------------------------------------------------
* This file contains all styles related to the dropdown selector component.
* --------------------------------------------------
*/

/* default selector settings */
.br-selector {
  --selector-color-normal: #000000;
  --selector-color-primary: var(--brand-highlight);
  --selector-color-error: #ff0000;
  --selector-color-success: #16a803;
  --selector-color-disabled: #cccccc;
  --selector-color-hover: var(--brand-highlight-sec);

  --selector-bg-normal: #ffffff;

  --selector-animation-duration:  0.2s;
}

.br-selector {
  position: relative;
}

.br-selector__activator {
  min-width: 1rem;
  min-height: 1rem;

  &_triangle {
    &:before{
      content: '';
      z-index: 9999;
      position: absolute;
      display: block;
      top: 1.4rem;
      left: calc( 50% - 0.625rem );
      border-right: 0.625rem solid transparent;
      border-bottom: 0.625rem solid white;
      border-left: 0.625rem solid transparent;
    }
  }
}

.br-selector__alignment {
  position: absolute;
  right: 0;
  left: 0;
  z-index: calc( var(--z-loader) - 2 );

  display: flex;
  justify-content: center;
  align-items: center;
}

.br-selector__body {
  position: relative;

  padding: 1rem 2.5rem 1rem 0.5rem;

  color: var(--selector-color-normal);

  background-color: var(--selector-bg-normal);
  border-radius: 1.25rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  animation-name: none;
  animation-duration: var(--selector-animation-duration);
  animation-timing-function: ease-in-out;

  animation-fill-mode: forwards;
}

.br-selector__body::before {
  content: '';
  position: absolute;

  display: block;
}

.br-selector__body{
  &_triangle{
    &:before{
      content: none;
    }
  }
}

.br-selector__close {
  position: absolute;
  top: 1.125rem;
  right: 0.75rem;

  display: block;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.25rem;

  color: #000000;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;

  border-radius: 50%;
}

.br-selector__close:hover,
.br-selector__close:focus {
  color: var(--selector-color-hover);
  outline: none;
}

.br-selector__close-icon {
  color: inherit;
  font-size: 1.25rem;
}

.br-selector__list {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
}

.br-selector__list-table {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.br-selector__list-flex {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
  display: flex;
}

.br-selector__list::-webkit-scrollbar {
  width: 0.5rem;
}

.br-selector__list-item {
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.br-selector__list-button,
.br-selector__list-button.br-button {
  padding: 10px;
  justify-content: flex-start;
  align-items: center;

  font-weight: normal;
  color: #000000;

  background: transparent;
  border: 1px solid transparent;
}

.br-selector__list-item_gap {
  margin-bottom: 1rem;
  position: relative;

  &:after{
    content: '';
    position: absolute;

    left: 0;
    top: calc(100% + 0.5rem);

    background: #cccccc;

    width: 100%;
    height: 1px;

  }
}

.br-selector__list-button.br-button.br-button_primary {
  color: #ffffff;
  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-selector__list-button:hover,
.br-selector__list-button.br-button:hover {
  border-color: var(--selector-color-primary);
}

/* positioning variations */
.br-selector_top {
  .br-selector__alignment {
    top: auto;
    right: 0;
    bottom: 100%;
    left: 0;

    justify-content: center;
    align-items: flex-end;
  }

  .br-selector__body {
    margin-bottom: 0.625rem;

    animation-name: selector-top;
  }

  .br-selector__body::before {
    top: auto;
    right: auto;
    bottom: -0.5625rem;
    left: calc( 50% - 0.625rem );

    border-top: 0.625rem solid var(--selector-bg-normal);
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;
  }
}

.br-selector_start.br-selector_top {
  .br-selector__alignment {
    justify-content: flex-start;
  }

  .br-selector__body::before {
    left: 3rem;
  }
}

.br-selector_end.br-selector_top .br-selector__alignment {
  justify-content: flex-end;
}

.br-selector_right {
  .br-selector__alignment {
    top: 0;
    right: auto;
    bottom: 0;
    left: 100%;

    justify-content: flex-start;
    align-items: center;
  }

  .br-selector__body {
    flex: 1 1 auto;
    margin-left: 0.625rem;

    animation-name: selector-right;
  }

  .br-selector__body::before {
    top: calc( 50% - 0.625rem );
    right: auto;
    bottom: auto;
    left: -0.5625rem;

    border-top: 0.625rem solid transparent;
    border-right: 0.625rem solid var(--selector-bg-normal);
    border-bottom: 0.625rem solid transparent;
  }
}

.br-selector_bottom {
  .br-selector__alignment {
    top: 100%;
    right: 0;
    bottom: auto;
    left: 0;

    justify-content: center;
    align-items: flex-start;
  }

  .br-selector__body {
    margin-top: 0.625rem;

    animation-name: selector-bottom;
  }

  .br-selector__body::before {
    top: -0.5625rem;
    left: calc( 50% - 0.625rem );

    border-right: 0.625rem solid transparent;
    border-bottom: 0.625rem solid var(--selector-bg-normal);
    border-left: 0.625rem solid transparent;
  }
}

.br-selector_start.br-selector_bottom {
  .br-selector__alignment {
    justify-content: flex-start;
  }

  .br-selector__body::before {
    left: 3rem;
  }
}

.br-selector_end.br-selector_bottom .br-selector__alignment {
  justify-content: flex-end;
}

.br-selector_left {
  .br-selector__alignment {
    top: 0;
    right: 100%;
    bottom: 0;
    left: auto;

    justify-content: flex-end;
    align-items: center;
  }

  .br-selector__body {
    flex: 1 1 auto;
    margin-right: 0.625rem;

    animation-name: selector-left;
  }

  .br-selector__body::before {
    top: calc( 50% - 0.625rem );
    right: -0.5625rem;
    bottom: auto;
    left: auto;

    border-top: 0.625rem solid transparent;
    border-bottom: 0.625rem solid transparent;
    border-left: 0.625rem solid var(--selector-bg-normal);
  }
}

.br-selector.br-selector_arrow-left .br-selector__body::before {
  right: unset;
  left: 0.3125rem;
}

@keyframes selector-top {
  0% {
    transform: translate(0, -0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes selector-right {
  0% {
    transform: translate(0.5em, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes selector-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes selector-left {
  0% {
    transform: translate(-0.5em, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
