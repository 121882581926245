.readonly-cols-box {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;

  &__col {
    display: grid;
    grid-gap: 4px;
    justify-items: start;
  }

  &__header {
    color: #525252;
    font-size: 8px;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;

    .rex-value-item__value & {
      min-height: 0.875rem;
      line-height: 0.875rem;
      color: #ffffff;
      font-weight: bold;
    }
  }

  &__badge-code {
    border: 1px solid #999;
    border-radius: 5px;
    color: #999;
    font: bold 14px/16px var(--font-family-sans-serif-arial);
    padding: 2px 6px;

    .rex-value-item .rex-value-item__data & {
      color: white;
      padding: 0 0.375rem;
    }
  }
}
