.br-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 0.5rem;
}

.br-header__section {
  display: flex;
}

.br-header__section_left {
  text-align: left;
  justify-content: flex-start;
}

.br-header__section_right {
  text-align: right;
  justify-content: flex-end;
}

.br-header__section_center {
  text-align: center;
  justify-content: center;
}

.br-header .filter-selected-value {
  height: auto;
  font-size: 1rem;
  text-transform: uppercase;
}
