.screen_rs {

  &__gds-1a {
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .header-list__text__inner {
      margin-left: 70px !important;
    }
  }
}
