/*
size of the icon depends on the setting:
small
default - 0.375rem x 1.625rem / 6px x 26px
large - 0.5rem x 2.25rem / 8px x 36px
*/

.vc-dots-btn {
  --icon-color-default: var(--color-light);
  --icon-color-disabled: var(--color-disabled);
}

.vc-dots-btn {
  --icon-color: var(--icon-color-default);

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.375rem;
  height: 1.625rem;
  padding: 0;

  font-size: inherit;

  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;

  user-select: none;
}

.vc-dots-btn_disanled {
  --icon-color: var(--icon-color-disabled);

  cursor: default;
}

.vc-dots-btn__icon,
.vc-dots-btn__icon::before,
.vc-dots-btn__icon::after {
  position: relative;

  display: block;
  flex: 0 0 auto;
  width: 0.3125rem;
  height: 0.3125rem;

  background-color: var(--icon-color);
  border-radius: 50%;
}

.vc-dots-btn__icon::before,
.vc-dots-btn__icon::after {
  content: '';
  position: absolute;
}

.vc-dots-btn__icon::before {
  top: auto;
  bottom: calc(100% + 0.25rem);
}

.vc-dots-btn__icon::after {
  top: calc(100% + 0.25rem);
  bottom: auto;
}

.vc-dots-btn_s {
}

.vc-dots-btn_l {
  width: 0.5rem;
  height: 2.25rem;
}

.vc-dots-btn_l .vc-dots-btn__icon,
.vc-dots-btn_l .vc-dots-btn__icon::before,
.vc-dots-btn_l .vc-dots-btn__icon::after {
  width: 0.5rem;
  height: 0.5rem;
}

.vc-dots-btn_l .vc-dots-btn__icon::before {
  bottom: calc(100% + 0.3125rem);
}

.vc-dots-btn_l .vc-dots-btn__icon::after {
  top: calc(100% + 0.3125rem);
}

.vc-dots-btn__icon {
  position: relative;

  display: inline-block;
  width: auto;
  height: 1em;
  margin: auto;
  padding: 0;

  font-size: inherit;
  color: inherit;
}

.vc-dots-btn_disabled {
  color: var(--icon-color-disabled);
}
