.seat-pools {
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: baseline;
  background-color: #fff;
  padding: 10px 0 2px 0;
  border-bottom: 2px solid #eee;

  &__header, &__concrete {
    margin: 0 10px 0 0;
    font-size: 15px;
    font-weight: bold;
    color: #666;
  }

  &__concrete {
    margin-left: 30px;

    &:first-of-type {
      margin: 0;
    }
  }

  &__class {
    font-style: italic;
    padding-left: 5px;
    padding-top: 5px;
  }

  &__item {
    font: bold 14px var(--font-family-sans-serif);
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }

    .dropdown__body & {
      margin: 1px 0;
    }
  }

  &__item-wrap {
    background-color: #999;
    color: #fff;
    padding: 6px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    &:hover {
      cursor: pointer;
      background-color: #ccc;
      color: #333;
    }

    .dropdown__switcher & {
      padding-right: 18px;
    }

    .dropdown__body & {
      border-radius: 6px;
    }
  }

  .select__tick {
    margin-right: 6px;
  }

  .dropdown.dropdown__not-selected:not(:hover) .select__tick {
    border-color: #fff;
  }

  .dropdown__body {
    flex-direction: column;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__item-wrap_active,
  &__item-wrap_active:hover {
    background-color: #fff;
    color: #666;
    padding-top: 7px;
    border: 2px solid #666;
  }

  &__item-code {
    font-family: var(--font-family-sans-serif-arial);
  }
}
