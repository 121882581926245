/*
* --------------------------------------------------
* This file contains all styles related to the checkbox component.
* --------------------------------------------------
*/

/* default checkbox settings */
.br-checkbox {
  --check-font-size-small: 0.875rem;
  --check-font-size-default: 1rem;
  --check-font-size-large: 1.125rem;

  --check-box-bg-normal: #ffffff;

  --check-color-normal: #000000;

  --check-box-color-normal: var(--brand-highlight);
  --check-box-color-primary: var(--brand-highlight);
  --check-box-color-error: #ff0000;
  --check-box-color-success: #16a803;
  --check-box-color-disabled: #ccc;
  --check-box-color-hover: var(--brand-highlight-sec);

  --check-color-hover: var(--brand-highlight-sec);
}

.br-checkbox {
  --check-font-size: var(--check-font-size-default);

  position: relative;

  display: inline-flex;

  color: var(--check-color-normal);
  font-size: var(--check-font-size);
  vertical-align: middle;

  cursor: pointer;

  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: color, transform, opacity;
}

.br-checkbox:focus {
  outline: none;
}

.br-checkbox:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--focus-visible-outline-color);
  border-radius: 0.125rem;
}

.br-checkbox_full-width {
  display: flex;
  width: 100%;
}

.br-checkbox_uppercase {
  text-transform: uppercase;
}

.br-checkbox_font_s {
  --check-font-size: var(--check-font-size-small);
}

.br-checkbox_font_l {
  --check-font-size: var(--check-font-size-large);
}

.br-checkbox__control {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.br-checkbox__box {
  position: relative;

  display: inline-block;
  flex: 0 0 auto;
  width: 1.75em;
  height: 1.75em;
  margin-right: 0.5em;

  font-size: inherit;

  border-radius: 50%;
}

.br-checkbox__box:before,
.br-checkbox__box:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  content: '';
  font-size: inherit;
}

.br-checkbox__box:before {
  background-color: var(--check-box-bg-normal);

  border: 2px solid var(--check-box-color-normal);
  border-radius: 50%;

  transition: background-color 0.1s, border-color 0.1s;
}

.br-checkbox__box:after {
  background-image: url(tais-styles/svg/br-check.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;

  transform: translateY(-0.25rem);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.05s ease-out, opacity 0.02s ease-out, visibility 0s linear;
}

.br-checkbox_indeterminate .br-checkbox__box {
  &:before {
    background-color: var(--check-box-color-disabled);
    border-color: var(--check-box-color-disabled);
  }

  &:after {
    background-image: url(tais-styles/svg/minus-white.svg);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    width: 50%;
    left: 25%;
  }
}

.br-checkbox__control:checked ~ .br-checkbox__box:before,
.br-checkbox_checked .br-checkbox__box:before {
  background-color: var(--check-box-color-normal);
}

.br-checkbox__control:checked ~ .br-checkbox__box:after,
.br-checkbox_checked .br-checkbox__box:after {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.br-checkbox:hover .br-checkbox__box:before,
.br-checkbox:focus-visible .br-checkbox__box:before,
.br-checkbox__control:focus-visible:before {
  border-color: var(--check-box-color-hover);
}

.br-checkbox__label {
  position: relative;

  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  align-items: center;

  line-height: normal;
  color: inherit;

  cursor: pointer;
}

.br-checkbox:focus-visible .br-checkbox__label,
.br-checkbox__control:focus-visible ~ .br-checkbox__label,
.br-checkbox__label:focus-visible {
  color: var(--check-color-hover);
}

.br-checkbox_primary .br-checkbox__box:before {
  border-color: var(--check-box-color-primary);
}

.br-checkbox_primary.br-checkbox_checked .br-checkbox__box:before {
  background-color: var(--check-box-color-primary);
}

.br-checkbox_success .br-checkbox__box:before {
  border-color: var(--check-box-color-success);
}

.br-checkbox_success.br-checkbox_checked .br-checkbox__box:before {
  background-color: var(--check-box-color-success);
}

.br-error .br-checkbox__box:before {
  border-color: var(--check-box-color-error);
}

.br-error.br-checkbox_checked .br-checkbox__box:before {
  background-color: var(--check-box-color-error);
}

.br-checkbox_disabled,
.br-checkbox[disabled] {
  cursor: default;

  pointer-events: none;

  & .br-checkbox__label {
    color: var(--check-box-color-disabled);
  }
}

.br-checkbox_disabled .br-checkbox__box:before,
.br-checkbox[disabled] .br-checkbox__box:before {
  border-color: var(--check-box-color-disabled) !important;
}

.br-checkbox_disabled.br-checkbox_checked .br-checkbox__box:before,
.br-checkbox[disabled].br-checkbox_checked .br-checkbox__box:before {
  background-color: var(--check-box-color-disabled) !important;
}

.br-checkbox .vc-key-hint {
  position: absolute;
  top: -1rem;
  text-transform: none;
}

.br-checkbox_disabled  .vc-key-hint {
  display: none;
}
