.screen_rsa,
.screen_rso,
.screen_rs {

  .forPrint {
    display: none;
  }

  .header-list__text__inner {
    margin-left: 1.2rem;
    font-size: var(--font-size-m);
  }

  .agent-report-list {
    margin-top: 0.75rem;

    .agent-report-list__item {
      margin-left: 0.525rem;
      margin-right: 0.525rem;
      margin-top: 0.5rem;

      .agent-report-list__text {
        font-size: var(--font-size-m);
      }

      .agent-report-list__number {
        color: var(--brand-highlight);
      }
    }

    .agent-report-list__item:first-child {
      margin-top: 0;
    }
  }

  .rs-print__btn {
    display: inline-flex;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: url("tais-styles/svg/printing.svg") 50% 50% no-repeat;
  }

  .rs-paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    margin: 1.25rem 0 2rem;
  }

  .agent-report-list__container {
    margin-bottom: 1rem;
  }

  .agent-report-list__error {
    margin: 1rem 0 2rem 1rem;
  }
}

@media print {
  .forPrint {
    display: contents !important;;
  }

  .noPrint {
    display: none;
  }
}
