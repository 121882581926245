.dropdown-selector-portal__dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--z-terminal-header) + 10);

  background-color: rgba(55, 55, 55, 0.5);
}

.dropdown-selector-portal,
.dropdown-selector-portal__alignment {
  --dropdown-animation-duration: 0.2s;
  --dropdown-selector-bg: #ffffff;
}

.dropdown-selector-portal__activator {
  display: inline-block;
  min-width: 1rem;
  min-height: 1rem;
}

.dropdown-selector-portal__alignment {
  position: fixed;
  z-index: calc(var(--z-terminal-header) + 11);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}

.dropdown-selector-portal__body {
  background-color: var(--dropdown-selector-bg);
  border-radius: 1.5rem;
  padding: 1rem 3rem 1rem 0.5rem;
}

.dropdown-selector-portal__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dropdown-selector-portal__list-item:not(:last-child) {
  margin-bottom: 0.125rem;
}

.dropdown-selector-portal__selector-button {
  font-weight: normal !important;
}

.dropdown-selector-portal__body-wrap {
  position: relative;
  animation-name: selector-portal-bottom;
  animation-duration: var(--dropdown-animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin-top: 0.625rem;
}

.dropdown-selector-portal__body-wrap_dropdown-top {
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.dropdown-selector-portal__body-arrow {
  position: absolute;
  display: block;
  top: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-selector-bg);
  border-left: 0.625rem solid transparent;
}

.dropdown-selector-portal__body-arrow_dropdown-top {
  position: absolute;
  display: block;
  bottom: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-selector-bg);
  border-left: 0.625rem solid transparent;
}

.dropdown-selector-portal__close {
  position: absolute;
  top: 1.125rem;
  right: 0.75rem;

  display: block;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.25rem;

  color: #000000;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;

  border-radius: 50%;
}

.dropdown-selector-portal__close:hover,
.dropdown-selector-portal__close:focus {
  color: var(--selector-color-hover);
  outline: none;
}

.dropdown-selector-portal__close-icon {
  color: inherit;
  font-size: 1.25rem;
}

@keyframes selector-portal-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
