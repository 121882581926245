/*
size of the icon depends on the setting:
xsmall - 0.5rem / 8px x 8px
small - 0.75rem / 12px x 12px
default - 1rem / 16px x 16px, arrow size 8px x 10px
medium - 1.125rem / 18px x 18px
large - 1.375rem / 22px x 22px
xlarge - 1.5rem / 24px x 24px
*/
.br-icon-toggler {
  display: block;
  width: 1em;
  height: 1em;

  font-size: inherit;
  text-align: center;
  color: inherit;
}

.br-icon-toggler__icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  padding: 0;
  overflow: hidden;

  font-size: inherit;

  background-color: currentColor;

  transition: transform 0.1s ease-in-out;

  mask-image: url(tais-styles/svg/toggler-mask.svg);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.br-icon-toggler_active .br-icon-toggler__icon {
  transform: rotate(180deg);
}
