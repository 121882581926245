/*
* --------------------------------------------------
* This file contains all styles related to the tag component.
* --------------------------------------------------
*/

/* default tag settings */
.vc-tag {
  --tab-color-normal: #ffffff;

  --vc-tag-bg-normal: #999999;
  --vc-tag-bg-primary: var(--brand-highlight);
  --vc-tag-bg-success: #16a803;
  --vc-tag-bg-error: var(--brand-warning);
  --vc-tag-bg-changed: var(--brand-changed);
  --vc-tag-bg-disabled: rgba(0,0,0, 0.26);

  --vc-tag-bg-primary-hover: var(--brand-highlight-sec);

  --tag-font-size-small: 0.75rem;
  --tag-font-size-normal: 0.875rem;
  --tag-font-size-large: 1rem;
}

.vc-tag {
  --vc-tag-bg: var(--vc-tag-bg-normal);
  --vc-tag-color: var(--tab-color-normal);
  --vc-tag-font-size: var(--tag-font-size-normal);

  position: relative;

  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  /* for proportional sizes changes according to font-size */
  min-width: 1.86em;
  padding: 0.125rem;

  color: var(--vc-tag-color);
  font-size: var(--vc-tag-font-size);
  font-weight: bold;
  line-height: 1.15;
  vertical-align: middle;

  border: 1px solid var(--vc-tag-bg);
  border-radius: 0.3125rem;
  background-color: var(--vc-tag-bg);

  user-select: none;
}

.vc-tag_rounded {
  border-radius: 2rem;
}

.vc-tag_outlined {
  color: var(--vc-tag-bg);

  background-color: var(--vc-tag-color);
}

.vc-tag_text {
  border-color: transparent;
  background-color: transparent;
}

.vc-tag_no-bg {
  background-color: transparent;
}

.vc-tag_uppercase {
  text-transform: uppercase;
}

.vc-tag_lowercase {
  text-transform: lowercase;
}

.vc-tag_block {
  display: flex;
  flex: 1 1 auto;
}

.vc-tag_font_s {
  --vc-tag-font-size: var(--tag-font-size-small);
}

.vc-tag_font_l {
  --vc-tag-font-size: var(--tag-font-size-large);
}

.vc-tag_width_s {
  min-width: auto;
  padding-right: 0;
  padding-left: 0;
}

.vc-tag_width_l {
}

.vc-tag_height_s {
  min-height: 1.25rem;

  border-width: 1px;
}

.vc-tag_height_l {
  min-height: 1.5rem;

  border-width: 2px;
}

/* color variations  */
.vc-tag_primary {
  --vc-tag-bg: var(--vc-tag-bg-primary);
}

.vc-tag_success {
  --vc-tag-bg: var(--vc-tag-bg-success);
}

.vc-tag_error {
  --vc-tag-bg: var(--vc-tag-bg-error);
}

.vc-tag_changed {
  --vc-tag-bg: var(--vc-tag-bg-changed);
}

.vc-tag_interactive:focus,
.vc-tag_interactive:hover {
  --vc-tag-bg: var(--vc-tag-color-primary-hover);
}

.vc-tag_disabled,
.vc-tag_interactive.vc-tag_disabled,
a.vc-tag_disabled {
  --vc-tag-bg: var(--vc-tag-bg-disabled);

  cursor: default;

  pointer-events: none;
}

.vc-tag__content {
  position: relative;
  color: inherit;
  white-space: nowrap;
}
