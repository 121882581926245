/*
* --------------------------------------------------
* This file contains all styles related to the screen with announcement.
* --------------------------------------------------
*/

.br-screen-announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 2rem;
}
