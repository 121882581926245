.differences-legend {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-right: 6px;

  &__list {
    padding: 2px 11px 2px 11px;
    border-radius: 5px;
    background-color: #eee;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
  }

  &__list_no-bg {
    background: transparent;
    padding: 0;

    .differences-legend__item {
      margin-right: 10px;
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 20px;
    margin-right: 20px;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__item:before {
    content: '';
    display: inline-block;
    min-width: 32px;
    max-width: 32px;
    min-height: 4px;
    max-height: 4px;
    margin-right: 6px;
  }

  &__item_created:before {
    background-color: #36a103;
  }

  &__item_deleted:before {
    background-color: #c37777;
  }

  &__item_replaced:before {
    background-color: var(--brand-highlight);
  }

  &_bright {
    .differences-legend__item_created:before {
      background-color: #36a103;
    }

    .differences-legend__item_deleted:before {
      background-color: red;
    }

    .differences-legend__item_replaced:before {
      background-color: var(--brand-highlight-sec);
    }
  }

  &_full-right {
    background-color: #eee;
    justify-content: flex-end;
  }
}
