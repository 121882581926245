/*
* --------------------------------------------------
* This file contains all styles related to the tabs selector component.
* --------------------------------------------------
*/

/* default settings */
.vc-tabs-selector {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.vc-tabs-selector__list {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: stretch;
}

.vc-tabs-selector__item {
  flex: 1 1 10%;
}

.vc-tabs-selector__item_has-hotkey .vc-tab__content {
  position: relative;
}

.vc-tabs-selector__item_has-hotkey .vc-key-hint {
  position: absolute;
  top: -1.4rem;
}

.vc-tabs-selector__item:not(:first-child),
.vc-tabs-selector .vc-tab:not(:first-child) {
  border-left: none;
}

.vc-tabs-selector_small-width .vc-tabs-selector__list {
  flex: 0 1 auto;
}

.vc-tabs-selector_disabled .vc-tabs-selector__item {
  border-color: rgba(0,0,0, 0.26);
}
