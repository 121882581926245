.rules-assignment-layout .screen__header {
  display: flex;
  justify-content: space-between;

  .filter-selected-value {
    height: auto;
    font-size: 16px;
    text-transform: uppercase;
  }

  &-left {
    display: flex;
  }
}
