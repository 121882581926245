.twtt-segment {

  &__dep-arr-time {
    flex-grow: 0.15;
    justify-content: center;

    .segment {
      &__duration {
        margin-left: 0.8rem;
        margin-right: 1rem;
        padding-top: 0.2rem;
      }

      &__arrival {
        min-width: 4.7em;
      }
    }
  }
}
