/*
* --------------------------------------------------
* This file contains all styles related to the chip component.
* --------------------------------------------------
*/

/* default chip settings */
.br-chip {
  --chip-border-radius: 3rem;
  --chip-label-border-radius: 0.3125rem;
  --chip-text-transform: uppercase;

  --chip-color-normal: #000000;
  --chip-color-primary: var(--brand-highlight);
  --chip-color-error: #ff0000;
  --chip-color-success: #16a803;
  --chip-color-danger: #c37777;
  --chip-color-warning: #ff9326;
  --chip-color-brand: var(--brand-highlight-sec);
  --chip-color-changed: #36a103;
  --chip-color-disabled: rgba(0,0,0, 0.26);

  --chip-color-primary-hover: var(--brand-highlight-sec);

  --chip-bg-normal: #ffffff;
  --chip-bg-disabled: #cccccc;

  --chip-font-size-x-small: 0.5rem;
  --chip-font-size-small: 0.625rem;
  --chip-font-size-default: 0.75rem;
  --chip-font-size-large: 0.875rem;
  --chip-font-size-x-large: 1rem;

  --chip-font-size-text-default: 0.75rem;
}

.br-chip {
  position: relative;

  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  min-width: auto;
  min-height: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  font-weight: bold;
  vertical-align: middle;
  text-transform: var(--chip-text-transform);
  text-decoration: none;
  white-space: nowrap;

  border: 1px solid currentColor;
  border-radius: var(--chip-border-radius);

  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: box-shadow, transform, opacity;

  user-select: none;
}

.br-chip_block {
  display: flex;
  flex: 1 0 auto;
  min-width: 100% !important;
  max-width: auto;
}

.br-chip_multiple-lines .br-chip__content {
  flex-wrap: wrap;
}

.br-chip_font_s {
  font-size: var(--chip-font-size-small);
}

.br-chip_font_l {
  font-size: var(--chip-font-size-large);
}

.br-chip_width_s {
  min-width: auto;
  padding-right: 0;
  padding-left: 0;
}

.br-chip_width_l {
  min-width: 12.5rem;
}

.br-chip_height_s {
  min-height: 1.125rem;
  border-width: 1px;
}

.br-chip_height_l {
  min-height: 1.875rem;
  border-width: 2px;
}

.br-chip_label {
  border-radius: var(--chip-label-border-radius);
  overflow: hidden;
}

.br-chip {
  color: var(--chip-color-normal);

  background-color: var(--chip-bg-normal);
  border-color: var(--chip-bg-normal);
}

.br-chip_interactive:focus,
.br-chip_interactive:hover {
  color: var(--chip-color-primary-hover);

  background-color: var(--chip-bg-normal);
  border-color: var(--chip-color-primary-hover);
}

.br-chip_primary {
  color: #ffffff;

  background-color: var(--chip-color-primary);
  border-color: var(--chip-color-primary);
}

.br-chip_interactive.br-chip_primary:focus,
.br-chip_interactive.br-chip_primary:hover {
  color: #ffffff;

  background-color: var(--chip-color-primary-hover);
  border-color: var(--chip-color-primary-hover);
}

.br-chip_interactive.br-chip:active,
.br-chip_interactive.br-chip_primary:active,
.br-chip_interactive.br-chip_outlined:active {
  color: #ffffff;

  background-color: var(--chip-color-primary);
  border-color: var(--chip-color-primary);
}

.br-chip_success,
.br-chip_interactive.br-chip_success:focus,
.br-chip_interactive.br-chip_success:hover,
.br-chip_interactive.br-chip_success:active {
  color: #ffffff;

  background-color: var(--chip-color-success);
  border-color: var(--chip-color-success);
}

.br-chip_error,
.br-chip_interactive.br-chip_error:focus,
.br-chip_interactive.br-chip_error:hover,
.br-chip_interactive.br-chip_error:active {
  color: #ffffff;

  background-color: var(--chip-color-error);
  border-color: var(--chip-color-error);
}

.br-chip_warning,
.br-chip_interactive.br-chip_warning:focus,
.br-chip_interactive.br-chip_warning:hover,
.br-chip_interactive.br-chip_warning:active {
  color: #ffffff;

  background-color: var(--chip-color-warning);
  border-color: var(--chip-color-warning);
}

.br-chip_outlined {
  color: var(--chip-color-normal);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_interactive.br-chip_outlined:focus,
.br-chip_interactive.br-chip_outlined:hover {
  color: var(--chip-color-primary-hover);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_interactive.br-chip_outlined:active {
  color: #ffffff;

  background-color: var(--chip-color-primary);
  border-color: var(--chip-color-primary);
}

.br-chip_outlined.br-chip_primary {
  color: var(--chip-color-primary);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_interactive.br-chip_outlined.br-chip_primary:hover,
.br-chip_interactive.br-chip_outlined.br-chip_primary:focus {
  color: var(--chip-color-primary-hover);
}

.br-chip_interactive.br-chip_outlined.br-chip_primary:active {
  color: #ffffff;

  background-color: var(--chip-color-primary);
  border-color: var(--chip-color-primary);
}

.br-chip_outlined.br-chip_success,
.br-chip_interactive.br-chip_outlined.br-chip_success:focus,
.br-chip_interactive.br-chip_outlined.br-chip_success:hover,
.br-chip_interactive.br-chip_outlined.br-chip_success:active {
  color: var(--chip-color-success);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_outlined.br-chip_error,
.br-chip_interactive.br-chip_outlined.br-chip_error:focus,
.br-chip_interactive.br-chip_outlined.br-chip_error:hover,
.br-chip_interactive.br-chip_outlined.br-chip_error:active {
  color: var(--chip-color-error);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_outlined.br-chip_warning,
.br-chip_interactive.br-chip_outlined.br-chip_warning:focus,
.br-chip_interactive.br-chip_outlined.br-chip_warning:hover,
.br-chip_interactive.br-chip_outlined.br-chip_warning:active {
  color: var(--chip-color-warning);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_outlined.br-chip_danger,
.br-chip_interactive.br-chip_outlined.br-chip_danger:focus,
.br-chip_interactive.br-chip_outlined.br-chip_danger:hover,
.br-chip_interactive.br-chip_outlined.br-chip_danger:active {
  color: var(--chip-color-danger);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_outlined.br-chip_changed,
.br-chip_interactive.br-chip_outlined.br-chip_changed:focus,
.br-chip_interactive.br-chip_outlined.br-chip_changed:hover,
.br-chip_interactive.br-chip_outlined.br-chip_changed:active {
  color: var(--chip-color-changed);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_outlined.br-chip_brand,
.br-chip_interactive.br-chip_outlined.br-chip_brand:focus,
.br-chip_interactive.br-chip_outlined.br-chip_brand:hover,
.br-chip_interactive.br-chip_outlined.br-chip_brand:active {
  color: var(--chip-color-brand);

  background-color: transparent;
  border-color: currentColor;
}

.br-chip_disabled,
a.br-chip_disabled {
  color: var(--chip-color-disabled) !important;

  cursor: default;

  pointer-events: none;
}

.br-chip_disabled:not(.br-chip_text):not(.br-chip_outlined) {
  background-color: var(--chip-bg-disabled) !important;
  border-color: var(--chip-bg-disabled) !important;
}

.br-chip__content {
  position: relative;

  display: flex;
  max-width: 100%;
  flex: 1 0 auto;
  justify-content: inherit;
  align-items: center;

  line-height: normal;
  color: inherit;
}

.br-chip_badge {
  color: var(--chip-color-normal);
  background-color: var(--chip-bg-normal);
  border-color: var(--chip-border-normal);
}

.br-chip_badge.br-chip_error {
  color: var(--chip-color-error);
  background-color: var(--chip-bg-error);
  border-color: var(--chip-border-error);
}
