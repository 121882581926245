.vc-tooltip {
  --tooltip-arrow-offset: 0.625rem;
  --tooltip-content-padding: 0.75rem;
  --tooltip-animation-shift: 0.625rem;
  --tooltip-content-max-height: 100%;
}

.vc-tooltip__activator {
  cursor: pointer;
  display: flex;
  outline: none;
}

.vc-tooltip__activator:hover .br-icon,
.vc-tooltip__activator:focus .br-icon,
.vc-tooltip__activator:active .br-icon {
  --icon-color: var(--brand-highlight);
}

.vc-tooltip_disabled .vc-tooltip__activator {
  cursor: default;
}

.vc-tooltip__overlay {
  position: fixed;
  top: var(--terminal-height);
  z-index: var(--z-tooltip);
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  background-color: rgba(153, 153, 153, 0.6);
  cursor: pointer;
}

/* DO NOT REPLACE px with rem */
.vc-tooltip__body {
  position: fixed;
  top: calc(100% + var(--tooltip-arrow-offset) + 4px);
  left: 50%;
  z-index: var(--z-tooltip);
}

.vc-tooltip__body::before {
  content: '';
  position: absolute;
  bottom: calc(100% - 0.5rem);
  left: 50%;
  transform: translate(-50%, 0);
  width: 1.25rem;
  height: 0.5rem;
  background: #ffffff;
  z-index: 2;
}

/* DO NOT REPLACE px with rem */
.vc-tooltip__body::after {
  content: '';
  position: absolute;
  border-style: solid;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  border-width: 0 4px 6px;
  filter: drop-shadow(0px -1px 4px #c2c1c1);

  border-right: var(--tooltip-arrow-offset) solid transparent;
  border-bottom: var(--tooltip-arrow-offset) solid #ffffff;
  border-left: var(--tooltip-arrow-offset) solid transparent;
}

.vc-tooltip__body_top::before {
  bottom: unset;
  top: calc(100% - 0.5rem);
}

.vc-tooltip__body_top::after {
  border-bottom: 0;
  border-top: var(--tooltip-arrow-offset) solid #ffffff;
  bottom: unset;
  top: 100%;
}

.vc-tooltip__body_grow-height {
  display: flex;
  flex-direction: column;
  bottom: var(--tooltip-arrow-offset);
  height: unset !important;
}

.vc-tooltip__body_shrink-height .vc-tooltip__content {
  max-height: var(--tooltip-content-max-height);
}

.vc-tooltip__body_shrink-height .vc-tooltip__content > * {
  overflow-y: auto;
}

/* DO NOT REPLACE px with rem */
.vc-tooltip__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: var(--tooltip-content-padding);
  border-radius: 0.625rem;
  box-shadow: 1px 1px 7px #c2c1c1;
  overflow: hidden;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.3;
}

.vc-tooltip__body_grow-height .vc-tooltip__content,
.vc-tooltip__body_grow-height .vc-tooltip__content > * {
  flex-grow: 1;
}

.vc-tooltip__content_uppercase {
  text-transform: uppercase;
}

.vc-tooltip__body_dark .vc-tooltip__content {
  background: #666666;
  color: #ffffff;
}

.vc-tooltip__body_dark::before {
  background: #666666;
}

.vc-tooltip__body_dark::after {
  border-bottom: var(--tooltip-arrow-offset) solid #666666;
  filter: none;
}

.vc-tooltip__body_top.vc-tooltip__body_dark::after {
  border-bottom: 0;
  border-top: var(--tooltip-arrow-offset) solid #666666;
}

.vc-tooltip__body_brand .vc-tooltip__content {
  background: var(--brand-highlight);
  color: #ffffff;
}

.vc-tooltip__body_brand::before {
  background: var(--brand-highlight);
}

.vc-tooltip__body_brand::after {
  border-bottom: var(--tooltip-arrow-offset) solid var(--brand-highlight);
}

.vc-tooltip__body_top.vc-tooltip__body_brand::after {
  border-bottom: 0;
  border-top: var(--tooltip-arrow-offset) solid var(--brand-highlight);
}

.vc-tooltip__body_text-center .vc-tooltip__content {
  align-items: center;
}

.vc-tooltip__body .br-chip {
  font-size: 0.75rem;
}

.vc-tooltip__body_redirect-cart {
  z-index: 2;
}

.vc-tooltip__body_redirect-cart .vc-tooltip__content {
  transform: translateX(-7.1875rem) !important;
}

/* Vue v-if tooltip transitions */

.vc-tooltip-enter-active {
  animation-duration: .3s;
  animation-name: slideIn;
}

.vc-tooltip-leave-active {
  animation-duration: .3s;
  animation-name: slideIn;
  animation-direction: reverse;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(var(--tooltip-animation-shift));
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.vc-tooltip-overlay-enter-active, .vc-tooltip-overlay-leave-active {
  transition: opacity .3s;
}

.vc-tooltip-overlay-enter, .vc-tooltip-overlay-leave-to {
  opacity: 0;
}

@media (max-width: 90rem) {
  .vc-tooltip__body_redirect-cart {
    width: calc(33.3% - 0.5rem) !important;
  }

  .vc-tooltip__body_redirect-cart .vc-tooltip__content {
    transform: translateX(-5.8125rem) !important;
    width: 100%;
  }
}
