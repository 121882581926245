.popup-multiselect__open .select__tick {
  transform: rotate(-135deg);
}

.popup-multiselect__body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: max-content;
  max-width: 80vw;
  height: max-content;
  max-height: 90vh;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
  border-radius: 1.5625rem;
  padding: 1.25rem;
  z-index: var(--z-popup);
  display: grid;
  grid-gap: 0.625rem;
  grid-template-rows: auto auto 1fr auto;
}

.popup-multiselect__header {
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--brand-secondary);
}

.popup-multiselect .br-text-field {
  padding-left: 0.625rem;
}

.popup-multiselect__footer {
  display: flex;
  justify-content: space-between;
}

.popup-multiselect__list {
  overflow: auto;
  padding-right: 0.625rem;
  display: grid;
  grid-gap: 0.625rem;
  grid-auto-rows: min-content;
}

.popup-multiselect__group {
  margin: 0.5rem 0 0.1875rem;
  font-weight: bold;
  color: var(--brand-secondary);
}

.popup-multiselect__group:first-of-type {
  margin-top: 0;
}

.popup-multiselect__item-code {
  color: #999;
  border: 1px solid;
  border-radius: var(--border-radius-tag);
  padding: 0.125rem 0.5rem;
  margin-right: 1.125rem;
  font-weight: bold;
}

.popup-multiselect .select__option {
  border: 0.0625rem solid var(--color-disabled);
  outline: none;
  padding: 0.75rem 0.625rem 0.75rem 1.125rem;
  margin: 0;
  min-height: 3.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.625rem;
  align-items: center;
}

.popup-multiselect .select__option:hover {
  background-color: transparent;
  color: inherit;
  border-color: var(--brand-highlight-sec);
  box-shadow: inset 0 0 0 0.125rem var(--brand-highlight-sec);
}

.popup-multiselect .br-checkbox__box {
  margin-right: 0;
}

.popup-multiselect .br-checkbox_checked {
  border-color: var(--brand-highlight-sec);
  box-shadow: inset 0 0 0 0.125rem var(--brand-highlight-sec);
}

.popup-multiselect .select__control_change-value{
  color: var(--brand-highlight-sec);
}

.popup-multiselect .select__control_required{
    border-bottom-color: var(--brand-highlight-sec);
}
