/*
AVAILIBILITY SCREEN
FS SCREEN
**
TWTT
- BAV SCREEN
- MYFLIGHTS SCREEN
- MYTOURISTS SCREEN
- UPLOAD SCREEN
*/

.av-route {
  padding: 0 0.2rem 0.625rem;
  margin-top: -0.25rem;

  line-height: 1;
  white-space: nowrap;
  color: var(--brand-secondary);

  &_datepicker {
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    background: transparent;
    border: none;
    text-transform: uppercase;
    width: 6.5em;
    color: var(--color-lblue);
   }

  &_h {
    /* route in header */
    color: var(--brand-primary);
    position: relative;
  }

  &_multiple {
     display: inline-block;
     margin: 0 20px;
   }

  &_flight {
    margin-top: 0.5rem;
    display: inline-block;
  }

  &__point {
    font-size: var(--font-size-md);
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
  }

  &__point_light {
    color: var(--brand-secondary);
    font-size: var(--font-size-root);
    font-weight: bold;
    text-transform: uppercase;

    @media (min-resolution: 1.1dppx) and (max-resolution: 1.25dppx) {
      font-size: 13px;
    }
  }

  &__header {
    margin-bottom: 10px;

    &_wide {
      width: 100%;
    }
  }

  &__arr {
    position: relative;
    vertical-align: middle;
    text-align: center;

    &:not(&_long):before {
      padding: 0 0.15em;
      content: '\2192';
      font-size: var(--font-size-lg);
    }

    &_long {
      width: 18px;
      height: 2px;
      margin: 10px;
      background-color: var(--brand-secondary);
    }

    &_long:before {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      margin-top: -4px;
      content: '';
      transform: rotate(45deg);
      border: 1.5px solid;
      border-color: var(--brand-secondary) var(--brand-secondary) transparent transparent;
     }
  }

  &__arr_light {
    &:before {
      color: var(--brand-secondary);
    }
  }

  &__date-wrap {
    position: absolute;
    top: 100%;
    left: 50%;
    display: inline-block;
    padding-top: 0.3em;
  }

  &__date {
    position: relative;
    left: -50%;
    white-space: nowrap;
    text-transform: uppercase;
  }

  &__date_light {
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: var(--font-size-root);
    font-weight: bold;
    color: var(--color-lblue);
  }

  &__flight {
    font-size: var(--font-size-md);
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
  }

  &__day {
    font-size: var(--font-size-md);
    vertical-align: middle;
    text-transform: uppercase;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
