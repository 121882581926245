/*
* --------------------------------------------------
* This file contains all styles related to the drag-select component.
* --------------------------------------------------
*/

/* default drag select settings */
.vc-drag-select {
  position: relative;
  user-select: none;
}

.vc-drag-select-box {
  position: absolute;
  z-index: 99;
}
