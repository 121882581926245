/*
size of the icon depends on the setting:
xsmall - 0.5rem / 8px x 8px
small - 0.75rem / 12px x 12px
default - 1rem / 16px x 16px, arrow size 8px x 10px
medium - 1.125rem / 18px x 18px
large - 1.375rem / 22px x 22px
xlarge - 1.5rem / 24px x 24px
*/
.br-icon-close {
  position: relative;

  display: block;
  width: 1em;
  height: 1em;

  font-size: inherit;
  color: inherit;
}

.br-icon-close__icon {
  position: relative;

  display: block;
  width: 100%;
  height: 100%;

  font-size: inherit;
  color: inherit;
}

.br-icon-close_active,
.br-icon-close:hover {
  color: var(--brand-highlight);
}
