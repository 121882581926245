.vc-spinner {
  font-size: 0.875rem;
  margin: 3.125rem auto;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, var(--brand-highlight) 10%, transparent 50%);
  position: relative;
  animation: vc-spinner-animation 1s infinite linear;
  transform: translateZ(0);
}

.vc-spinner_small {
  width: 2.5rem;
  height: 2.5rem;
}

.vc-spinner_no-margin {
  margin: 0;
}

.vc-spinner_slow {
  animation-duration: 4s;
}

.vc-spinner:before {
  width: 50%;
  height: 50%;
  background: var(--brand-highlight);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.vc-spinner:after {
  background: #ffffff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.vc-spinner_grey-bg:after {
  background: #eee;
}

@-webkit-keyframes vc-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vc-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
