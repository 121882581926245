/*
* --------------------------------------------------
* This file contains all styles related to the combobox component.
* --------------------------------------------------
*/

/* default combobox settings */

.vc-combobox {
  --combobox-border-radius: 0;
  --combobox-text-transform: uppercase;

  --combobox-color-normal: #000000;
  --combobox-color-primary: var(--brand-highlight);
  --combobox-color-error: #ff0000;
  --combobox-color-success: #16a803;
  --combobox-color-changed: var(--brand-changed);
  --combobox-color-disabled: rgba(0,0,0, 0.26);

  --combobox-label-color-normal: #999999;
  --combobox-label-color-error: #ff0000;

  --combobox-border-color-normal: #cccccc;

  --combobox-font-size-small: 0.875rem;
  --combobox-font-size-default: 1rem;
  --combobox-font-size-large: 1.125rem;
}

.vc-combobox {
  position: relative;

  max-width: 100%;

  font-size: var(--combobox-font-size-default);
  font-family: inherit;
  text-align: left;
}

.vc-combobox_font_s {
  font-size: var(--combobox-font-size-small);
}

.vc-combobox_font_l {
  font-size: var(--combobox-font-size-large);
}

.vc-combobox_uppercase {
  text-transform: uppercase;
}

.vc-combobox_height_s {
}

.vc-combobox_height_l {
}

.vc-combobox_primary .vs__selected {
  color: var(--combobox-color-primary);
}

.vc-combobox_success .vs__selected {
  color: var(--combobox-color-success);
}

.vc-combobox_required .vs__selected {
  border-bottom-color: var(--combobox-color-primary);
}

.vc-combobox_required:not(.vc-combobox_filled) .vs__dropdown-toggle {
  border-bottom-color: var(--brand-highlight);
}

.vc-combobox_changed .vs__selected {
  color: var(--combobox-color-changed);
}

.vs__dropdown-toggle {
  position: relative;

  display: flex;
  padding: 0 0.875rem 0 0;

  font-weight: bold;

  background-color: transparent;
  border: solid var(--combobox-border-color-normal);
  border-width: 0 0 1px;

  appearance: none;
}

.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open .vs__dropdown-toggle {
}

.vs__selected-options {
  position: relative;

  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
}

.vs--single .vs__selected-options {
  flex-wrap: nowrap;
}

.vs__selected,
.vs__search,
.vs__search:focus {
  max-width: 100%;
  min-height: calc( 1.5em - 1px ); /* exclude border */
  margin: 0;
  padding: 0.125rem;
  overflow: hidden;

  font-size: inherit;
  line-height: 1;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;

  border: none;
}

.vs__selected {
  position: relative;
  z-index: 0;

  display: flex;
  flex: 0 1 auto;
  align-items: center;
  margin-right: 0.25rem;
  padding-right: 0.875rem;
  overflow: hidden;
}

.vs__selected::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;
  width: 1rem;

  background-image: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%);
}

.vs__search,
.vs__search:focus {
  z-index: 1;

  flex-grow: 1;
  width: 0;

  background-color: transparent;
  outline: none;
  box-shadow: none;

  appearance: none;
}

.vs--single .vs__selected {
  flex: 1 1 100%;
  margin-right: 0;
}

.vs--single:not(.vs--clearable) .vs__selected {
  padding-right: 0.125rem;
}

.vs--single:not(.vs--clearable) .vs__selected::before {
  display: none;
}

.vs--single.vs--open .vs__selected {
  position: absolute;

  opacity: 0.2;
}

.vs--single.vs--searching .vs__selected {
  display: none;
}

.vs__search::-webkit-search-cancel-button {
  display: none;
}

.vs__search::-ms-clear,
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration {
  display: none;
}

.vs__search::placeholder {
  color: inherit;
}

.vs--unsearchable .vs__search {
  opacity: 1;
}

.vs--unsearchable:not(.vs--disabled) .vs__search:hover {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

.vs__actions {
  position: absolute;
  top: calc(50% - 0.5em);
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 0.75rem; */
  height: 1em;
  padding: 0;

  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.vs__open-indicator,
.vs__open-indicator .br-icon-chevron {
  width: 0.75rem;
}

.vs__open-indicator {
  transform: scale(1);

  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

.vs__clear,
.vs__deselect {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  overflow: hidden;

  color: var(--combobox-label-color-normal);
  text-indent: -999em;

  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.vs__clear {
  position: relative;

  margin-right: 0.25rem;
}

.vs__deselect {
  position: absolute;
  top: calc( 50% - 0.3125rem );
  right: 0.125rem;
}

.vs__clear::before,
.vs__clear::after,
.vs__deselect::before,
.vs__deselect::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 7px);

  display: block;
  width: 14px;
  height: 2px;

  color: inherit;

  background-color: currentColor;
  transform-origin: 50% 50%;
}

.vs__clear::before,
.vs__deselect::before {
  transform: rotate(-45deg);
}

.vs__clear::after,
.vs__deselect::after {
  transform: rotate(45deg);
}

.vs__dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-loader);

  display: none;
  box-sizing: border-box;
  min-width: min( 10rem, 100% );
  max-height: 7rem;
  margin: -1px 0 0;
  padding: 0.25rem 0;
  overflow-y: auto;

  list-style: none;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  color: #333333;

  background: #ffffff;
  border: 1px solid rgba(60, 60, 60, 0.26);
  box-shadow: 0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.15);

  scrollbar-width: thin;
}

.v-select:not(.vs--in-body) .vs__dropdown-menu {
  top: 100%;

  display: block;
}

.vs__dropdown-menu::-webkit-scrollbar {
  width: 0.5rem;
}

.vs__no-options {
  font-weight: normal;
  font-size: inherit;
  text-align: center;
  color: inherit;
}

.vs__dropdown-option {
  display: block;
  padding: 0.25rem 1rem;

  list-style: none;
  font-weight: normal;
  font-size: inherit;
  line-height: 1.2;
  color: inherit;
}

.vs__dropdown-option:hover {
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  color: #ffffff;
  background-color: var(--brand-changed);
}

.vs__dropdown-option--disabled {
  color: var(--combobox-color-disabled);

  background: inherit;
}

.vs__dropdown-option--disabled:hover {
  cursor: inherit;
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);

  pointer-events: none;
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

.vs--disabled .vs__clear {
  display: none;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.v-select[dir="rtl"] .vs__dropdown-toggle {
  padding-right: 0;
  padding-left: 0.875rem;
}

.v-select[dir="rtl"] .vs__actions {
  right: auto;
  left: 0;
}

.v-select[dir="rtl"] .vs__clear {
  margin-right: 0;
  margin-left: 0.25rem;
}

.v-select[dir="rtl"] .vs__selected {
  margin-right: 0;
  margin-left: 0.25rem;
  padding-right: 0.125rem;
  padding-left: 0.875rem;
}

.v-select[dir="rtl"] .vs__selected::before {
  right: auto;
  left: 0;

  background-image: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 100%);
}

.v-select[dir="rtl"].vs--single .vs__selected {
  margin-left: 0;
  padding-left: 0.125rem;
}

.v-select[dir="rtl"] .vs__deselect {
  right: auto;
  left: 0.125rem;
}

.v-select[dir="rtl"] .vs__dropdown-menu {
  text-align: right;
}

.vs__spinner {
  align-self: center;
  overflow: hidden;

  font-size: 1rem;
  text-indent: -9999em;

  border: 0.125em solid hsla(0, 0%, 39.2%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  opacity: 0;

  transition: opacity 0.1s;
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
}

.vs__spinner,
.vs__spinner:after {
  width: 0.75em;
  height: 0.75em;

  border-radius: 50%;
}

.vs--loading .vs__spinner {
  opacity: 1;
}

@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
