/*
* --------------------------------------------------
* This file contains all styles related to the button component.
* --------------------------------------------------
*/

/* default button settings */
.br-button {
  --btn-border-radius: 0.3125rem;
  --btn-rounded-border-radius: 3rem;
  --btn-text-transform: uppercase;

  --btn-color-normal: #999999;
  --btn-color-primary: var(--brand-highlight);
  --btn-color-error: #ff0000;
  --btn-color-success: #16a803;
  --btn-color-dark: #000000;
  --btn-color-changed: var(--brand-changed);
  --btn-color-disabled: rgba(0,0,0, 0.26);

  --btn-color-primary-hover: var(--brand-highlight-sec);

  --btn-bg-normal: #f5f5f5;
  --btn-bg-disabled: #cccccc;

  --btn-font-size-x-small: 0.5rem;
  --btn-font-size-small: 0.625rem;
  --btn-font-size-default: 0.75rem;
  --btn-font-size-large: 0.875rem;
  --btn-font-size-x-large: 1rem;

  --btn-height: 1.875rem; /* 30px */
  --btn-icon-size: calc( var(--btn-height) / 2 ); /* 1/2 of button height */
  --btn-transition: 0.3s ease-in-out;
}

.br-button {
  position: relative;

  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  min-width: 12.5rem;
  height: var(--btn-height);
  padding: 0.25rem 0.75rem;

  font-weight: bold;
  font-size: var(--btn-font-size-default);
  font-family: var(--font-family-sans-serif-arial);
  vertical-align: middle;
  text-transform: var(--btn-text-transform);
  text-decoration: none;
  white-space: nowrap;

  border: 2px solid currentColor;
  border-radius: 0.5rem;
  cursor: pointer;

  user-select: none;
  transition: var(--btn-transition);
}

.br-button_block {
  display: flex;
  flex: 1 0 auto;
  min-width: 100% !important;
  max-width: none;
}

.br-button_li {
  text-transform: none;
  flex: 0 0 auto;
}

.br-button_li .br-button__content {
  justify-content: flex-start;
}

.br-button_li:not(.br-button_primary) {
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--brand-primary) !important;
}

.br-button_li:not(.br-button_primary):hover {
  border-color: var(--btn-color-primary) !important;
  color: var(--brand-primary) !important;
}

.br-button_font_s {
  font-size: var(--btn-font-size-small);
}

.br-button_font_l {
  font-size: var(--btn-font-size-large);
}

.br-button_width_s {
  min-width: auto;
  padding-right: 0;
  padding-left: 0;
}

.br-button_width_m {
  min-width: auto;
}

.br-button_width_l {
  min-width: 16rem;
}

.br-button_height_s {
  --btn-height: 1.5rem; /* 24px */
  border-width: 1px;
}

.br-button_height_xs {
  --btn-height: 1.125rem; /* 18px */
}

.br-button_height_l {
  --btn-height: 2.5rem; /* 40px */
}

.br-button_rounded {
  border-radius: var(--btn-rounded-border-radius);
}

.br-button {
  color: var(--btn-color-normal);

  background-color: var(--btn-bg-normal);
  border-color: var(--btn-bg-normal);
}

.br-button:focus {
  outline: none;
}

.br-button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-visible-outline-color);
}

.br-button:focus,
.br-button:focus-visible,
.br-button:hover {
  color: var(--btn-color-primary-hover);

  background-color: var(--btn-bg-normal);
  border-color: var(--btn-color-primary-hover);
}

.br-button_primary {
  color: #ffffff;

  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-button_primary:focus,
.br-button_primary:focus-visible,
.br-button_primary:hover {
  color: #ffffff;

  background-color: var(--btn-color-primary-hover);
  border-color: var(--btn-color-primary-hover);
}
.br-button_primary.selector-options__button_selected:focus,
.br-button_primary.selector-options__button_selected:hover {
  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-button:active,
.br-button_primary:active,
.br-button_outlined:active {
  color: #ffffff;

  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-button_success,
.br-button_success:focus,
.br-button_success:focus-visible,
.br-button_success:hover,
.br-button_success:active {
  color: #ffffff;

  background-color: var(--btn-color-success);
  border-color: var(--btn-color-success);
}

.br-button_error,
.br-button_error:focus,
.br-button_error:focus-visible,
.br-button_error:hover,
.br-button_error:active {
  color: #ffffff;

  background-color: var(--btn-color-error);
  border-color: var(--btn-color-error);
}

.br-button_dark,
.br-button_dark:focus,
.br-button_dark:focus-visible,
.br-button_dark:hover,
.br-button_dark:active {
  color: #ffffff;

  background-color: var(--btn-color-dark);
  border-color: var(--btn-color-dark);
}

.br-button_changed,
.br-button_changed:focus,
.br-button_changed:focus-visible,
.br-button_changed:hover,
.br-button_changed:active {
  color: #ffffff;

  background-color: var(--btn-color-changed);
  border-color: var(--btn-color-changed);
}

.br-button_outlined {
  color: var(--btn-color-normal);

  background-color: transparent;
  border-color: currentColor;
}

.br-button_outlined:focus-visible {
  box-shadow: none;
}

.br-button_outlined:focus,
.br-button_outlined:focus-visible,
.br-button_outlined:hover {
  color: var(--btn-color-primary);

  background-color: #ffffff;
  border-color: currentColor;
}

.br-button_outlined:active {
  color: #ffffff;

  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-button_outlined.br-button_primary {
  color: var(--btn-color-primary);
  border: 2px solid currentColor;
  background-color: transparent;
}

.br-button_outlined.br-button_primary:hover,
.br-button_outlined.br-button_primary:focus,
.br-button_outlined.br-button_primary:focus-visible {
  color: var(--btn-color-primary-hover);
  background-color: #ffffff;
}

.br-button_outlined.br-button_primary:active {
  color: #ffffff;

  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-button_outlined.br-button_success {
  color: var(--btn-color-success);

  background-color: transparent;
  border-color: currentColor;
}

.br-button_outlined.br-button_success:focus,
.br-button_outlined.br-button_success:focus-visible,
.br-button_outlined.br-button_success:hover,
.br-button_outlined.br-button_success:active {
  background-color: var(--btn-color-success);
  border-color: var(--btn-color-success);
  color: #ffffff;
}

.br-button_outlined.br-button_error {
  color: var(--btn-color-error);

  background-color: transparent;
  border-color: currentColor;
}

.br-button_outlined.br-button_error:focus,
.br-button_outlined.br-button_error:focus-visible,
.br-button_outlined.br-button_error:hover,
.br-button_outlined.br-button_error:active {
  background-color: var(--btn-color-error);
  border-color: var(--btn-color-error);
  color: #ffffff;
}

.br-button_outlined.br-button_dark {
  color: var(--btn-color-dark);

  background-color: transparent;
  border-color: currentColor;
}

.br-button_outlined.br-button_dark:focus,
.br-button_outlined.br-button_dark:focus-visible,
.br-button_outlined.br-button_dark:hover,
.br-button_outlined.br-button_dark:active {
  background-color: var(--btn-color-dark);
  border-color: var(--btn-color-dark);
  color: #ffffff;
}

.br-button_outlined.br-button_changed {
  color: var(--btn-color-changed);

  background-color: transparent;
  border-color: currentColor;
}

.br-button_outlined.br-button_changed:focus,
.br-button_outlined.br-button_changed:focus-visible,
.br-button_outlined.br-button_changed:hover,
.br-button_outlined.br-button_changed:active {
  background-color: var(--btn-color-changed);
  border-color: var(--btn-color-changed);
  color: #ffffff;
}

.br-button_text {
  font-weight: normal;
  color: var(--btn-color-normal);
  text-transform: none;

  background-color: transparent;
  border-color: transparent;
}

.br-button_weight-normal {
  font-weight: normal;
}

.br-button_text:focus,
.br-button_text:focus-visible,
.br-button_text:hover,
.br-button_text:active {
  color: var(--btn-color-primary-hover);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_primary {
  color: var(--btn-color-primary);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_primary:focus,
.br-button_text.br-button_primary:focus-visible,
.br-button_text.br-button_primary:hover,
.br-button_text.br-button_primary:active {
  color: var(--btn-color-primary-hover);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_success,
.br-button_text.br-button_success:focus,
.br-button_text.br-button_success:focus-visible,
.br-button_text.br-button_success:hover,
.br-button_text.br-button_success:active {
  color: var(--btn-color-success);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_error,
.br-button_text.br-button_error:focus,
.br-button_text.br-button_error:focus-visible,
.br-button_text.br-button_error:hover,
.br-button_text.br-button_error:active {
  color: var(--btn-color-error);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_dark,
.br-button_text.br-button_dark:focus,
.br-button_text.br-button_dark:focus-visible,
.br-button_text.br-button_dark:hover,
.br-button_text.br-button_dark:active {
  color: var(--btn-color-dark);

  background-color: transparent;
  border-color: transparent;
}

.br-button_text.br-button_changed,
.br-button_text.br-button_changed:focus,
.br-button_text.br-button_changed:focus-visible,
.br-button_text.br-button_changed:hover,
.br-button_text.br-button_changed:active {
  color: var(--btn-color-changed);

  background-color: transparent;
  border-color: transparent;
}

.br-button_pressed,
.br-button_pressed:focus {
  color: #ffffff;

  background-color: var(--brand-highlight-sec);
  border-color: var(--brand-highlight-sec);

  cursor: default;
}

.br-button_disabled,
.br-button[disabled] {
  color: var(--btn-color-disabled) !important;

  cursor: default;

  pointer-events: none;
}

.br-button_disabled:not(.br-button_text):not(.br-button_outlined),
.br-button[disabled]:not(.br-button_disabled-primary):not(.br-button_text):not(.br-button_outlined) {
  color: #ffffff !important;

  background-color: var(--btn-bg-disabled) !important;
  border-color: var(--btn-bg-disabled) !important;
}

.br-button_disabled-primary:not(.br-button_text):not(.br-button_outlined) {
  color: #ffffff !important;

  background-color: var(--btn-color-primary) !important;
  border-color: var(--btn-color-primary) !important;
}

.br-button_with-icon {
  padding-right: calc( var(--btn-icon-size) * 2 );
  padding-left: calc( var(--btn-icon-size) * 2 );
}

.br-button__icon {
  /* icon is 1/2 of a height of a button */
  position: absolute;
  top: 50%;
  left: calc( var(--btn-icon-size) / 2 );

  display: inline-block;
  width: var(--btn-icon-size);
  height: var(--btn-icon-size);

  transform: translateY(-50%);
}

.br-button_with-icon.br-button_text {
  /*
  for buttons without background and borders icon is 10px
   */
  --btn-icon-size: 0.625rem;
}

.br-button_with-icon.br-button_plus_rounded,
.br-button_with-icon.br-button_minus_rounded {
  --btn-icon-size: 1.125rem;
}

.br-button_with-icon.br-button_text.br-button_width_s {
  padding-left: calc( var(--btn-icon-size) + 0.25em );
  padding-right: 0;
}

.br-button_with-icon.br-button_text.br-button_width_s .br-button__icon {
  left: 0;
}

.br-button__icon-minus,
.br-button__icon-plus,
.br-button__icon-close {
  position: relative;

  display: block;
  width: 100%;
  height: 100%;

  color: inherit;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  background: currentColor;
  transition: background var(--btn-transition);
}

.br-button__icon-minus {
  mask-image: url(tais-styles/svg/minus.svg);
}

.br-button__icon-plus {
  mask-image: url(tais-styles/svg/plus.svg);
}

.br-button__icon-close {
  mask-image: url(tais-styles/svg/plus.svg);
  transform: rotate(45deg);
}

.br-button:hover .br-button__icon-minus,
.br-button:hover .br-button__icon-plus,
.br-button:hover .br-button__icon-close {
  background: var(--btn-color-primary-hover);
}

.br-button_primary:not(.br-button_outlined):hover .br-button__icon-minus,
.br-button_primary:not(.br-button_outlined):hover .br-button__icon-plus,
.br-button_primary:not(.br-button_outlined):hover .br-button__icon-close {
  background-color: currentcolor
}

.br-button_with-icon.br-button_text.br-button_plus_rounded,
.br-button_with-icon.br-button_text.br-button_minus_rounded {
  padding-left: 1.6875rem;
  border: none;
}

.br-button_plus_rounded .br-button__icon-plus-rounded,
.br-button_minus_rounded .br-button__icon-minus-rounded,
.br-button_close_rounded .br-button__icon-close-rounded {
  display: block;
  width: var(--btn-icon-size);
  height: var(--btn-icon-size);

  color: inherit;
  border: 1px solid currentColor;
  border-radius: 50%;
}

.br-button_plus_rounded .br-button__icon-plus-rounded svg,
.br-button_minus_rounded .br-button__icon-minus-rounded svg,
.br-button_close_rounded .br-button__icon-close-rounded svg {
  max-width: 100%;
  max-height: 100%;

  color: inherit;
}

.br-button_plus_rounded:hover .br-button__icon-plus-rounded,
.br-button_minus_rounded:hover .br-button__icon-minus-rounded {
}

.br-button_plus_rounded[disabled] .br-button__icon-plus-rounded,
.br-button_minus_rounded[disabled] .br-button__icon-minus-rounded {
}

.br-button_plus_rounded.br-button_icon-only:hover .br-button__icon {
  color: var(--brand-highlight-sec);
  transition: color .2s;
}

.br-button__content {
  position: relative;

  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  align-items: center;

  line-height: normal;
  color: inherit;
}

.br-button__content .vc-key-hint {
  position: absolute;
  top: -1.5625rem;
  text-transform: none;
}

.br-button:disabled .br-button__content .vc-key-hint {
  visibility: hidden;
  opacity: 0;
}

.br-button__content-prefix {
  font: bold 0.875rem var(--font-family-sans-serif-arial);
  margin-right: 10px;
}

.br-button_icon-only {
  width: auto;
  min-width: 1.125rem;
  padding: 0;

  color: transparent;

  background-color: transparent;
  background-image: none;
  border: none;
}

.br-button_icon-only:hover,
.br-button_icon-only:focus,
.br-button_icon-only:focus-visible {
  color: transparent;

  background-color: transparent;
}

.br-button_icon-only .br-button__icon {
  position: static;

  width: auto;
  height: auto;

  color: var(--btn-color-primary);

  transform: none;
}

.br-button_icon-only.br-button_primary .br-button__icon {
  color: var(--btn-color-primary);
}

.br-button_icon-only.br-button_primary:focus .br-button__icon,
.br-button_icon-only.br-button_primary:focus-visible .br-button__icon,
.br-button_icon-only.br-button_primary:hover .br-button__icon {
  color: var(--btn-color-primary-hover);
}

.br-button_icon-only.br-button_success .br-button__icon,
.br-button_icon-only.br-button_success:focus .br-button__icon,
.br-button_icon-only.br-button_success:focus-visible .br-button__icon,
.br-button_icon-only.br-button_success:hover .br-button__icon,
.br-button_icon-only.br-button_success:active .br-button__icon {
  color: var(--btn-color-success);
}

.br-button_icon-only.br-button_success .br-button__icon,
.br-button_icon-only.br-button_success:focus .br-button__icon,
.br-button_icon-only.br-button_success:focus-visible .br-button__icon,
.br-button_icon-only.br-button_success:hover .br-button__icon,
.br-button_icon-only.br-button_success:active .br-button__icon {
  color: var(--btn-color-success);
}

.br-button_icon-only.br-button_error .br-button__icon,
.br-button_icon-only.br-button_error:focus .br-button__icon,
.br-button_icon-only.br-button_error:focus-visible .br-button__icon,
.br-button_icon-only.br-button_error:hover .br-button__icon,
.br-button_icon-only.br-button_error:active .br-button__icon {
  color: var(--btn-color-error);
}

.br-button_icon-only.br-button_dark .br-button__icon,
.br-button_icon-only.br-button_dark:focus .br-button__icon,
.br-button_icon-only.br-button_dark:focus-visible .br-button__icon,
.br-button_icon-only.br-button_dark:hover .br-button__icon,
.br-button_icon-only.br-button_dark:active .br-button__icon {
  color: var(--btn-color-dark);
}

.br-button_icon-only.br-button_changed .br-button__icon,
.br-button_icon-only.br-button_changed:focus .br-button__icon,
.br-button_icon-only.br-button_changed:focus-visible .br-button__icon,
.br-button_icon-only.br-button_changed:hover .br-button__icon,
.br-button_icon-only.br-button_changed:active .br-button__icon {
  color: var(--btn-color-changed);
}

.br-button_icon-only.br-button_disabled .br-button__icon,
.br-button_icon-only.br-button[disabled] .br-button__icon {
  color: var(--btn-bg-disabled) !important;
}

.br-button_bold {
  font-weight: 700;
}

.br-button_uppercase {
  text-transform: uppercase;
}

.br-button_lowercase {
  text-transform: lowercase;
}

.br-button_envelope {
  width: 1.6875rem;
  height: 1.1875rem;
  background-image: url(tais-styles/svg/mail_27x19-grey.svg);
  background-repeat: no-repeat;
  border-radius: 0;

  &:hover {
    background-image: url(tais-styles/svg/mail_27x19.svg);
  }

  &-process {
    background-image: url(tais-styles/svg/mail-telegram-grey.svg);
  }

  &-process:hover {
    background-image: url(tais-styles/svg/mail-telegram.svg);
  }
}

.br-button_thin,
.br-button_outlined.br-button_thin,
.br-button_primary.br-button_thin {
  border-width: 0.0625rem;
  font-weight: normal;
  border-radius: 0.3125rem;
  line-height: 1;
  padding: 0.1875rem 0.625rem;
  height: auto;
}
