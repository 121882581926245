.paginationjs {
  line-height: 1.6;
  font-family: Marmelad, "Lucida Grande", Arial, "Hiragino Sans GB", Georgia, sans-serif;
  font-size: 14px;
  box-sizing: initial;

  &:after {
    display: table;
    content: " ";
    clear: both;
  }

  & .paginationjs-go-button {
    display: none;
  }

  & .paginationjs-next,
  & .paginationjs-prev {
    margin: 0 0.625rem;

    &>a {
      display: block;
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      font-size: 0;
      background: var(--brand-highlight);
    }
  }

  & .paginationjs-prev {
    &>a {
      mask: url('tais-styles/svg/arrow-pagination-prev.svg') center no-repeat;
    }
  }

  & .paginationjs-next {
    &>a {
      mask: url('tais-styles/svg/arrow-pagination-next.svg') center no-repeat;
    }
  }

  & .paginationjs-ellipsis {
    margin-right: 0.625rem;

    &.disabled>a {
      color: var(--color-light);
      font-size: 1.125rem;
      opacity: 1;
      pointer-events: none;
    }
  }

  & .paginationjs-go-input {
    & input[type=text] {
      width: 2.5625rem;
      height: 1.625rem;
      padding: 0.125rem 0.9375rem;
      border-radius: 5px;
      border: 2px solid var(--brand-highlight);
      padding: 0;
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
      vertical-align: baseline;
      outline: 0;
      background: #fff;
      box-shadow: none;
      box-sizing: initial;
    }
  }

  & .paginationjs-pages {
    float: left;

    & ul {
      float: left;
      margin: 0;
      padding: 0;
    }

    & li {
      float: left;
      list-style: none;

      &>a:hover {
        color: #fff;
        background: var(--brand-highlight-100);
      }

      &.active>a {
        color: #fff;
        background: var(--brand-highlight);
        pointer-events: none;
      }

      &.disabled>a {
        opacity: .3;

        &:hover {
          background: 0 0;
        }
      }
    }
  }

  & .paginationjs-page {
    margin-right: 0.625rem;

    & a {
      display: block;
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      color: var(--brand-highlight);
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.875rem;
      text-decoration: none;
      text-align: center;
      border-radius: 50%;
      background: none;
    }
  }

  & .paginationjs-go-button,
  & .paginationjs-go-input,
  & .paginationjs-nav {
    float: left;
    margin-left: 0.625rem;
    font-size: 0.875rem;
  }

  & .paginationjs-go-button {
    &>input[type=button] {
      min-width: 40px;
      height: 30px;
      line-height: 28px;
      background: #fff;
      border-radius: 3px;
      border: 1px solid #aaa;
      text-align: center;
      padding: 0 8px;
      font-size: 0.875rem;
      outline: 0;
      box-shadow: none;
      color: #333;
      cursor: pointer;
      vertical-align: middle;

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }

  & .paginationjs-nav {
    height: 30px;
    line-height: 30px;
  }

  & .paginationjs-go-button,
  & .paginationjs-go-input {
    margin-left: 0.625rem;
  }

  & .paginationjs-go-input {
    &>input[type=text] {
      line-height: 1;
      vertical-align: middle;
    }
  }



  /* Big type */
  &.paginationjs-big {
    & .paginationjs-page a {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      font-size: 1.125rem;
      line-height: 2.5rem;
    }

    & .paginationjs-go-input {
      & input[type=text] {
        width: 3.75rem;
        height: 2.5rem;
        font-size: 1.125rem;
      }
    }

    & .paginationjs-go-button {
      font-size: 16px;

      &>input[type=button] {
        min-width: 50px;
        height: 36px;
        line-height: 34px;
        padding: 0 12px;
        font-size: 16px;
      }
    }

    & .paginationjs-nav {
      height: 36px;
      line-height: 36px;
      font-size: 16px;
    }
  }



  /* Small type */
  &.paginationjs-small {
    font-size: 12px;

    & .paginationjs-pages li {
      &.active>a {
        height: 26px;
        line-height: 26px;
      }

      &>a {
        min-width: 26px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
      }
    }

    & .paginationjs-go-input {
      font-size: 12px;

      &>input[type=text] {
        width: 26px;
        height: 24px;
        font-size: 12px;
      }
    }

    & .paginationjs-go-button {
      font-size: 12px;

      &>input[type=button] {
        min-width: 30px;
        height: 26px;
        line-height: 24px;
        padding: 0 6px;
        font-size: 12px;
      }
    }

    & .paginationjs-nav {
      height: 26px;
      line-height: 26px;
      font-size: 12px;
    }
  }
}
