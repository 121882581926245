.twx-features {
  display: grid;
}

.twx-feature {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.625rem;
  border-bottom: 1px solid #fff;
  grid-gap: 0.625rem;

  transition: background .3s;

  .twx-feature__wrapper & {
    border-bottom: none;
    grid-template-columns: repeat(3, 1fr) .5fr;
  }
}

.twx-feature:last-child {
  border-bottom: none;
}

.twx-feature_miss-beta {
  background-color: rgba(255, 233, 211, 1);
}

.twx-feature_miss-stable {
  background-color: rgba(243, 168, 168, .5);
}

.twx-feature_different-version {
  background-color: rgba(194, 194, 194, 1);
}

.twx-feature_changed-localy {
  background-color: rgba(255, 165, 0, .8);
}

.twx-feature_changed-onlogin {
  background-color: rgba(255, 165, 0, .3);
}

.twx-feature_has-error {
  background-color: rgba(221, 0, 0, .5);
}

.twx-feature__name-box {
  display: inline-grid;
  background-color: #fefefe;
  border-radius: 0.25rem;
  padding: 0.625rem;
  text-transform: uppercase;
  font-size: 0.6875rem;
  width: 100%;
  min-height: 4.75rem;
  align-content: start;
}

.twx-feature__name-header {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.625rem;
  align-items: baseline;
  justify-content: start;
}

.twx-feature__name-desc {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.6875rem;
}

.twx-feature__name {
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;

  .twx-feature__wrapper & {
    font-size: 0.6875rem;
  }
}

.twx-feature__ticket-link,
.twx-feature__ticket-link[data-tooltip] {
  color: var(--brand-highlight-sec);
  font-weight: bold;
  font-size: 0.8125rem;

  .twx-feature__wrapper & {
    font-size: 0.75rem;
  }
}

.twx-feature__versions {
  display: grid;
  grid-gap: 0.3125rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.twx-feature__version {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
  justify-content: start;
  grid-column-gap: 0.25rem;
}

.twx-feature__version-num {
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  min-width: 2.875rem;
  display: inline-block;
  text-align: center;
}

.twx-feature__version-num_default {
  display: inline-flex;
  background-color: rgba(76, 165, 255, .9);
  padding: 0.375rem;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: bold;
}

.twx-feature__version-desc {
  text-transform: uppercase;
  font-size: 11px;
  margin: 0;
}

.twx-feature__select-wrap {
  display: grid;
  grid-gap: 1rem;
}

.twx-feature__set-title {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0;
}

.twx-feature__select-wrap-version {
  display: grid;
  grid-gap: 0.25rem;
  justify-content: start;
}

.twx-feature__selector {
  outline: none;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #333;
  min-width: 12rem;
}

.twx-feature__status {
  text-transform: uppercase;
  font-size: 0.75rem;
}

.twx-feature__status-par {
  margin: 0;
  font-weight: bold;
}

.twx-feature__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.twx-feature__wrapper_active {
  background-color: #eee;
  box-shadow: 0 -2px 0 #999, 0 2px 0 #999;
}

.twx-feature__compare {
  position: relative;
  border-radius: 0.3125rem;
  background-color: #fff;
  margin: 0.625rem;
  align-items: center;
}

.twx-feature__deleted {
  background-color: rgba(255, 0, 0, .5);
}

.twx-feature__changed {
  background-color: rgba(76, 165, 255, .6);
}

.twx-feature__deleted-empty {
  background-color: #fff;
}

.twx-feature__added {
  background-color: rgba(54, 161, 3, .3);
}

.twx-feature__hide {
  display: none;
}

.twx-feature__badge {
  padding: 0.0625rem 0.75rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  text-transform: uppercase;
  border-top-left-radius: 0.3125rem;
  box-shadow: 0.0625rem 0.0625rem 0.1875rem #333;
}

.twx-feature__not-changed {
  background-color: #eee;
}

.twx-feature__version {
  font-size: 0.8125rem;
  font-family: var(--font-family-sans-serif);
  text-transform: lowercase;
}

.twx-feature__select {
  outline: none;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 0.9375rem;
  color: #333;
}

.twx-feature__name-box_secition-wrap {
  grid-row-gap: 1rem;
}

.twx-feature__name-box-title {
  margin: 0;
  font-size: 0.6875rem;
  font-weight: bold;
}

.twx-feature__name-box-section {
  display: grid;
  grid-row-gap: 0.3125rem;
}

.the-feature-you-were-looking-for {
  background: #0c8bbc;
  transition: background .3s;
}
