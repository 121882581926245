.user-profile {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 9px;
  max-width: 500px;

  &__field-set {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    border: none;
    margin: 0 26px 10px 0;
  }

  .input__text_show_on-error,
  .input__text_show_on-warn {
    height: 0;
    display: block;
  }


  &__lang-set {
    padding: 0;
    border: none;
    margin: 0;
  }

  &__btn-set {
    width: 100%;
    padding: 0;
    border: none;
    margin: 0 0 10px;
  }

  &__extra-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .input__control {
    font-size: 20px;
    font-weight: bold;
    padding: 6px 4px;
    line-height: 30px;
    height: unset;
  }

  .input__control:focus,
  .input__control:hover {
    background-color: rgba(76, 165, 255, .1);
  }

  .btn-save {
    font-weight: bold;
    font-size: 14px;
  }

  .check {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
  }

  .check__box {
    margin: 0 5px 0 0;
    width: 22px;
    height: 22px;
  }

  .check__text {
    font-size: 11px;
  }

  .input {
    &__text {
      &_show_on-warn {
        visibility: hidden;
      }
    }

    &_warn {
      & .input__text {
        &_show_on-warn {
          visibility: visible;
          height: auto;
        }
      }
    }
  }

  .input_error .input__text_show_on-error {
    height: auto;
  }

  .check:hover {
    .check__control:not(:checked) ~ .check__box {
      background-image: none;
      background-color: rgba(76, 165, 255, .1);
      border-color: var(--brand-highlight-sec);
      box-shadow: 0 0 4px var(--brand-highlight);
    }

    .check__control:checked ~ .check__box {
      box-shadow: 0 0 4px var(--brand-highlight);
      border-color: var(--brand-highlight-sec);
    }
  }

  .check__control:checked ~ .check__box {
    background-size: 0.688rem;
  }

  &__select {
    padding: 0 20px 0 5px;
  }

  &__labels-set {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
