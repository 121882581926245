.rules-assignment-editor__json {
  padding: 30px 25px 15px;

  .json-format {
    outline: none;
    font-size: 16px;
  }
}

.rules-assignment-editor {
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  position: relative;

  .version-beta & {
    .rules-assignment-editor__view-mode {
      display: none;
    }
  }

  &__view-mode {
    display: none;
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 16px;

    .select__control {
      border: none;
    }

    &_legacy {
      top: 15px;
      right: 25px;
    }

    .select__value__data {
      font-size: 16px;
    }

    .dropdown__body {
      padding: 16px;
      left: auto;
      right: 0;
    }

    .select__option {
      font-size: 16px;
      margin: 0 0 10px;
      padding: 6px 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .btn_cancel-header {
    position: absolute;
    left: 170px;
    top: 35px;
  }

  &__buttons {
    display: flex;
    align-items: center;
  }

  &__errors {
    flex: 1;
    color: red;
    margin-left: 10px;
  }

  .btn_save {
    margin-right: 10px;
  }
}
