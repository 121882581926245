.vc-select-portal {
  text-align: left;
  min-width: 15.625rem;
  font-size: 0.75rem;
}

.vc-select-portal__options {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

/* DO NOT REPLACE px with rem */
.vc-select-portal__option {
  border: 2px solid #ffffff;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  min-width: 14rem;
}

.vc-select-portal__option .vc-tooltip {
  width: 100%;
}

.vc-select-portal__option:hover, .vc-select-portal__option_active {
  border-color: var(--brand-highlight);
}

.vc-select-portal__option_disabled:hover, .vc-select-portal__option_disabled.vc-select-portal__option_active {
  border-color: white;
}

.vc-select-portal__option_selected {
  background: var(--brand-highlight);
  border-color: var(--brand-highlight);
}

.vc-select-portal__option_disabled {
  cursor: default;
}

/* DO NOT REPLACE px with rem */
.vc-select-portal__value {
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.vc-select-portal__label {
  color: #999999;
  line-height: 1.2;
  text-transform: lowercase;
}

.vc-select-portal__option-name {
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: 0.3125rem 0.5rem;
  display: flex;
  align-items: center;
}

.vc-select-portal__option_selected .vc-select-portal__option-name {
  color: #ffffff;
}

.vc-select-portal__option_disabled .vc-select-portal__option-name {
  color: #999999;
  cursor: default;
}

.vc-select-portal__dropdown .br-icon {
  transition: 0.3s;
}

.vc-select-portal__dropdown.vc-tooltip_active .br-icon {
  transform: rotateX(180deg);
}

.vc-select-portal__options-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 3rem;
  height: 3.25rem;
  padding: 1rem;
  color: #000000;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-top-right-radius: 0.625rem;
}

.vc-select-portal__options-close .br-icon {
  color: #000000;
}

.vc-select-portal_changed {
  color: var(--brand-changed);
}

.vc-select-portal .vc-select-portal__option_required {
  border-bottom-color: var(--brand-highlight);
}
