/*
* --------------------------------------------------
* This file contains all styles related to the tooltip component.
* --------------------------------------------------
*/

/* default tooltip settings */
.br-tooltip {
  --tooltip-animation-duration: 0.2s;

  --tooltip-bg: #666666;

  --tooltip-bg-primary: var(--brand-highlight);
  --tooltip-bg-error: #ff0000;
  --tooltip-bg-success: #16a803;
  --tooltip-bg-info: #ffffff;
  --tooltip-bg-disabled: #cccccc;
}

.br-tooltip {
  position: relative;
}

.br-tooltip__activator {
  min-width: 1rem;
  min-height: 1rem;
}

.br-tooltip__alignment {
  position: absolute;
  z-index: calc( var(--z-loader) - 2 );

  display: flex;
  justify-content: center;
  align-items: center;
}

.br-tooltip__body {
  position: relative;

  padding: 0.5rem;

  font-size: 0.75rem;
  color: #ffffff;

  background-color: var(--tooltip-bg);
  border-radius: 0.3125rem;

  animation-name: none;
  animation-duration: var(--tooltip-animation-duration);
  animation-timing-function: ease-in-out;

  animation-fill-mode: forwards;
  word-break: normal;
}

.br-tooltip__body_uppercase {
  text-transform: uppercase;
}

.br-tooltip__body_transform-unset {
  text-transform: none;
}

.br-tooltip__body_font-normal {
  font-weight: normal
}

.br-tooltip__body_font-small {
  font-size: 0.625rem;
}

.br-tooltip__body_wide {
  padding: 1.25rem;
}

.br-tooltip__body-dfn {
  display: inline-block;
  font-style: normal;
  white-space: nowrap;
}

.br-tooltip__body-dfn_wrap {
  white-space: normal;
}

.br-tooltip__body::before {
  content: '';
  position: absolute;

  display: block;
}

/* positioning variations */
.br-tooltip_top .br-tooltip__alignment {
  top: auto;
  right: 0;
  bottom: 100%;
  left: 0;

  justify-content: center;
  align-items: flex-end;
}

.br-tooltip_top .br-tooltip__body {
  margin-bottom: 0.625rem;

  animation-name: tooltip-top;
}

.br-tooltip_top .br-tooltip__body::before {
  top: auto;
  right: auto;
  bottom: -0.5625rem;
  left: calc( 50% - 0.625rem );

  border-top: 0.625rem solid var(--tooltip-bg);
  border-right: 0.625rem solid transparent;
  border-left: 0.625rem solid transparent;
}

.br-tooltip_start.br-tooltip_top .br-tooltip__alignment {
  justify-content: flex-start;
}

.br-tooltip_start.br-tooltip_top .br-tooltip__body::before {
  left: 3rem;
}

.br-tooltip_end.br-tooltip_top .br-tooltip__alignment {
  justify-content: flex-end;
}

.br-tooltip_text-center .br-tooltip__alignment {
  text-align: center;
}

.br-tooltip_right .br-tooltip__alignment {
  top: 0;
  right: auto;
  bottom: 0;
  left: 100%;

  justify-content: flex-start;
  align-items: center;
}

.br-tooltip_right .br-tooltip__body {
  flex: 1 1 auto;
  margin-left: 0.625rem;

  animation-name: tooltip-right;
}

.br-tooltip_right .br-tooltip__body::before {
  top: calc( 50% - 0.625rem );
  right: auto;
  bottom: auto;
  left: -0.5625rem;

  border-top: 0.625rem solid transparent;
  border-right: 0.625rem solid var(--tooltip-bg);
  border-bottom: 0.625rem solid transparent;
}

.br-tooltip_bottom .br-tooltip__alignment {
  top: 100%;
  right: 0;
  bottom: auto;
  left: 0;
  justify-content: center;
  align-items: flex-start;
}

.br-tooltip_bottom-right .br-tooltip__alignment {
  top: 100%;
  left: auto;
  right: 0;
  justify-content: center;
  align-items: flex-start;
}

.br-tooltip_bottom .br-tooltip__body,
.br-tooltip_bottom-right .br-tooltip__body {
  margin-top: 0.625rem;
  animation-name: tooltip-bottom;
}

.br-tooltip_bottom .br-tooltip__body::before {
  top: -0.5625rem;
  left: calc( 50% - 0.625rem );

  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;
}

.br-tooltip_bottom-right .br-tooltip__body::before {
  top: -0.5625rem;
  left: auto;
  right: 0.625rem;
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;
}

.br-tooltip_bottom .br-tooltip__body_moved::before {
  display: none;
}

.br-tooltip_bottom .br-tooltip__body-arr_moved {
  position: absolute;
  top: -0.5625rem;

  display: block;

  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;
}

.br-tooltip_start.br-tooltip_bottom .br-tooltip__alignment {
  justify-content: flex-start;
}

.br-tooltip_start.br-tooltip_bottom .br-tooltip__body::before {
  left: 3rem;
}

.br-tooltip_end.br-tooltip_bottom .br-tooltip__alignment {
  justify-content: flex-end;
}

.br-tooltip_left .br-tooltip__alignment {
  top: 0;
  right: 100%;
  bottom: 0;
  left: auto;

  justify-content: flex-end;
  align-items: center;
}

.br-tooltip_left .br-tooltip__body {
  flex: 1 1 auto;
  margin-right: 0.625rem;

  animation-name: tooltip-left;
}

.br-tooltip_left .br-tooltip__body::before {
  top: calc( 50% - 0.625rem );
  right: -0.5625rem;
  bottom: auto;
  left: auto;

  border-top: 0.625rem solid transparent;
  border-bottom: 0.625rem solid transparent;
  border-left: 0.625rem solid var(--tooltip-bg);
}

.br-tooltip.br-tooltip_arrow-left .br-tooltip__body::before {
  right: unset;
  left: 0.3125rem;
}

.br-tooltip_bottom-left .br-tooltip__alignment {
  left: 0;
  right: auto;
}

.br-tooltip_bottom-left .br-tooltip__body {
  left: 0;
  right: auto;
  margin-top: 0.625rem;
  animation-name: tooltip-bottom;
}

.br-tooltip_bottom-left .br-tooltip__body::before {
  top: -0.5625rem;
  left: 0.625rem;
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;
}

/* color variations */
.br-tooltip_primary {
  --tooltip-bg: var(--tooltip-bg-primary);
}

.br-tooltip_success {
  --tooltip-bg: var(--tooltip-bg-success);
}

.br-tooltip_error {
  --tooltip-bg: var(--tooltip-bg-error);
}

.br-tooltip_info {
  --tooltip-bg: var(--tooltip-bg-info);
  color: #000000;
}

.br-tooltip_info .br-tooltip__body {
  box-shadow: 0 1px 7px #c2c1c1;
  color: #000000;
}

.br-tooltip_disabled {
  --tooltip-bg: var(--tooltip-bg-disabled);
}

@keyframes tooltip-top {
  0% {
    transform: translate(0, -0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes tooltip-right {
  0% {
    transform: translate(0.5em, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes tooltip-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes tooltip-left {
  0% {
    transform: translate(-0.5em, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
