/*
size of the icon depends on the setting:
xsmall - 0.5rem / 8px x 8px
small - 0.75rem / 12px x 12px
default - 1rem / 16px x 16px, icon size 8px x 12px
medium - 1.125rem / 18px x 18px
large - 1.25rem / 20px x 20px
xlarge - 1.5rem / 24px x 24px
*/

.vc-icon-infant {
  --icon-color-default: #000000;
  --icon-color-disabled: #ffffff;
}

.vc-icon-infant {
  display: flex;
  width: 1em;
  height: 1em;
  justify-content: center;
  align-items: center;

  font-size: inherit;
  color: var(--icon-color-default);
}

.vc-icon-infant__icon {
  position: relative;

  display: inline-block;
  width: auto;
  height: 1em;
  padding: 0;
  margin: auto;

  font-size: inherit;
  color: inherit;
}

.br-icon_disabled .vc-icon-infant {
  color: var(--icon-color-disabled);
}
