/*
* --------------------------------------------------
* This file contains all styles related to the loader.
* --------------------------------------------------
*/

/* default button settings */
.vc-circular-loader {
  display: block;
  width: 5rem;
  height: 5rem;

  border-radius: 50%;

  background: conic-gradient(
    var(--brand-highlight-0) 180deg,
    var(--brand-highlight-100)
  );
  -webkit-mask:
    radial-gradient(farthest-side,#ffffff 98%,transparent 100%) center/3rem 3rem no-repeat,
    linear-gradient(#ffffff,#ffffff);
  -webkit-mask-composite:destination-out;

  mask:
    radial-gradient(farthest-side,#ffffff 98%,transparent 100%) center/3rem 3rem no-repeat,
    linear-gradient(#ffffff,#ffffff);
  mask-composite:exclude;

  animation: vc-circular-loader 1.75s infinite linear;
  transform: translateZ(0);
}

@-webkit-keyframes vc-circular-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes vc-circular-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
