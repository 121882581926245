/*
* --------------------------------------------------
* This file contains styles that are specific to the
* AVAILIBILITY screen and it's elements.
* --------------------------------------------------
*/

/*
* AVAILIBILITY SCREEN FOR TWTt
*/

.bav-seats {
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;

  &:first-child,
  &:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__sclass {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }

  &__count {
    position: relative;

    padding-right: 0.5rem;
    padding-left: 0.5rem;

    font-weight: normal;
  }

  &__num {
    font-weight: bold;
  }

  /* Seats not available */
  &_na {
    opacity: 0.5;
  }
}

.bav-seats-cnt {
  position: relative;
  overflow: hidden;

  &__bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    content: '';

    background: #eee;
  }

  &__txt {
    position: relative;
  }
}

.av-counter {
  margin-left: 0.5rem;
}
