.popup-selector {
  position: relative;
  display: inline-grid;
}

.popup-selector__selected {
  cursor: pointer;
  color: #545454;
  font: bold 12px var(--font-family-sans-serif-arial);
  text-transform: uppercase;
  padding: 0.25rem 0.625rem;
  background-color: #ddd;
  border-radius: 0.25rem;
}

.popup-selector__selected:hover {
  background-color: #fff;
}

.popup-selector__selected_clicked {
  color: var(--brand-highlight-sec);
  position: relative;
  z-index: 9000;
}

.popup-selector__selected_clicked:hover {
  background-color: transparent;
}

.popup-selector__list {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  grid-gap: 0.3125rem;
  border-radius: 0.5625rem;
  background-color: #ffffff;
  padding: 1.25rem;
  list-style: none;
  z-index: 9000;
}

.popup-selector__list::before {
  content: '';
  border-style: solid;
  border-width: 0 11px 12px 11px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
}

.popup-selector__list-item {
  text-transform: uppercase;
  color: #545454;
  cursor: pointer;
  padding: 0.1875rem 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  text-align: center;
}

.popup-selector__list-item:hover,
.popup-selector__list-item_selected {
  background-color: var(--brand-highlight-sec);
  color: #fff;
}
