.br-text-field__clear{
  z-index: 2;
}

.br-text-field__control-v2{
  position: absolute !important;
  z-index: 1;
}

.br-text-field__value-v2{
  color: transparent !important;
  z-index: 0 !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  white-space: nowrap;
  display: block;
}

.br-text-field__control-v2,
.br-text-field__value-v2{
  padding: 0.25em 1rem !important;
  font-size: inherit;
  font-family: sans-serif;
  line-height: 1;
  text-overflow: ellipsis;
  min-height: 21px;
}

/*
.br-text-field__left-label{
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
}
*/

.br-text-field__left-label{
  font-size: 0.625rem;
  line-height: 1.4;
  color: #525252;
  text-transform: uppercase;
}

.br-text-field_full-width-v2-label{
  display: grid;
  grid-template-columns: auto 1fr;
}

.br-text-field_full-width-v2{
  display: grid;
  grid-template-columns: 1fr;
}
