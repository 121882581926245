<template>
  <div class="save-element">
    <br-button
      rounded primary largeWidth largeHeight
      @click="openModal"
    >
      {{ messages.btnSave }}
    </br-button>

    <portal to="pwd-config-layout" v-if="modal">
      <div class="save-element__modal">
        <div class="background"></div>
        <div class="content">
          <h2 class="title"> {{ messages.title }} </h2>
          <div class="body">
            <br-checkbox
              class="checkbox"
              v-for="item in settings"
              :key="item.value"
              block uppercase
              :label="item.title"
              :checked="item.checked"
              @click="onChangeMode(item)"
            />

            <div class="preview">
              <textarea ref="textarea" v-model="jsonConfig"/>

              <button
                :title="messages.copy"
                class="twx-features__copy-btn"
                @click="CopyToClipboard"
              />
            </div>
          </div>
          <div class="footer">
            <button
              class="btn btn_lg"
              @click="closeModal"
            >
              {{ messages.cancel }}
            </button>

            <br-button
              rounded primary largeWidth largeHeight
              @click="saveFeaturesToConfigFile"
            >
              {{ messages.download }}
            </br-button>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const FeatureSaver = require('tais-model/feature-config/features-saver');
const tr = require('l10n').translator;
const Portal = require('portal-vue').Portal;
const saveEnums = require('tais-model/enums/pwd-save');
const NOT_SPECIFIED = 'not specified';
export default {
  name: "saveElement",
  components: {
    portal: Portal
  },

  data: function() {
    return {
      modal: false,
      jsonConfig: null,
      messages: {
        btnSave: tr.msg('СОХРАНИТЬ В ФАЙЛ'),
        title: tr.msg('Настройки сохранения фич в файл'),
        download: tr.msg('Скачать'),
        preview: tr.msg('Предпросмотр'),
        savePreview: tr.msg('Скачать изменненый конфиг'),
        cancel: tr.msg('Отменить'),
        copy: tr.msg('Копировать')
      },
      settings: [
        {
          title: tr.msg('Сохранять только те фичи, которые участвуют в сборке'),
          value: saveEnums.NoSaveIfNotInBuild,
          checked: true
        },
        {
          title: tr.msg('Сохранять только фичи с указанной версией'),
          value: saveEnums.NoSaveWithoutVersion,
          checked: true
        },
        {
          title: tr.msg('Если версия фичи не указана, использовать версию по умолчанию'),
          value: saveEnums.SaveDefaultIfNoVersion,
          checked: false
        }
      ]
    };
  },

  watch: {
    settings: {
      handler: function() {
        this.getFeaturesToConfigFile();
      },

      deep: true
    }
  },

  computed: {
    ...mapGetters({
      featuresOrigin: "featuresOrigin",
      filteredFeatures: 'filteredFeatures',
      terminalData: 'terminalData'
    })
  },

  methods: {
    removeNotSpecifiedKeys: function(obj) {
      return Object.entries(obj)
        .filter(([ key, value ]) => (typeof value !== 'object' ? value !== NOT_SPECIFIED : true))
        .reduce((newObj, [ key, value ]) => {
          newObj[key] = typeof value === 'object' ? this.removeNotSpecifiedKeys(value) : value;
          return newObj;
        }, {});
    },
    openModal: function() {
      this.modal = true;
    },

    closeModal: function() {
      this.modal = false;
    },

    renameKey: function(obj, oldKey, newKey) {
      if (oldKey !== newKey) {
        Object.defineProperty(obj, newKey,
          Object.getOwnPropertyDescriptor(obj, oldKey));
        delete obj[oldKey];
      }
    },

    onChangeMode: function(item) {
      item.checked = !item.checked;

      if (item.value === saveEnums.NoSaveWithoutVersion) {
        const mirrorItem = this.settings.find(el => el.value === saveEnums.SaveDefaultIfNoVersion);
        mirrorItem.checked = !item.checked;
      }

      if (item.value === saveEnums.SaveDefaultIfNoVersion) {
        const mirrorItem = this.settings.find(el => el.value === saveEnums.NoSaveWithoutVersion);
        mirrorItem.checked = !item.checked;
      }
    },

    getFeaturesToConfigFile: function() {
      const json = FeatureSaver.getFeaturesToConfigFileSettings(this.featuresOrigin, this.settings);
      const configObj = JSON.parse(json);
      this.renameKey(configObj, 'stable', '1.0.0');
      this.renameKey(configObj, 'beta', '2.0.0');
      this.jsonConfig = JSON.stringify(this.removeNotSpecifiedKeys(configObj), null, 2);
    },

    saveFeaturesToConfigFile: function(event) {
      FeatureSaver.saveFeaturesToConfigFileSettings(this.featuresOrigin, event.shiftKey, this.settings);
    },

    CopyToClipboard: function() {
      this.$refs.textarea.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
    }
  },

  mounted: function() {
    this.getFeaturesToConfigFile();
  }
};
</script>

<style>
.br-checkbox__label {
  white-space: nowrap;
}
</style>

<style lang="cssnext" scoped>
.save-element {
  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 2;

      background: white;
      padding: 1.25rem;
      border-radius: 1.5rem;

      .title {
        display: inline-block;
        vertical-align: baseline;
        margin: 0 0 2rem 0;
        font-size: 1.125rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #666666;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 25px;

        .checkbox label {
          white-space: nowrap;
        }

        .preview {
          margin-bottom: 1rem;
          display: flex;

          textarea {
            width: 100%;
            height: 30vh;
          }

          .twx-features__copy-btn {
            background: white url('tais-styles/icons/page_white_copy.png') no-repeat 50% 50%;
            color: var(--brand-highlight);
            border-color: var(--brand-highlight);
            border: none;
            border-radius: 0.4rem;
            cursor: pointer;
            display: block;
            height: 2rem;
            width: 2rem;
            margin-left: .5rem;
          }

          .twx-features__copy-btn:hover {
            color: white;
            background-color: var(--brand-highlight);
            border-color: var(--brand-highlight);
          }
        }
      }

      .footer {
        display: flex;
        gap: 50px;
        justify-content: space-between;
      }
    }
  }
}
</style>
