<template>
  <div class="dependencies-info" ref="dependencies">
    <button
      @click="openDetails"
      ref="btn"
      class="dependencies-btn"
      :class="{
        'dependencies-btn_error': isErrorDependencies,
        'dependencies-btn_open': showDetails
      }"
    >
      {{ messages.dependencies }}
    </button>

    <div class="info" v-if="showDetails">
      <div class="content">
        <div class="table">
          <div class="row">
            <span class="name">{{ messages.name }}</span>
            <span class="name">{{ messages.must }}</span>
            <span class="name">{{ messages.currentState }}</span>
            <span class="name">{{ messages.control }}</span>
          </div>
          <div
            class="row"
            v-for="item in Object.keys(dependencies)"
          >
            <template v-if="featuresOrigin.find(el => el.name === item)">
              <p>
                <span class="name-link" @click="scrollToFeature(item)">{{ item }}</span>
                - {{ getFeatureDescription(item) }}
              </p>

              <div class="btn-wrapper">
                <template v-if="Array.isArray(dependencies[item])">
                <span v-for="i in dependencies[item]" :key="i">
                  {{ i }} - {{ getVersionDescription(item, i) }}
                </span>
                </template>

                <template v-else>
                  <span>{{ dependencies[item] }} - {{ getVersionDescription(item, dependencies[item]) }}</span>
                </template>
              </div>

              <span>{{ getVersion(item) }} - {{ getVersionDescription(item, getVersion(item)) }}</span>
              <div class="btn-wrapper">
                <template v-if="Array.isArray(dependencies[item])">
                  <br-button
                    v-for="i in dependencies[item]"
                    :key="i"
                    primary
                    smallFont
                    :disabled="i === getVersion(item)"
                    @click="fixVersion(item, i)"
                  >
                    {{ getBtnText(item, i) }}
                   </br-button>
                </template>

                <template v-else>
                  <br-button
                    primary
                    smallFont
                    :disabled="dependencies[item] === getVersion(item)"
                    @click="fixVersion(item, dependencies[item])"
                  >
                    {{ getBtnText(item, dependencies[item])}}
                  </br-button>
                </template>
              </div>
            </template>
            <template v-else>
              <span class="name-link">{{ item }}</span>
              <span class="error"> {{messages.noFeature}} </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FeatureVersions from "tais-model/feature-versions";
import { mapGetters } from "vuex";

const tr = require('l10n').translator;
const appModes = require('tais-model/feature-config/app-modes');

export default {
  name: 'dependencies-info',
  props: {
    version: {
      type: Object,
      require: true
    },
    mode: {
      type: String,
      require: true
    }
  },
  data: function() {
    return {
      dependencies: this.version?.dependencies,
      showDetails: false,
      messages: {
        dependencies: tr.msg('Зависимости'),
        tooltip: tr.msg('Показать подробную информацию про зависимости'),
        title: tr.msg('Зависимости для версии {version} в режиме {mode}', {
          version: this.version.version,
          mode: this.mode
        }),
        noVersion: tr.msg('Нет версии'),
        noFeature: tr.msg('Фича не найдена'),
        name: tr.msg('Название фич'),
        must: tr.msg('Поддерживаемые версии'),
        currentState: tr.msg('Сейчас стоит версия'),
        control: tr.msg('Управление'),
        fixVersion: tr.msg('Установить нужную версию')
      },
      isErrorDependencies: false
    };
  },

  watch: {
    featuresOrigin: {
      handler: function() {
        this.isErrorDependencies = this.checkErrorDependencies();
      },

      deep: true
    },

    version: {
      handler: function() {
        this.dependencies = this.version.dependencies;
        this.isErrorDependencies = this.checkErrorDependencies();
      },

      deep: true
    },

    openDependencies: function(status) {
      if (status) {
        this.showDetails = false;
      }
    }
  },

  computed: {
    ...mapGetters({
      featuresOrigin: "getFeatures"
    }),

    openDependencies: function() {
      return this.$store.state.openDependencies;
    }
  },

  methods: {
    getBtnText(fName, version) {
      return tr.msg('Установить версию {v} для {f}', { v: version, f: fName });
    },

    getFeatureDescription(featureName) {
      const feature = this.featuresOrigin.find(el => el.name === featureName);
      if (feature) {
        return feature.description;
      }

      return tr.msg('Описание отсутствует');
    },

    checkErrorDependencies() {
      const features = this.featuresOrigin;
      if (!features || !features.length) {
        return true;
      }

      if (this.dependencies && Object.entries(this.dependencies)) {
        for (const [ key, value ] of Object.entries(this.dependencies)) {
          const feature = features.find(f => f.name === key);
          if (!feature) {
            return true;
          }

          let config;

          if (this.mode === appModes.stable) {
            config = feature.stableConfig;
          }

          if (this.mode === appModes.beta) {
            config = feature.betaConfig;
          }

          if (typeof value === 'string') {
            if (feature[this.mode] && feature[this.mode] !== value) {
              return true;
            } else if (!feature[this.mode] && (!feature || !config || config.value !== value)) {
              return true;
            }
          } else if (Array.isArray(value)) {
            if (feature[this.mode] && !value.includes(feature[this.mode])) {
              return true;
            } else if (!feature[this.mode] && (!feature || !config || !value.includes(config.value))) {
              return true;
            }
          }
        }
      }

      return false;
    },

    getVersionDescription(name, version) {
      const feature = this.featuresOrigin.find(el => el.name === name);

      if (feature) {
        return feature.versions.find(el => el.version === version)?.description;
      }
    },

    getVersion(name) {
      const feature = this.featuresOrigin.find(feature => feature.name === name);
      if (feature) {
        if (feature[this.mode]) {
          return feature[this.mode];
        }
        if (this.mode === appModes.stable) {
          return feature.stableConfig ? feature.stableConfig.value || feature.stableConfig : this.messages.noVersion;
        }
        if (this.mode === appModes.beta) {
          return feature.betaConfig ? feature.betaConfig.value || feature.betaConfig : this.messages.noFeature;
        }

      }

      return tr.msg('фича не найдена');
    },

    scrollToFeature(name) {
      this.openDetails();
      this.$emit('scroll-to-feature', name);
    },

    openDetails() {
      this.$store.commit('dependenciesStatus', !this.showDetails);
      this.$nextTick(() => {
        this.showDetails = !this.showDetails;
        this.$store.commit('dependenciesStatus', false);
      });
    },
    closeDetails(event) {
      if (!this.$el.contains(event.target)) {
        this.$store.commit('dependenciesStatus', false);
        this.$nextTick(() => {
          this.showDetails = false;
          this.$store.commit('dependenciesStatus', false);
        });
      }
    },

    getIcon(currentVersion, version) {
      return currentVersion !== version;
    },

    fixVersion(name, version) {
      if (this.mode === appModes.stable) {
        FeatureVersions.setStableFeatureVersion(name, version);
      }
      if (this.mode === appModes.beta) {
        FeatureVersions.setBetaFeatureVersion(name, version);
      }
      this.$emit('update-features');
    }
  },

  mounted: function() {
    this.isErrorDependencies = this.checkErrorDependencies();

    document.querySelector('body').addEventListener('click', this.closeDetails);
  },

  destroyed: function() {
    document.querySelector('body').removeEventListener('click', this.closeDetails);
  }
};
</script>

<style lang="cssnext">
.dependencies-info {
  position: relative;
  width: min-content;
  margin-left: .5rem;

  display: flex;
  align-items: center;
}

.dependencies-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  border: none;
  border-radius: 5px;

  text-transform: lowercase;

  padding: 5px 20px 5px 10px;
  margin: 0;

  background: darkgreen;
  color: white;

  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-45%, -50%);
    position: absolute;
    top: 50%;
    right: 10px;
  }

  &_open {
    &:after {
      transform: rotate(225deg);
    }
  }

  &:hover {
    background: green;
    transition: background .3s;
  }

  &_error {
    background: darkred;

    &:hover {
      background: red;
      transition: background .3s;
    }
  }
}

.info {
  position: absolute;
  z-index: 2;
  border-radius: 5px;

  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    transform: translateY(-100%) translateX(-50%);
  }

  .content {
    background: white;
    padding: 1.25rem;
    border-radius: 1.5rem;

    .table {
      display: grid;
      grid-template-columns: 150px 200px 200px 200px;
      grid-gap: 40px 25px;

      .row {
        display: contents;

        .name {
          text-transform: uppercase;
          font-weight: bold;
        }

        .name-link {
          height: min-content;

          font-weight: bold;
          cursor: pointer;
          color: blue;

          &:hover {
            color: #4ca5ff;
          }
        }

        .error {
          color: red;
          font-size: 1rem;
          font-weight: bold;
        }

        .btn-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .br-button__content {
          white-space: break-spaces;
          width: 100%;
          height: auto;
        }
      }
    }

    .btn {
      margin-top: 40px;
    }
  }
}
</style>
