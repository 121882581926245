.vc-dropdown {
  --dropdown-content-padding: 0.75rem;
}

.vc-dropdown__activator {
  cursor: pointer;
  display: flex;
}

.vc-dropdown_disabled .vc-dropdown__activator {
  cursor: default;
}

.vc-dropdown__overlay {
  position: fixed;
  top: var(--terminal-height);
  z-index: var(--z-terminal-header);
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  background-color: rgba(153, 153, 153, 0.6);
}

.vc-dropdown__overlay ~ .vc-dropdown__overlay {
  display: none;
}

/* DO NOT REPLACE px with rem */
.vc-dropdown__body {
  position: fixed;
  z-index: calc(var(--z-terminal-header) + 1);
}

/* DO NOT REPLACE px with rem */
.vc-dropdown__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: var(--dropdown-content-padding);
  border-radius: 0.625rem;
  box-shadow: 1px 1px 7px #c2c1c1;
  transition: 0.3s;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.3;
}

.vc-dropdown__content_top .context-menu__body {
  top: 0.375rem;
  flex-direction: column-reverse;
  row-gap: 0.375rem;
}

.vc-dropdown__content_left .context-menu__body {
  left: 0;
}

/* Vue v-if dropdown transitions */

.vc-dropdown-enter-active, .vc-dropdown-leave-active {
  transition: opacity .3s, transform .3s;
}

.vc-dropdown-enter, .vc-dropdown-leave-to {
  opacity: 0;
  transform: translateY(var(--dropdown-animation-shift));
}

.vc-dropdown-overlay-enter-active, .vc-dropdown-overlay-leave-active {
  transition: opacity .3s;
}

.vc-dropdown-overlay-enter, .vc-dropdown-overlay-leave-to {
  opacity: 0;
}
