.go-back {
  display: inline-flex;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  color: transparent;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color .1s;
}

.go-back:hover,
.go-back:active,
.go-back:focus {
  background: var(--brand-highlight-sec);
}

.go-back::before {
  content: '';
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--brand-highlight-sec);
  mask-image: url(tais-styles/svg/back-arrow.svg);
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.go-back:hover::before,
.go-back:active::before,
.go-back:focus::before {
  background: #ffffff;
}
