/*
* --------------------------------------------------
* This file contains styles that are specific to the
* MY TOURISTS screen and it's elements.
* --------------------------------------------------
*/

/*
* TOURISTS SCREEN FOR TWTt
*/

.screen_mytourists {
  min-width: var(--screen-min-width) !important;

  .screen__body {
    display: flex;
    flex-direction: column;
  }

  .mytourists-list {
    flex-grow: 1;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }

  .tourists-list {
    margin-right: 0;
    padding: 0 10px;
  }

  .btn_secondary-nobg {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .panel_tourist {
    padding-left: 0;
    padding-right: 0;

    .screen-sect__details {
      padding: 0;
    }
  }

  .ssr-full-name {
    text-transform: uppercase;
    min-width: 440px;
    max-width: 440px;
    flex-grow: 1;
    flex-basis: auto;
  }

  .psgr-sect {
    flex: 1 0 100%;
  }

  .popover__switcher {
    padding: 0.4375rem 0.375rem 0.4375rem 0.625rem;
  }

  .popover__icon {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
  }

  .panel__info-flex {
  }

  .passenger__info {
    font-size: 0.625rem;
  }

  @media (min-width: 1366px) {
    .passenger__info {
      font-size: var(--font-size-base);
    }
  }

  .passenger__info_main {
    flex-wrap: wrap;
    flex-basis: auto;
    flex-grow: 1;
    max-width: 54%;
  }

  .passenger__info_add {
    flex-basis: auto;
    flex-grow: 1;
    max-width: calc(46% - 10px);
    padding-right: 10px;
  }

  .tourist-name {
    display: -ms-flexbox;
    flex: 1 1 25%;
    max-width: 33%;
    white-space: normal;
  }

  .tourist-name__text {
    font-size: 1.5em;
    line-height: 1.1;
    max-width: 100%;
    display: inline-block;
  }

  .tourist-dob__text,
  .tourist-doc__text,
  .tourist-country__text,
  .tourist-doc-expire__text {
    font-size: 12px;
  }

  .tourist-seat__text {
    font-size: 11px;
  }

  .tourist-dob {
    margin-right: 10px;
  }

  .tourist-doc {
    margin-right: 10px;
  }

  .tourist-doc__text-wrap {
    display: flex;
  }

  .tourist-doc__type {
    position: relative;
    bottom: auto;
    left: auto;
    font-size: 0.819em;
    text-transform: uppercase;
  }

  .tourist-contacts {
    font-size: 11px;

    .tourist-contact-ico {
      display: inline-block;
      width: 12px;
      height: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-left: 14px;

      &__phone {
        background-image: url(tais-styles/images/phone-call.svg);
      }

      &__email {
        background-image: url(tais-styles/svg/mail_27x19-grey.svg);
      }

      &__unkonwn {
        display: none;
      }
    }
  }

  .tourist-cat {
    align-self: center;
    display: block;
    height: auto;
    padding-left: 0;
    text-align: left;
    margin-right: 10px;
    min-width: 40px;
  }

  .tourist-seat {
    min-width: 80px;
  }

  .tourist-flight-forward,
  .tourist-reverse-flight {
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    min-width: 96px;
  }

  .tourist-flight-forward .tourist-ssr-ico {
    margin: .35rem 0;
  }

  .tourist-ssr {
    align-self: flex-start;
    width: 1.5rem;
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }

  .tourist-flight-date__text {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tourist-flight-canceled-date__text {
    white-space: nowrap;
    color: var(--color-disabled);
  }

  .tourist-flight-canceled__text {
    font-size: 0.819em;
    text-transform: uppercase;
  }

  .flight__text {
    font-size: 1em;
    font-weight: bold;
  }

  .flight__text .tag {
    min-width: 1.8rem;
  }

  .date__text {
    font-size: 1em;
    text-transform: uppercase;
  }

  .tourist-rbd {
    flex-grow: 1;
    padding-left: 0;
  }

  .tourist-tickets {
    flex: 1 0 auto;
    display: block;
    height: auto;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }

  .tourist-badges {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 40px;
    max-width: 40px;

    .badge {
      margin: 0;
    }
  }


  .ssr-direct {
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    min-width: 164px;
  }

  .ssr-reverse {
    margin-left: 0;
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    min-width: 164px;
    max-width: 164px;
  }

  .ssr-reverse:last-child {
    margin-left: 10px;
  }

  .ssr-total {
    background-color: white;
    width: 100%;
    padding: 20px 20px 20px 59%;
  }

  .ssr-total__coll:first-child {
    margin-bottom: 4px;
  }

  .btn-add-ssr,
  .btn-remove-ssr {
    padding: 0 12px;
  }

  .ssr-total-summ,
  .ssr-total-reserved {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ssr-total-summ-val,
  .ssr-total-reserved-val {
    font-weight: bold;
    color: #666;
    font-size: 15px;
  }

  .btn-add-ssr:hover {
    border-bottom-color: transparent;
  }

  .close-ssr {
    left: calc(100% - 10px);
    top: -42px;
    cursor: pointer;
  }

  .ssrs {
    background-color: #eee;
    padding-top: 14px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .menu {
      min-width: 21%;
      max-width: 21%;
      padding-left: 10px;

      a {
        color: var(--brand-highlight);
        text-transform: uppercase;
      }

      a:hover {
        color: #fff;
      }
    }
  }

  .passenger_uploaded .ssrs {
    border-left: 0.1875rem solid var(--notify-success-bg);
    border-right: 0.1875rem solid var(--notify-success-bg);
  }

  .passenger_selected .ssrs {
    border: solid var(--brand-highlight);
    border-width: 0 3px 3px 3px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ssr-wrap {
    max-width: 79%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ssr-route {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .ssr-allotment:first-child {
      margin-right: 10px;
    }

    .ssr-route-direct,
    .ssr-route-reverse {
      background-color: #fff;
      padding: 10px;
      border-radius: 6px;
    }

    .ssr-route-reverse {
      .ssr-baggage,
      .rbd-seg-status {
        margin-right: 0;
      }
    }

    .ssr-route-direct {
      .ssr-flight {
        justify-content: flex-end;
      }
    }

    &.ssr-route-rt {
      .ssr-route-direct {
        margin-right: 0;
      }

      .ssr-route-reverse .ssr-flight-info {
        justify-content: flex-end;
      }
    }

    .ssr-flight-time {
      margin-bottom: 6px;
    }

    .ssr-segment {
      margin-bottom: 8px;
      font-size: 15px;
    }

    .ssr-dep-time,
    .ssr-arr-time {
      font-size: 16px;
      font-weight: bold;
    }

    .rbd-seg-status {
      margin: 0 10px 0 0;
    }

    .ssr-baggage {
      padding: 0;
      margin-right: 10px;
    }

    .tourist-ssr-ico {
      width: 32px;
      height: 32px;
    }

    .ssr-baggage-info {
      text-align: center;
    }

  }

  .ssr-flight .ssr-segment-block {
    max-width: 320px;
  }

  .ssr-route-space {
    width: 27px;
  }

  .ssr-route-reverse {
    .ssr-segment-block {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  .ssrs-list {
    overflow: visible;
    max-height: none;
    padding-top: 0;

    .ssr {
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      box-shadow: 1px 1px #999;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .extra-coll {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
    }
  }

  .label-show-selected {
    color: var(--brand-highlight);
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    position: absolute;
  }

  .tourists-segment {
    display: flex;
    justify-content: center;
    margin: 10px auto;

    .panel__info-flex {
      padding: 5px 10px;
    }
  }

  .section-null-passengers .tourists-list {
    margin-right: -0.675rem;
  }

  .section-null-passengers .panel__control_disabled {
    width: 4.75rem;
    padding: 0.5625rem 0 0.5625rem 1.625rem;
  }

  .loading-spinner {
    background: #eee linear-gradient(to right, var(--brand-highlight) 10%, rgba(238, 238, 238, 0) 42%);

    &:before {
      background-color: #eee;
    }
  }

  .segment__dep-arr-time {
    display: inline-flex;

    .segment__departure {
      width: auto;
    }

    .segment__duration {
      margin: 0 10px;
    }

    .segment__arrival {
      width: auto;
    }
  }

  .single-ssr {
    padding: 0;
    min-height: 20px;

    &.ssr-active {
      .rounded-checkbox {
        margin-bottom: 14px;
      }
    }

    .rounded-checkbox {
      margin-left: 0;
      padding: 0;
      display: inline-flex;
      align-items: center;
      min-height: 20px;
    }

    .checkbox {
      left: 0;
      top: 0;
    }

    .ssr-cost-status {
      margin-left: 24px;
    }
  }

  .ssr-cost-status {
    margin-left: 0;
    min-width: 0;
    text-transform: uppercase;
  }

  .ssr-actions {
    width: auto;
    padding: 0;
    text-align: right;
  }

  .ssr-descr {
    height: 5rem;
    width: 9rem;
    text-transform: uppercase;
  }

  .screen__header-btn {
    margin-top: 0;
  }

  .tourist-baggage {
    margin-left: auto;

    .tourist-no-baggage {
      background-image: url("tais-styles/svg/ssr/XBAG-no-baggage.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }

  .emds-cost,
  .emds-number {
    display: block;
  }

  .ssr-code {
    & .ssr-subcode {
      color: #666 !important;
    }
  }
}

.mytourists-buttons-footer {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 0.25rem 1.875rem;
  margin-top: 1.25rem;

  .btn + .btn {
    margin-left: 2rem;
  }
}

.psgr-sect__switcher:hover {
  color: white;
  background-color: var(--brand-highlight);
}

.screen-sect_active {
  .psgr-sect__switcher {
    color: white;
    background-color: var(--brand-highlight);
  }
}

.mytourists-search__attribute {
  font-weight: bold;
  font-family: var(--font-family-sans-serif-arial);
  font-size: var(--font-size-xlg);
  line-height: 1.5;
  text-align: center;
  width: 100%;
}

.delete-tourists-list {
  height: 100px;
  overflow-y: auto;
  text-align: left;
  padding-left: 130px;
}

.ui-widget-content {
  .new-emds {
    &__field {
      display: flex;
      text-transform: uppercase;
      text-align: left;
      margin-top: 7px;

      &-name {
        min-width: 120px;

        &_right {
          margin-left: 20px;
          margin-right: 20px;
        }
      }

      &-input {
        width: 120px;
        text-transform: uppercase;

        &.error {
          border-color: red;
        }
      }
    }

    &-comment {
      width: 320px;
    }
  }
}
