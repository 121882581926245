.feature-config__outer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.twx-feature {
  &__changes-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  &__button {
    width: 100%;
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &_control-panel {
    &_filters-container {
      margin-left: auto;
      margin-right: 20px;
    }
  }
}

.vhidden {
  visibility: hidden
}

.check-box__options {
  cursor: pointer;
}

.check-box__actuality {
  margin: 0.60rem 0.60rem;
}

.check-box__flex {
  display: flex;
  margin-top: 0.5rem;
}

.twx-footer-flex {
  display: flex;
  align-items: center;
}

.btn-save-features {
  margin-right: 5px;
}

.input-load-wrapper .load-file > input[type="file"] {
  opacity: 0;
  cursor: pointer;
  width: 1px;
  height: 1px;
}

.border-for-changed {
  border: 1px dashed var(--brand-highlight);
  padding: 2px;
  margin: 2px;
}

.pwd-config-footer {
  .dropdown {
    align-self: flex-start;
  }

  .dropdown_active {
    .dropdown__body,
    .dropdown__switcher {
      z-index: calc(var(--z-overlay) + 100);
    }

    .select__tick_save {
      border-color: var(--z-above-content);
    }
  }

  .select__tick_save {
    border-color: var(--z-above-content);
    right: 3rem;
    top: 1.2rem;
  }

  .select__value .select__tick_save:hover {
    border-color: var(--brand-highlight);
  }

  .select__control {
    padding-top: 0;
    border: none;
  }

  .select__options-container {
    display: flex;
    flex-direction: column;

    .select__option {
      padding: 6px 10px;
    }
  }
}

.dialog__select-item {
  span:not(.choose-file) {
    color: grey;
    font-size: .8rem;
  }
}

.feature-from,
.feature-with {
  &__select {
    width: 300px;
  }

  &__input {
    width: 300px;
  }

  &__add-file {
    width: 200px;
  }

  &__description-file {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
  }
}

.load-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.config-select__wrapper {
  width: 100%;

  &_changed {
    border: solid rgb(252, 70, 70) 1px;
    box-shadow: 0px 0px 3px 1px rgba(255, 0, 0, 0.6);

    select {
      border: solid rgb(255, 165, 165) 1px;
    }
  }

  .twx-feature-stable,
  .twx-feature-beta {
    width: 100%;
  }
}

.feature-from,
.feature-with {
  display: grid;
  grid-gap: 0.3125rem;
}


.twx-feature__tags {
  margin-top: 20px;
}

.twx-feature__tag {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  color: #4ca5ff;

  padding: 2px 5px;
  margin-right: 10px;

  border-radius: 5px;
  border: 2px solid #4ca5ff;

  transition: background 0.3s, color 0.3s;

  &:hover {
    background: #4ca5ff;
    color: white;
  }
}

.pwd-config__tags-panel {
  position: relative;
}

.pwd-config__tags-selector {
  min-width: 300px;
}

.pwd-config__tags-selector .g-select__open-indicator,
.pwd-config__tags-selector .g-select__open-indicator .br-icon-chevron {
  width: 1rem;
  height: 10px;
}

.pwd-config__tags-selector .g-select__deselect-indicator,
.pwd-config__tags-selector .g-select__deselect-indicator .br-icon-chevron {
  width: 1rem;
  height: 10px;
}

.pwd-config__tags-selector .g-select__selected {
  padding: 0 1.1rem 0 .25em
}

.pwd-config__tags-selector ::placeholder {
  color: #999 !important;
  font-style: italic;
  font-size: 0.8125rem;
}

.pwd-config__tags-selector .g-select__dropdown-toggle {
  background-color: white;
  border: 1px solid #ccc;
  padding: 0 0 3px;
}

.pwd-config__tags-selector .g-select__actions {
  padding: 3px 6px 0 3px;
}

.twx-features__no-features {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.twx-features__copy-btn {
  background: white url('tais-styles/icons/page_white_copy.png') no-repeat 50% 50%;
  color: var(--brand-highlight);
  border-color: var(--brand-highlight);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  display: block;
  height: 2rem;
  width: 2rem;
  margin-left: .5rem;
}

.twx-features__copy-btn:hover {
  color: white;
  background-color: var(--brand-highlight);
  border-color: var(--brand-highlight);
}

.twx-features__flex {
  display: flex;
}
