.br-select {
  position: relative;

  overflow: hidden;

  font-weight: normal;
  font-size: 0.875rem;
  text-align: left;
}

.br-select__toggler {
  position: absolute;
  top: calc(50% - 0.5em);
  right: 0.125rem;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1em;
  padding: 0;

  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.br-select__toggler-icon {
  position: absolute;
  top: 2px;
  right: -3px;

  color: #999999;

  pointer-events: none;
}

.br-select__control {
  width: 100%;
  padding-bottom: 2px;

  font-weight: bold;
  text-transform: uppercase;

  border: 1px solid #cccccc;
  border-width: 0 0 1px;
  border-radius: 0;
  outline: none;
  cursor: pointer;

  appearance: none;
}

.br-select_changed .br-select__control {
  color: var(--brand-highlight);

  border-color: var(--brand-highlight);
}

.br-select__option {
  font-weight: bold;
  color: #000000;
}
