/*
* --------------------------------------------------
* This file contains styles that are specific to the main screen
* of TWTt and it's elements.
* --------------------------------------------------
*/

@import 'tais-styles/styles/utils/custom_media.cssnext';

.terminal-actions_twtt {
  min-height: 15.2rem
}

.terminal-action_twtt {
  & .terminal-action__icon {
    top: 1.75rem;
    margin-top: 0;

    &_mask {
      background-color: currentColor;
    }

    &_accounting {
      mask: url('tais-styles/svg/main-menu/accounting.svg') no-repeat center;
    }

    &_ta_flights {
      mask: url('tais-styles/svg/main-menu/ta_flights.svg') no-repeat center;
    }

    &_ta_tourists {
      mask: url('tais-styles/svg/main-menu/ta_tourists.svg') no-repeat center;
    }
  }
}

@media (--vp-min-md) {
  .sf_main-flights-container {
    padding-left: 2.5rem;
  }
}

.sf_main-flights {
  max-width: 16.25rem;
  min-height: 6.25rem;
  padding-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.sf_main-tourists {
  max-width: 18.75rem;
  min-height: 6.25rem;
  padding-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;

  &__or {
    flex: 0 1 auto;

    font-weight: bold;
    font-size: var(--font-size-s);
    color: var(--color-light-extra);
  }

  &__inputs {
    flex: 1 1 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.sf_main-flights .input,
.sf_main-tourists .input {
  margin-bottom: 0.5rem;
}

.sf_main-flights .input__control,
.sf_main-tourists .input__control {
  text-transform: uppercase;

  &::-webkit-input-placeholder {
    text-transform: none;
  }

  &:-moz-placeholder {
    text-transform: none;
  }

  &::-moz-placeholder {
    text-transform: none;
  }

  &:-ms-input-placeholder {
    text-transform: none;
  }
}

.twtt-option-row {
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  div {
    height: 1.8rem;
  }
}

.flight-search-by-num-reverse,
.flight-search-by-route-reverse,
.fin-report-date-end-agent,
.fin-report-date-end-ppr,
.fin-report-date-end-emd,
.fin-report-ppr {
  display: none;
}

.reverse-route-text {
  cursor: pointer;
  position: relative;
  top: 13px;
  border-bottom: 1px dashed;
}

.fin-report-end-date-text-agent,
.fin-report-end-date-text-ppr,
.fin-report-end-date-text-emd,
.fin-report-ppr-text {
  font-size: 1em;
  border-bottom: 0;
  padding-top: 0.5rem;
}

.flight-search-by-route-reverse:checked +span {
  left: 130px;
  width: 12px;
  font-size: 1em;
  font-weight: bold;
  top: -35px;
  border-bottom: 0;
}

.reverse-num-text {
  position: relative;
  top: 15px;
  left: 75px;
  cursor: pointer;
  border-bottom: 1px dashed;
}

.flight-search-by-num-reverse:checked +span {
  left: 275px;
  font-size: 1em;
  font-weight: bold;
  top: -35px;
  border-bottom: 0;
}

.flight-search-reverse {
  display: flex;
}

.data-input_disabled {
  color: gray;
}

.reverse__hidden {
  display: none;
}

.side-panel.session-currency-other {
  background: #222;
}

.side-panel.session-currency-eur {
  background: #4a1212;
}

.side-panel.session-currency-usd {
  background: #122f01;
}

.permissions-msg {
  background-color: #fff;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  margin: auto;
  text-transform: uppercase;
}
