/*
* --------------------------------------------------
* This file contains all styles related to the chip component.
* --------------------------------------------------
*/

/* default chip settings */
.br-badge {
  --badge-bg-normal: #cccccc;
  --badge-bg-light: #ffffff;
  --badge-bg-primary: var(--brand-highlight);
  --badge-bg-success: #16a803;
  --badge-bg-error: #ff0000;
  --badge-bg-warning: #ffff00;

  --badge-color-normal: #666666;
  --badge-color-primary: #ffffff;
  --badge-color-success: #ffffff;
  --badge-color-error: #ffffff;

  --badge-font-size-small: 0.875rem;
  --badge-font-size-default: 0.875rem;
  --badge-font-size-large: 1.125rem;
}

.br-badge {
  position: relative;

  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  overflow: hidden;

  font-weight: bold;
  font-size: var(--badge-font-size-default);
  line-height: 1.875rem;
  text-align: center;
  color: #000000;
  text-transform: uppercase;

  background-color: var(--badge-bg-normal);
  border-radius: 50%;

  transition: all 0.1s ease;
}

.br-badge_block {
  display: block;
}

.br-badge__text {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.br-badge__prefix {
  color: var(--badge-color-normal);
}

.br-badge_light {
  color: var(--badge-color-normal);
  border: 1px solid var(--badge-color-normal);
  background-color: var(--badge-bg-light);
}

.br-badge_primary {
  color: var(--badge-color-primary);

  background-color: var(--badge-bg-primary);
}

.br-badge_primary-light {
  background-color: #a9d4ff;
}

.br-badge_success {
  color: var(--badge-color-success);

  background-color: var(--badge-bg-success);
}

.br-badge_error {
  color: var(--badge-color-error);

  background-color: var(--badge-bg-error);
}

.br-badge_warning {
  color: var(--badge-color-normal);

  background-color: var(--badge-bg-warning);
}

.br-badge_s {
  width: 1.625rem;
  height: 1.625rem;
  font-size: var(--badge-font-size-small);
  line-height: 1.625rem;
}

.br-badge_l {
  width: 2.5rem;
  height: 2.5rem;
  font-size: var(--badge-font-size-large);
  line-height: 2.5rem;
}

.br-badge_extendable.br-badge_s {
  width: auto;
  min-width: 1.625rem;
  border-radius: 0.8125rem;
}

.br-badge_extendable.br-badge_l {
  width: auto;
  min-width: 2.5rem;
  border-radius: 1.25rem;
}

.br-badge::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  display: block;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.7;
}

.br-badge_s::before {
  font-size: 0.6875rem;
}

.br-badge_primary::before {
  opacity: 0.3;
}

.br-badge_flight::before {
  /* Segment number */
  background-image: url(tais-styles/svg/badge_seg.svg);
  background-position: 0.33em 0.556em;
  background-size: 1.33em auto;
}

.br-badge_flight.br-badge_active::before {
  background-image: url(tais-styles/svg/badge_seg_active.svg);
  opacity: 1;
}

.br-badge_passenger::before {
  /* Passenger (choose seat) badge */
  background-image: url(tais-styles/svg/badge_psgr.svg);
  background-position: 0.556em 50%;
  background-size: 1.11em auto;
}

.br-badge_adult::before {
  /* Adult passenger number */
  background-image: url(tais-styles/svg/badge_adt.svg);
  background-position: 0.556em 0.167em;
  background-size: 1.7em auto;
}

.br-badge_child::before {
  /* Child passenger number */
  background-image: url(tais-styles/svg/badge_chd.svg);
  background-position: 0.75em 0.222em;
  background-size: 1.2em auto;
}

.br-badge_infant::before {
  /* Infant passenger number */
  background-image: url(tais-styles/svg/badge_inf.svg);
  background-position: 0.68em 0.222em;
  background-size: 1.25em auto;
}
