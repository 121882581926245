.popup-codes-selector {
  &__input-wrap {
    margin-bottom: 1rem;
  }

  &__codes-list {
    margin: 0;
    padding: 0;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.3125rem;
    list-style: none;
    justify-content: center;
  }

  &__list-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.625rem;
    height: 1.375rem;
    font-size: 0.875rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0.3125rem;
    transition: box-shadow, transform, opacity .2s;
    user-select: none;
  }

  &__list-item_selected {
    background-color: var(--brand-highlight);
    color: #fff;
  }

  &__list-item:hover {
    border: 1px solid var(--brand-highlight);
    color: #000;
  }

  &__list-item_selected:hover {
    background-color: var(--brand-highlight);
    border-color: var(--brand-highlight);
    color: #fff;
  }

  &__list-item_wide {
    width: auto;
  }

  &__footer-wrap {
    margin-top: 1.25rem;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 0.875rem;
    color: #666;
    text-transform: uppercase;
  }

  &__extra-buttons {
    min-width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 0.625rem;
    justify-content: start;
    justify-items: start;
    grid-column: 1 / -1;
  }

  &__selected {
    text-decoration: underline;
    text-decoration-style: solid;
    color: var(--brand-highlight-sec);
  }

  &__codes-btn {
    color: var(--brand-highlight);
  }

  &__codes-list_wide {
    padding: 0.125rem;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    grid-auto-flow: row;
    grid-gap: 0.3125rem;
  }
}
