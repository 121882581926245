.compare-dialog-wrap {

  &.dialog-message .ui-widget-header {
    margin: 0;
  }

  &.dialog-message .ui-dialog-title {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.625rem;
    color: #666;
    text-align: left;
  }

  &.dialog-message .compare-dialog__select {
    outline: none;
    padding: 0.25rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #333;
    min-width: 100%;
  }

  &.dialog-message .ui-dialog-content {
    padding: 0;
    text-align: left;
    border: none;
  }

  &.dialog-message .ui-dialog-buttonpane {
    padding: 0;
  }

  &.dialog-message .ui-dialog-buttonpane button {
    margin: 0;
  }

  .ui-dialog-titlebar {
    padding: 0;
  }
}

.compare-dialog {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 1.25rem;
  align-items: start;
  min-height: 195px;
}

.compare-dialog__header {
  margin: 0;
  color: #666;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

.compare-dialog__txt {
  padding: 0.375rem;
  display: inline-flex;
  line-height: 1.1;
}

.compare-dialog__field {
  outline: none;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #333;
  min-width: 100%;
}
