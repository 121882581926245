/*
* --------------------------------------------------
* This file contains all styles related to the radiobutton component.
* --------------------------------------------------
*/

/* default radiobutton settings */
.br-radiobutton {
  --check-font-size-small: 0.875rem;
  --check-font-size-default: 1rem;
  --check-font-size-large: 1.125rem;

  --check-box-bg-normal: #ffffff;

  --check-color-normal: #000000;

  --check-box-color-normal: var(--brand-highlight);
  --check-box-color-primary: var(--brand-highlight);
  --check-box-color-error: #ff0000;
  --check-box-color-success: #16a803;
  --check-box-color-disabled: #dadada;
  --check-box-color-hover: var(--brand-highlight-sec);
}

.br-radiobutton {
  --check-font-size: var(--check-font-size-default);

  position: relative;

  display: inline-flex;

  color: var(--check-color-normal);
  font-size: var(--check-font-size);
  vertical-align: middle;

  cursor: pointer;

  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: color, transform, opacity;
}

.br-radiobutton_full-width {
  display: flex;
  width: 100%;
}

.br-radiobutton_uppercase {
  text-transform: uppercase;
}

.br-radiobutton_font_s {
  --check-font-size: var(--check-font-size-small);
}

.br-radiobutton_font_l {
  --check-font-size: var(--check-font-size-large);
}

.br-radiobutton__control {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.br-radiobutton__box {
  position: relative;

  display: inline-block;
  flex: 0 0 auto;
  width: 1.75em;
  height: 1.75em;
  margin-right: 0.5em;

  font-size: inherit;

  border-radius: 50%;
}

.br-radiobutton__box::before,
.br-radiobutton__box::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  content: '';
  font-size: inherit;
}

.br-radiobutton__box::before {
  background-color: var(--check-box-bg-normal);

  border: 2px solid var(--check-box-color-normal);
  border-radius: 50%;

  transition: background-color 0.1s, border-color 0.1s;
}

.br-radiobutton__box::after {
  background-image: url(tais-styles/svg/br-check.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;

  transform: translateY(-0.25rem);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.05s ease-out, opacity 0.02s ease-out, visibility 0s linear;
}

.br-radiobutton__control:checked ~ .br-radiobutton__box::before,
.br-radiobutton_checked .br-radiobutton__box::before {
  background-color: var(--check-box-color-normal);
}

.br-radiobutton__control:checked ~ .br-radiobutton__box::after,
.br-radiobutton_checked .br-radiobutton__box::after {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.br-radiobutton__box:hover .br-radiobutton__box::before {
  background-color: var(--check-box-color-normal);
  border-color: var(--check-box-color-hover);
}

.br-radiobutton__label {
  position: relative;

  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  align-items: center;

  line-height: normal;
  color: inherit;

  cursor: pointer;
}

.br-radiobutton_primary .br-radiobutton__box::before {
  border-color: var(--check-box-color-primary);
}

.br-radiobutton_primary.br-radiobutton_checked .br-radiobutton__box::before {
  background-color: var(--check-box-color-primary);
}

.br-radiobutton_success .br-radiobutton__box::before {
  border-color: var(--check-box-color-success);
}

.br-radiobutton_success.br-radiobutton_checked .br-radiobutton__box::before {
  background-color: var(--check-box-color-success);
}

.br-error .br-radiobutton__box::before {
  border-color: var(--check-box-color-error);
}

.br-error.br-radiobutton_checked .br-radiobutton__box::before {
  background-color: var(--check-box-color-error);
}

.br-radiobutton_disabled,
.br-radiobutton[disabled] {
  cursor: default;

  pointer-events: none;
}

.br-radiobutton_disabled .br-radiobutton__box::before,
.br-radiobutton[disabled] .br-radiobutton__box::before {
  border-color: var(--check-box-color-error) !important;
}

.br-radiobutton_disabled.br-radiobutton_checked .br-radiobutton__box::before,
.br-radiobutton[disabled].br-radiobutton_checked .br-radiobutton__box::before {
  background-color: var(--check-box-color-error) !important;
}
