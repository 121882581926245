/*
* --------------------------------------------------
* This file contains all styles related to the optional-field component.
* --------------------------------------------------
*/

/* default select settings */

.vc-optional-field {
  display: inline-block;
}

.vc-optional-field__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.vc-optional-field__remove.br-button_with-icon.br-button_close {
  --btn-icon-size: 0.75rem;
}

.vc-optional-field_no-events {
  pointer-events: none;
}
