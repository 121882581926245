/*
* --------------------------------------------------
* This file contains all styles related to the chips group component.
* --------------------------------------------------
*/

/* default chip settings */
.br-chips-group {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.br-chips-group .br-chip:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.br-chips-group .br-chip:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
