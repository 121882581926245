/*
* --------------------------------------------------
* This file contains all styles related to the text field component.
* --------------------------------------------------
*/

/* default input[type="text"] settings */
.br-text-field {
  --tf-border-radius: 0;
  --tf-text-transform: uppercase;

  --tf-color-normal: #000000;
  --tf-color-primary: var(--brand-highlight);
  --tf-color-error: #ff0000;
  --tf-color-success: #16a803;
  --tf-color-changed: var(--brand-changed);
  --tf-color-disabled: rgba(0,0,0, 0.26);
  --tf-color-focus: var(--brand-highlight-sec);

  --tf-label-color-normal: #999999;
  --tf-label-color-error: #ff0000;

  --tf-border-color-normal: #cccccc;

  --tf-font-size-xsmall: 0.625rem;
  --tf-font-size-small: 0.875rem;
  --tf-font-size-default: 1rem;
  --tf-font-size-large: 1.125rem;
  --tf-font-size-xlarge: 1.25rem;
  --tf-font-size-xxlarge: 3.75rem;
}

.br-text-field {
  position: relative;

  display: inline-block;
  max-width: 100%;
  padding-top: 0.75em;

  font-size: var(--tf-font-size-default);
  line-height: 1.3;
  text-align: left;
}

.br-text-field_no-gap-inside {
  padding: 0;
}

.br-text-field_label-down {
  padding-top: 0;
  padding-bottom: 0.75em;
}

.br-text-field_font_xs {
  font-size: var(--tf-font-size-xsmall);
}

.br-text-field_font_s {
  font-size: var(--tf-font-size-small);
}

.br-text-field_font_l {
  font-size: var(--tf-font-size-large);
}

.br-text-field_font_xl {
  font-size: var(--tf-font-size-xlarge);
}

.br-text-field_full-width {
  width: 100%;
}

.br-text-field_height_s {
}

.br-text-field_height_l {
}

.br-text-field__label {
  position: absolute;
  top: 0.75em;
  right: auto;
  left: 0;

  display: block;
  min-height: 0.5em;
  max-height: 1.5em;
  padding: 0.25em 0.125rem;
  overflow: hidden;

  font-size: inherit;
  color: var(--tf-label-color-normal);
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;

  transition: 0.3s cubic-bezier(0.25,0.8,0.5,1);

  pointer-events: none;
}

.br-text-field__label_active {
  top: 0;
  bottom: auto;

  font-size: 0.6em;
}

.br-text-field_label-down .br-text-field__label {
  top: auto;
  right: auto;
  bottom: 0.75em;
  left: 0;
}

.br-text-field_label-down .br-text-field__label_active {
  top: auto;
  bottom: 0;
}

.br-text-field__bottom-label {
  display: block;
  font-size: 0.6875rem;
  color: var(--tf-label-color-normal);
}

.br-text-field_label-left {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
}

.br-text-field__left-label {
  position: relative;

  display: inline-block;
  margin-right: 0.875rem;
  margin-bottom: 0.125rem;

  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1.4;
  color: #525252;
  text-transform: uppercase;
}

.br-text-field__box {
  position: relative;
  display: block;
}

.br-text-field__control,
.br-text-field__textarea {
  padding: 0.25em 0.125rem;
  font-size: inherit;
  font-family: sans-serif;
  line-height: 1;
}

.br-text-field__control {
  max-height: 1.5em;
}

.br-text-field__control,
.br-text-field__textarea {
  display: inline-block;
  width: 100%;
  min-width: 0;
  max-width: 100%;

  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;

  border: solid var(--tf-border-color-normal);
  border-width: 0 0 1px;
  background-color: transparent;
}

.br-text-field__control:focus,
.br-text-field__textarea:focus,
.br-text-field__control:focus-visible,
.br-text-field__textarea:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-visible-outline-color);
  border-radius: 0.125rem;
}



.br-text-field__control::placeholder,
.br-text-field__textarea::placeholder {
  color: var(--tf-label-color-normal);
  font-weight: normal;
  opacity: 1;
}


/*игнор цветовой схемы*/
.br-text-field_primary .br-text-field__control,
.br-text-field_primary .br-text-field__textarea {
  color: var(--tf-color-primary);
}

.br-text-field_success .br-text-field__control,
.br-text-field_success .br-text-field__textarea {
  color: var(--tf-color-success);
}

.br-text-field_changed .br-text-field__control,
.br-text-field_changed .br-text-field__textarea {
  color: var(--tf-color-changed);
}

.br-text-field_error .br-text-field__label {
  color: var(--tf-color-error);
  outline-color: var(--tf-label-color-error) ;
}

.br-text-field_error .br-text-field__control,
.br-text-field_error .br-text-field__textarea {
  color: var(--tf-color-error);
  border-bottom-color: currentColor;
}

.br-text-field_disabled .br-text-field__control,
.br-text-field_disabled .br-text-field__textarea {
  color: var(--tf-color-disabled);
  border-bottom-color: currentColor;
}

.br-text-field_text .br-text-field__control,
.br-text-field_text .br-text-field__textarea {
  border-bottom-color: transparent;
}

.br-text-field_required .br-text-field__control_empty,
.br-text-field_required .br-text-field__textarea_empty {
  border-bottom-color: var(--brand-highlight);
}

.br-text-field_clearable .br-text-field__control{
  padding: 0.25rem 0.625rem;
}

.br-text-field_clearable .br-text-field__textarea {
  padding-right: 1.25rem;
}

.br-text-field__clear {
  position: absolute;
  top: calc(50% - 0.3125rem);
  right: 0.125rem;

  display: block;
  /*use px instead of rem for proper work mask-position property*/
  width: 10px;
  height: 10px;
  padding: 0;

  border: none;

  overflow: hidden;
  text-indent: -999em;

  cursor: pointer;
  color: var(--tf-label-color-normal);
  mask-image: url(tais-styles/svg/cross-no-bg.svg);
  mask-position: 50% 0;
  mask-repeat: no-repeat;
  mask-size: contain;
  background: #999999;
}

@media (max-width: 1048px) {
  .br-text-field__clear {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.br-text-field__clear:focus,
.br-text-field__clear:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-visible-outline-color);
}

.br-text-field__textarea ~ .br-text-field__clear {
  top: 0.3125rem;
  right: 0.5rem;
}

.br-text-field__clear[disabled] {
  display: none;
  cursor: default;
}

.br-text-field__error {
  color: var(--tf-color-error);
  font-size: 0.75rem;
}

.br-text-field_huge .br-text-field__control,
.br-text-field_huge .br-text-field__textarea {
  height: 1em;
  padding: 0.25rem 0.75rem;

  font-size: var(--tf-font-size-xxlarge);
  line-height: 1;
  text-align: center;
}

.br-text-field_huge .br-text-field__control:focus,
.br-text-field_huge .br-text-field__textarea:focus {
  outline: none;
}

.br-text-field_huge .br-text-field__control::placeholder {
  font-weight: bold;
  color: #dadada;
}

.br-text-field_huge .br-text-field__clear {
  top: auto;
  right: 0;
  bottom: 0.5rem;

  width: 0.75rem;
  height: 0.75rem;
}

.br-text-field_framed .br-text-field__control {
  border: solid 2px var(--tf-color-primary);
  background-color: #ffffff;
}

.br-text-field_framed.br-text-field_error .br-text-field__control{
  border-color: var(--tf-color-error);
}

.br-text-field_round-corner .br-text-field__control {
  border-radius: 6px;
}

.br-text-field_focus .br-text-field__control {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-visible-outline-color);
  border-radius: 0.125rem;
}

.br-text-field_no-outline .br-text-field__control,
.br-text-field_no-outline .br-text-field__control:focus {
  outline: none;
  box-shadow: none;
}

.br-text-field_no-outline .br-text-field__control:focus-visible {
  border-color: #666;
}

.br-text-field_uppercase .br-text-field__control {
  text-transform: uppercase;
}
.br-text-field_uppercase .br-text-field__control::placeholder {
  text-transform: none;
}

.br-text-field_placeholder-uppercase .br-text-field__control::placeholder {
  text-transform: uppercase;
}

.br-text-field_error .br-text-field__control:focus {
  box-shadow: 0 0 0 2px var(--tf-color-error);
  border-bottom-color: var(--tf-color-error);
}

.br-text-field_left-align .br-text-field__control {
  text-align: left;
}

.br-text-field_right-align .br-text-field__control {
  text-align: right;
}

.br-text-field_center-align .br-text-field__control {
  text-align: center !important;
}
