.terminal-button-back {
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  display: inline-block;
  color: transparent;
  transition: 0.3s;
  padding: 0;
  margin: 0 0.5rem 0 0;
  background: none;
  border: 0;
  cursor: pointer;
  text-align: left;
  border-radius: 50%;
  overflow: hidden;
}

.js-keyboard-hints-active .terminal-button-back {
  overflow: visible;
}

.terminal-button-back:hover,
.terminal-button-back:active,
.terminal-button-back:focus {
  background: var(--brand-highlight);
}

.terminal-button-back__icon {
  font-size: 0;
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  background: var(--brand-highlight);
  mask-image: url(tais-styles/svg/back-arrow.svg);
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.terminal-button-back:hover .terminal-button-back__icon,
.terminal-button-back:active .terminal-button-back__icon,
.terminal-button-back:focus .terminal-button-back__icon {
  background: #ffffff;
}

.terminal-button-back .vc-key-hint {
  left: -0.5rem;
}
