<script>
const tr = require('l10n').translator;

const gSelect = require('tais-vue/components/g-select');

const mapGetters = require('vuex').mapGetters;
const mapMutations = require('vuex').mapMutations;

export default {
  name: "tags-panel",
  components: {
    gSelect: gSelect.VueSelect
  },

  props: {},

  data: function() {
    return {
      labels: {
        placeholder: tr.msg('Поиск по тэгам'),
        noTags: tr.msg('Нет подходящих тегов'),
        noAvailableTags: tr.msg('Нет доступных тегов'),
        deselectOnePrefix: tr.msg('Удалить'),
        deselectAllTitle: tr.msg('Удалить все')
      }
    };
  },

  computed: {
    ...mapGetters({
      selectedTags: 'selectedTags',
      allTags: "allTags"

    }),

    unselectedTags() {
      if (!this.selectedTags.length) {
        return this.allTags;
      }

      const featuresWithSelectedTags = this.$store.state.features.filter(
        feature => feature.tags && this.selectedTags.every(tag => feature.tags.includes(tag))
      );

      const allTagsFromSelectedFeatures = featuresWithSelectedTags.reduce(
        (tags, feature) => tags.concat(feature.tags),
        []
      );

      const uniqueTagsFromSelectedFeatures = [ ...new Set(allTagsFromSelectedFeatures) ];

      return uniqueTagsFromSelectedFeatures.filter(
        tag => !this.selectedTags.includes(tag)
      );
    }
  },

  methods: {
    ...mapMutations(
      {
        selectTags: 'selectTags',
        clearSelectedTags: 'clearSelectedTags'
      }
    )
  }
};
</script>

<template>
  <div class="pwd-config__tags-panel">
    <g-select
      v-if="allTags.length > 0"
      class="pwd-config__tags-selector"
      :value="selectedTags"
      :options="unselectedTags"
      :placeholder="labels.placeholder"
      :deselect-one-prefix="labels.deselectOnePrefix"
      :deselect-all-title="labels.deselectAllTitle"
      multiple
      @input="selectTags"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          {{ labels.noTags }}
        </template>
        <template v-else>
          {{ labels.noAvailableTags }}
        </template>
      </template>
    </g-select>
  </div>
</template>

<style lang="cssnext" scoped>
.pwd-config__tags-panel {
  position: relative;
  z-index: 2;
}
</style>
