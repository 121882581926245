.twd-select {
  --select-color-normal: #000000;
  --select-color-primary: var(--brand-highlight);
  --select-color-primary-sec: var(--brand-highlight-sec);

  --select-color-disabled: rgba(0,0,0, 0.26);

  --select-font-size-normal: 0.75rem;

  --select-height-small: 1.5rem; /* 24px */
  --select-height-normal: 1.875rem; /* 30px */
  --select-height-large: 2.5rem; /* 40px */

  --select-width-normal: 12.5rem;
  --select-cols-count: 1;
  --select-primary-disabled: #BCBCBC;
}

.twd-select {
  --select-width: var(--select-width-normal);
  --select-height: var(--select-height-normal);
  --select-font-size: var(--select-font-size-normal);
  --select-color: var(--select-color-normal);

  position: relative;

  min-width: var(--select-width);
  min-height: var(--select-height);
}

.twd-select_font_s {
  --select-font-size: var(--btn-font-size-small);
}

.twd-select_font_l {
  --select-font-size: var(--btn-font-size-large);
}

.twd-select_height_s {
  --select-height: var(--select-height-small);
}

.twd-select_height_l {
  --select-height: var(--select-height-large);
}

.twd-select_primary {
  --select-color: var(--select-color-primary);
}

.twd-select_primary:not(.twd-select_opened):hover .twd-select__body,
.twd-select_primary:not(.twd-select_opened):active .twd-select__body,
.twd-select_primary:not(.twd-select_opened):focus .twd-select__body {
  border-color: var(--select-color-primary-sec);
}

.twd-select_primary:not(.twd-select_opened):hover .twd-select__title,
.twd-select_primary:not(.twd-select_opened):active .twd-select__title,
.twd-select_primary:not(.twd-select_opened):focus .twd-select__title {
  color: var(--select-color-primary-sec);
}

.twd-select_primary:hover .twd-select__title-inner,
.twd-select_primary:active .twd-select__title-inner,
.twd-select_primary:focus .twd-select__title-inner {
  background: #ffffff;
}

.twd-select_primary:not(.twd-select_opened):hover .br-icon_primary,
.twd-select_primary:not(.twd-select_opened):active .br-icon_primary,
.twd-select_primary:not(.twd-select_opened):focus .br-icon_primary {
  --icon-color: var(--select-color-primary-sec);
}

.twd-select_bottom .twd-select__body_opened {
  bottom: unset;
  flex-direction: column-reverse;
}

.twd-select_bottom .br-icon-toggler__icon {
  transform: rotate(180deg);
}

.twd-select_bottom .br-icon-toggler_active .br-icon-toggler__icon {
  transform: none;
}

.twd-select:hover .br-icon-toggler {
  color: var(--brand-highlight);
}

.twd-select__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-overlay);

  display: none;

  background-color: rgba(0,0,0,0.2);
}

.twd-select__body {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  border: 2px solid var(--select-color);
  border-radius: 1.25rem;
  caret-color: transparent;
}

.twd-select__body_opened {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: var(--z-popup);

  background-color: #ffffff;
}

.twd-select__title {
  position: relative;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: stretch;
  align-self: flex-start;
  min-width: calc( var(--select-width) - 4px ); /* subtract borders width */
  height: calc(var(--select-height) - 4px );
  font-weight: bold;
  font-size: var(--select-font-size);
  font-family: var(--font-family-sans-serif-arial);
  vertical-align: middle;
  color: var(--select-color);
  text-transform: uppercase;
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
  padding: 0;
}

.twd-select__name {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0.25rem 0 0.25rem 1rem;
}

/* DO NOT REPLACE px with rem */
.twd-select__toggler {
  padding: 0.25rem 1rem 0.25rem 0.75rem;
  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: 0.3s;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.twd-select__title-inner {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  transition: 0.3s;
  border-radius: 1.25rem;
}

.twd-select__title-inner .vc-key-hint {
  position: absolute;
  top: -0.8125rem;
  text-transform: none;
}

.twd-select__body_opened .twd-select__title-inner .vc-key-hint {
  visibility: hidden;
  opacity: 0;
}

.twd-select__options {
  display: none;
  align-items: center;
  min-width: calc( var(--select-width) - 4px ); /* subtract borders width */
  max-height: calc(100vh - 10rem);
  margin: 0 0 0.125rem;
  overflow: visible;
  list-style: none;
  column-gap: 1.25rem;
  grid-template-columns: repeat(var(--select-cols-count), 1fr);
  grid-auto-flow: row;
  scrollbar-width: thin;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0 0.75rem;
}

.twd-select__options::-webkit-scrollbar {
  width: 0.5rem;
}

.twd-select__options_opened {
  display: grid;
}

.twd-select__option {
  min-width: 10.625rem;
  padding: 0.75rem 0.625rem;
  list-style: none;
  font-weight: bold;
  font-size: 0.75rem;
  text-align: center;
  color: var(--select-color);
  cursor: pointer;
  user-select: none;
  grid-row: var(--grid-row);
  margin: 0 -0.75rem;
  transition: 0.3s;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1rem;
}

.twd-select__option:hover,
.twd-select__option_active {
  color: #ffffff;

  background-color: var(--brand-highlight);
}

.twd-select__option.twd-select__option_disabled {
  color: var(--color-disabled);
}

.twd-select__option.twd-select__option_disabled:hover,
.twd-select__option_disabled.twd-select__option_active{
  color: #ffffff;
  background-color: var(--bg-dark);
}

.twd-select_opened .twd-select__overlay {
  display: block;
}

/* DO NOT REPLACE px with rem */
.twd-select_optional .twd-select__name {
  border-right: 1px solid var(--brand-highlight);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.twd-select__name_active {
  margin-top: -0.125rem;
  background-color: var(--select-color);
  color: #ffffff;
}

.twd-select_optional.twd-select_primary:hover .twd-select__title-inner,
.twd-select_optional.twd-select_primary:active .twd-select__title-inner,
.twd-select_optional.twd-select_primary:focus .twd-select__title-inner {
  background: unset;
}

.twd-select_optional:not(.twd-select_opened) .twd-select__name:hover {
  background: #ffffff;
}

.twd-select_optional:not(.twd-select_opened) .twd-select__toggler:hover {
  background: #ffffff;
}

.twd-select_optional .twd-select__title-inner {
  border-radius: 20px;
  border: 2px solid transparent;
  transition: none;
  margin: -2px;
}

.twd-select_optional.twd-select_opened .twd-select__title-inner {
  border-color: var(--select-color);
}

.twd-select_optional .twd-select__options {
  padding: 0 0.75rem 0.25rem;
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__title,
.twd-select_primary:not(.twd-select_outlined) .twd-select__name {
  color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__name {
  background-color: var(--brand-highlight);
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__name {
  border-right-color: #FFFFFF;
  color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined) .br-icon-toggler__icon {
  color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined):hover .br-icon-toggler__icon {
  color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__toggler {
  background-color: var(--brand-highlight);
}

.twd-select_optional:not(.twd-select_opened .twd-select_outlined) .twd-select__toggler:hover .br-icon-toggler__icon {
  color: var(--brand-highlight);
}

.twd-select_primary:not(.twd-select_opened .twd-select_outlined):hover .twd-select__body,
.twd-select_primary:not(.twd-select_opened.twd-select_outlined):active .twd-select__body,
.twd-select_primary:not(.twd-select_opened.twd-select_outlined):focus .twd-select__body {
  border-color: var(--brand-highlight);
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__name:hover {
  color: var(--brand-highlight);
  background-color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined) .twd-select__name_active {
  margin-top: 0;
  outline: 2px solid var(--select-color);
}

.twd-select_optional:not(.twd-select_outlined).twd-select_opened .twd-select__toggler:hover {
  background-color: #FFFFFF;
}

.twd-select_primary:not(.twd-select_outlined).twd-select_optional.twd-select_primary:hover .twd-select__title-inner,
.twd-select_primary:not(.twd-select_outlined).twd-select_optional.twd-select_primary:active .twd-select__title-inner,
.twd-select_primary:not(.twd-select_outlined).twd-select_optional.twd-select_primary:focus .twd-select__title-inner,
.twd-select_primary:not(.twd-select_outlined) .twd-select__body:not(.twd-select__body_opened),
.twd-select_primary:not(.twd-select_outlined) .twd-select__title-inner {
  background-color: var(--select-color);
}

.twd-select_disabled {
  color: var(--color-disabled);
}

.twd-select__option .vc-key-hint {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.twd-select__body .js-keyboard-hints-active .twd-select__option_active .vc-key-hint,
.twd-select__body .js-keyboard-hints-active .twd-select__option:focus .vc-key-hint {
  visibility: visible;
  opacity: 1;
}

.twd-select__option-hint-top {
  top: -1rem;
  left: 50%;
}

.twd-select__option-hint-right {
  top: 50%;
  left: 100%;
}

.twd-select__body .js-keyboard-hints-active .twd-select__option-hint-left {
  transform: translateX(50%) translateY(-50%);
}

.twd-select__body .js-keyboard-hints-active .twd-select__option-hint-right {
  transform: translateX(-50%) translateY(-50%);
}

.twd-select__option-hint-bottom {
  bottom: -1rem;
  left: 50%;
}

.twd-select__option-hint-left {
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
}

.twd-select.twd-select_center-title .twd-select__toggler {
  position: absolute;
  right: 0;
}

.twd-select.twd-select_center-title .twd-select__name {
  padding-left: 0;
}

.twd-select.twd-select_primary.twd-select_outlined.twd-select_disabled .twd-select__body {
  background-color: transparent;
  border-color: var(--select-primary-disabled);
}
.twd-select.twd-select_primary.twd-select_outlined.twd-select_disabled .twd-select__body .twd-select__title-inner {
  color: var(--select-primary-disabled);
  background-color: transparent;
}

.twd-select.twd-select_primary.twd-select_outlined.twd-select_disabled .twd-select__body .twd-select__title-inner .br-icon-toggler {
  color: var(--select-primary-disabled);
}

@media (min-width: 640px) and (min-height: 360px) {
  .twd-select__options {
    padding-right: 1rem;
  }
}
@media (min-width: 1000px) and (min-height: 560px) {
  .twd-select__options {
    padding-right: 0.75rem;
  }
}
