.twd-range {
  --slider-background: #cccccc;
  --slider-handle-color: #ffffff;
  --slider-border-color: #dddddd;
  --slider-reset-color: #999999;
}

.twd-range {
  position: relative;
  width: 100%;
  padding-right: 2.5rem;
}

.twd-range__control {
  position: relative;
  width: 100%;
  z-index: unset;
}

.twd-range__control.ui-slider-horizontal {
  height: 0.375rem;
  border: none;
  background-color: var(--slider-background);
}

.twd-range__control .ui-slider-handle {
  display: block;
  top: -0.313rem;
  width: 1rem;
  height: 1rem;
  border: solid 1px var(--slider-border-color);
  border-radius: 100%;
  background-color: var(--brand-highlight);
  cursor: pointer;
}

.twd-range__tooltip {
  display: block;
  position: absolute;
  top: -0.938rem;
  left: 50%;
  padding: 0.063rem 0.25rem;
  border-radius: 0.188rem;
  background-color: var(--brand-highlight);
  transform: translate3d(-50%, 0, 0);
}

.twd-range__tooltip:after {
  position: absolute;
  display: block;
  content: ' ';

}

.twd-range__value {
  display: block;
  font-size: 0.5rem;
  font-weight: normal;
  color: var(--slider-handle-color);
}

.twd-range__control .ui-widget-header {
  background-color: var(--brand-highlight);
}

.twd-range__reset {
  position: absolute;
  top: -0.438rem;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--slider-reset-color);
  cursor: pointer;
}
.twd-range__reset:hover {
  color: var(--brand-highlight);
}
.twd-range__reset:before,
.twd-range__reset:after {
  position: absolute;
  left: 0.563rem;
  content: ' ';
  height: 1.25rem;
  width: 0.125rem;
  background-color: currentColor;
}
.twd-range__reset:before {
  transform: rotate(45deg);
}
.twd-range__reset:after {
  transform: rotate(-45deg);
}
