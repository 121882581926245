.popover-closer {
  display: inline-flex;
  background-image: url(tais-styles/svg/popover-closer.svg);
  width: 0.625rem;
  height: 0.625rem;
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
  cursor: pointer;
}
