.br-dropdown-selector-portal__dimmer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--z-terminal-header) + 10);

  background-color: rgba(55, 55, 55, 0.5);
}

.br-dropdown-selector-portal,
.br-dropdown-selector-portal__alignment {
  --dropdown-animation-duration: 0.2s;
  --dropdown-selector-bg: #ffffff;
}

.br-dropdown-selector-portal__activator {
  min-width: 1rem;
  min-height: 1rem;
}

.br-dropdown-selector-portal__alignment {
  position: fixed;
  z-index: 6000;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.br-dropdown-selector-portal__body {
  background-color: var(--dropdown-selector-bg);
  border-radius: 1.5rem;
  padding: 1rem 2.5rem 1rem 0.5rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}

.br-dropdown-selector-portal__list {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
}

.br-dropdown-selector__list-table {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.br-dropdown-selector__list-flex {
  margin: 0;
  padding: 0 0.5rem 0 0;

  list-style: none;
  max-height: 20rem;
  overflow-y: auto;

  scrollbar-width: thin;
  display: flex;
}

.br-dropdown-selector-portal__list-item {
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.br-dropdown-selector-portal__selector-button,
.br-dropdown-selector-portal__selector-button.br-button {
  padding: 10px;
  justify-content: flex-start;
  align-items: center;

  font-weight: normal;
  color: #000000;

  background: transparent;
  border: 1px solid transparent;
}

.br-dropdown-selector-portal__selector-button.br-button.br-button_primary {
  color: #ffffff;
  background-color: var(--btn-color-primary);
  border-color: var(--btn-color-primary);
}

.br-dropdown-selector-portal__selector-button:hover,
.br-dropdown-selector-portal__selector-button.br-button:hover {
  border-color: var(--brand-highlight-sec);
}

.br-dropdown-selector-portal__body-wrap {
  position: relative;
  animation-name: selector-portal-bottom;
  animation-duration: var(--dropdown-animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin-top: 0.625rem;
}

.br-dropdown-selector-portal__body-arrow_dropdown-bottom {
  position: absolute;
  display: block;
  top: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-selector-bg);
  border-left: 0.625rem solid transparent;
}

.br-dropdown-selector-portal__body-arrow_dropdown-top {
  position: absolute;
  display: block;
  bottom: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-selector-bg);
  border-left: 0.625rem solid transparent;
  transform: scaleY(-1);
}

.br-dropdown-selector-portal__body-arrow_dropdown-right {
  position: absolute;
  display: block;
  bottom: calc(50% - 0.625rem);
  left: -0.5625rem;
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--dropdown-selector-bg);
  border-left: 0.625rem solid transparent;
  transform: rotate(-90deg) translateY(-50%);
}

.br-dropdown-selector-portal__close {
  position: absolute;
  top: 1.125rem;
  right: 0.75rem;

  display: block;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.25rem;

  color: #000000;

  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;

  border-radius: 50%;
}

.br-dropdown-selector-portal__close:hover,
.br-dropdown-selector-portal__close:focus {
  color: var(--brand-highlight-sec);
  outline: none;
}

.br-dropdown-selector-portal__close-icon {
  color: inherit;
  font-size: 1.25rem;
}

@keyframes selector-portal-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
