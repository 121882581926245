/* DO NOT REPLACE px with rem */
.vc-key-hint {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #000000;
  border-bottom-width: 2px;
  padding: 0.125rem 0.1875rem;
  border-radius: 0.1875rem;
  position: relative;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-0.25rem);
  transition: 0.3s;
  z-index: 2;
  white-space: nowrap;
  min-width: 1rem;
}

.js-keyboard-hints-active .vc-key-hint {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.vc-key-hint__text {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
}
