<template>
  <div v-if="showModal" class="confirm-modal">
      <div class="background" @click.stop="closeModal"></div>
      <div class="content">
        <h1> {{questions}} </h1>
        <div class="btn-wrapper">
          <br-button
            @click="btnYes"
            rounded primary largeWidth largeHeight
          >
            {{messages.yes}}
          </br-button>
          <br-button
            @click="btnNo"
            rounded primary largeWidth largeHeight
          >
            {{messages.no}}
          </br-button>
        </div>

      </div>
  </div>
</template>

<script>
var tr = require('i18n/src/translator');

export default {
  name: 'ConfirmModal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    questions: {
      type: String,
      default: tr.msg('Вы уверены ?')
    }
  },

  data: function() {
    return {
      messages: {
        yes: tr.msg('Да'),
        no: tr.msg('Нет')
      }
    };
  },

  methods: {
    btnYes: function() {
      this.$emit('confirm', true);
    },

    btnNo: function() {
      this.$emit('confirm', false);
    },

    closeModal: function() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="cssnext" scoped>
  .confirm-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .background {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: black;
      opacity: 0.5;

      z-index: 2;
    }

    .content {
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;

      min-width: 40%;
      min-height: 40px;

      padding: 25px;
      border-radius: 1.5rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;

      h1 {
        text-align: center;
        margin: 0 auto;
        width: 70%;
      }

      .btn-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 50px;
      }
    }
  }
</style>
