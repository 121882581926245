.br-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.screen__footer_scroll{
  padding-top: 40px;
}

.br-footer__section {
  padding: 0 2rem;
  text-align: right;
}

.br-footer__section_left {
  padding-left: 0;
  text-align: left;
}

.br-footer__section_right {
  padding-right: 0;
}

.br-footer__section_scroll {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  padding: 0 20px 0 12px;
}

.br-footer .btn-back {
  background-image: none;
}

.br-footer__buttons {
  font-size: 0;
}

.br-footer__button {
  display: inline-block;
  font-size: 0.75rem;
}

.br-footer__button + .br-footer__button {
  margin-left: 1.875rem;
}
