
.lang-switcher {
  display: grid;
  align-items: start;
}

.lang-switcher .lang-switcher__btn {
  padding: 0 5px 0 40px;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 2.125rem;
  text-align: left;
  text-transform: uppercase;
  background-size: 1.875rem;
  border: none;
  cursor: pointer;
  transition: color 0.1s;
  min-width: 8rem;
}

.lang-switcher .input__text {
  margin: 5px auto 0;
}

.lang-switcher .lang-switcher__btn[value=ru] {
  background: url(tais-styles/svg/ru-flag.svg) 5px 50% / 30px 22px no-repeat;
}

.lang-switcher .lang-switcher__btn[value=en] {
  background: url(tais-styles/svg/en-flag.svg) 5px 50% / 30px 22px no-repeat;
}

.lang-switcher .lang-switcher__btn[value=zh] {
  background: url(tais-styles/svg/zh-flag.svg) 5px 50% / 30px 22px no-repeat;
}

.lang-switcher .lang-switcher__btn.chosen-lang:hover {
  color: #000000;
}

.lang-switcher .lang-switcher__btn:hover {
  background-color: #E8E8E8;
}

.lang-switcher .lang-switcher__btn.chosen-lang {
  cursor: default;
  opacity: 1;
  background-color: #d6d6d6;
}
