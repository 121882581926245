/*
* --------------------------------------------------
* This file contains all styles related to the tab component.
* --------------------------------------------------
*/

/* default tab settings */
.vc-tab {
  --tab-border-radius: 0.25rem;

  --tab-color-default: var(--brand-highlight);
  --tab-color-active: #ffffff;
  --tab-color-success: #16a803;
  --tab-color-error: #ff0000;

  --tab-color-hover: var(--brand-highlight-sec);

  --tab-color-disabled: rgba(0,0,0, 0.26);

  --tab-bg-default: #ffffff;
  --tab-bg-active: var(--brand-highlight);
  --tab-bg-success: #16a803;
  --tab-bg-error: #ff0000;

  --tab-font-size-small: 0.5rem;
  --tab-font-size-default: 0.625rem;
  --tab-font-size-large: 0.75rem;

  --tab-height-small: 1.5rem; /* 24px */
  --tab-height-default: 1.875rem; /* 30px */
  --tab-height-large: 2.5rem; /* 40px */
}

.vc-tab {
  --tab-color: var(--tab-color-default);
  --tab-bg: var(--tab-bg-default);
  --tab-height: var(--tab-height-default);
  --tab-font-size: var(--tab-font-size-default);
  --tab-border-color: var(--tab-color);

  position: relative;

  display: inline-flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  width: auto;
  height: var(--tab-height);
  padding: 0.25rem 0.75rem;

  color: var(--tab-color);
  background-color: var(--tab-bg);

  font-size: var(--tab-font-size);
  font-weight: bold;
  vertical-align: middle;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;

  border: 1px solid;
  border-color: var(--tab-border-color);
  cursor: pointer;

  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: box-shadow, transform, opacity;

  user-select: none;
}

.vc-tab:first-child {
  border-top-left-radius: var(--tab-border-radius);
  border-bottom-left-radius: var(--tab-border-radius);
}

.vc-tab:last-child {
  border-top-right-radius: var(--tab-border-radius);
  border-bottom-right-radius: var(--tab-border-radius);
}

.vc-tab_font_s {
  --tab-font-size: var(--tab-font-size-small);
}

.vc-tab_font_l {
  --tab-font-size: var(--tab-font-size-large);
}

.vc-tab_width_min {
  padding-right: 0;
  padding-left: 0;
}

.vc-tab_width_s {
  flex: 0 1 auto;
}

.vc-tab_height_s {
  --tab-height: var(--tab-height-small); /* 24px */
}

.vc-tab_height_l {
  --tab-height: var(--tab-height-large); /* 40px */
}

.vc-tab_rounded {
  --tab-border-radius: var(--tab-rounded-border-radius);
}

.vc-tab:focus,
.vc-tab:hover {
  --tab-color: var(--tab-color-hover);
}

.vc-tab_active {
  --tab-color: var(--tab-color-active);
  --tab-bg: var(--tab-bg-active);
  --tab-border-color: var(--tab-bg-active);
}

.vc-tab_success,
.vc-tab_success:focus,
.vc-tab_success:hover {
  --tab-color: var(--tab-color-success);

  --tab-bg-active: var(--tab-bg-success);
}

.vc-tab_error,
.vc-tab_error:focus,
.vc-tab_error:hover {
  --tab-color: var(--tab-color-error);

  --tab-bg-active: var(--tab-bg-error);
}

.vc-tab_active,
.vc-tab_active:focus,
.vc-tab_active:hover {
  --tab-color: var(--tab-color-active);

  cursor: default;
}

.vc-tab_disabled,
.vc-tab[disabled] {
  --tab-bg: var(--tab-bg-default);

  color: var(--tab-color-disabled) !important;

  cursor: default;
}

.vc-tab_passed::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--tab-bg-active);
  opacity: 0.22;
}

.vc-tab__content {
  position: relative;

  display: flex;
  flex: 1 1 auto;
  justify-content: inherit;
  align-items: center;

  line-height: normal;
  color: inherit;
}

.vc-tab__content .vc-key-hint {
  margin-left: 0.375rem;
}

.vc-tab__content .vc-tooltip__activator {
  align-items: center;
  cursor: pointer;
}
