.no-data {
  padding: 10px;

  &_collapsed {
    padding: 0;
    margin-bottom: 10px;
  }

  &_full {
    width: 100%;
    height: 100%;
  }

  &_centered {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  &__header {
    margin: 0;
    color: #888;
    font-size: 18px;
    text-transform: uppercase;
  }

  &__header_txt-left {
    text-align: left;
  }

  &__header_txt-center {
    text-align: center;
  }

  &__header_small {
    font-size: 14px;
  }

  &__header_x-small {
    font-size: 12px;
  }

  &__header_bold {
    font-weight: bold;
  }

  &:only-child {
    margin-bottom: 0;
  }
}
