<script>
"use strict";
const tr = require('l10n').translator;

const appModes = require('tais-model/feature-config/app-modes');
const copyToClipboard = require('tais-utils/copy-to-clipboard');
import EE from "tais-model/ee";

const mapMutations = require('vuex').mapMutations;

const FeatureVersionSelector = require('tais-vue/parts/features-config/feature-version-selector.vue');
const DependenciesInfo = require('tais-vue/parts/features-config/dependencies-info.vue');

const labels = {
  defaultVersion: tr.msg('Версия по умолчанию'),
  setStableVersion: tr.msg('Установить stable версию'),
  setBetaVersion: tr.msg('Установить beta версию'),
  hasAllVersions: tr.msg('Success / Версии stable и beta правильные.'),
  missStable: tr.msg('Нет версии stable!'),
  missBeta: tr.msg('Нет версии beta!'),
  hasErrors: tr.msg('Ошибка в версиях'),
  hasErrorsDependencies: tr.msg('Ошибка в зависимостях'),
  notFound: tr.msg('Описания нет, фича не участвует в сборке!')
};

export default {

  components: {
    FeatureVersionSelector: FeatureVersionSelector.default,
    DependenciesInfo: DependenciesInfo.default
  },

  props: {
    feature: {
      type: Object,
      required: true
    }
  },

  data: function() {
    return {
      labels: labels,
      messages: {
        copyFeatureName: tr.msg('Копировать наименование фичи'),
        copyFeatureNameAndStable: tr.msg('Копировать наименование фичи и номер stable версии'),
        copyFeatureNameAndBeta: tr.msg('Копировать наименование фичи и номер beta версии'),
        copyDone: tr.msg('Успешно скопировано')
      },
      appModes: appModes
    };
  },

  computed: {
    tags() {
      return this.feature.tags;
    },

    stableVersion() {
      const {
        stable,
        stableConfig,
        versions
      } = this.feature;
      const isStableConfigObject = stableConfig && typeof stableConfig === 'object';

      const stableVersion = stable || (isStableConfigObject ? stableConfig.value : stableConfig);

      return stableVersion && versions.find(el => el.version === stableVersion);
    },

    betaVersion() {
      const {
        beta,
        betaConfig,
        versions
      } = this.feature;
      const isBetaConfigObject = betaConfig && typeof betaConfig === 'object';

      const betaVersion = beta || (isBetaConfigObject ? betaConfig.value : betaConfig);

      return betaVersion && versions.find(el => el.version === betaVersion);
    }
  },

  methods: {
    ...mapMutations({
      selectTag: 'selectTag',
      updateFeature: 'updateFeature',
      setSearchQuery: 'setSearchQuery'
    }),

    updateFeatures() {
      this.reloadFeature();
    },

    scrollToFeature(name) {
      this.$emit('scroll-to-feature', name);
    },

    onStableChange() {
      EE.fire(EE.LOCAL_FEATURES_CHANGED);
      this.reloadFeature();
    },

    onBetaChange() {
      EE.fire(EE.LOCAL_FEATURES_CHANGED);
      this.reloadFeature();
    },

    reloadFeature() {
      this.updateFeature();
    },

    onClickTag(tag) {
      this.selectTag(tag);
    },

    copyFeatureName() {
      this.copyToClipboardAndNotify(this.feature.name);
    },

    copyFeatureNameAndStable() {
      const version = this.$refs.stableVersionSelector.getVersion();
      const text = `"${this.feature.name}": "${version}"`;
      this.copyToClipboardAndNotify(text);
    },

    copyFeatureNameAndBeta() {
      const version = this.$refs.betaVersionSelector.getVersion();
      const text = `"${this.feature.name}": "${version}"`;
      this.copyToClipboardAndNotify(text);
    },

    copyToClipboardAndNotify(text) {
      copyToClipboard({
        text: text
      });

      EE.fire(EE.NOTIFY, {
        type: 'info-disappear',
        once: true,
        html: `${this.messages.copyDone}: ${text}`
      });
    }
  }
};
</script>

<template>
  <section class="twx-feature" :class="feature.chosenClass" :data-feature="feature.name">
    <div class="twx-feature__section twx-features__flex">
      <div class="twx-feature__name-box">
        <header class="twx-feature__name-header">
          <span v-if="feature.ticket" class="twx-feature__ticket-wrap">
            <a
              :href="'https://jira.ors.aero/browse/' + feature.ticket"
              target="_blank"
              class="twx-feature__ticket-link"
              data-tooltip-position="bottom-left"
              data-tooltip-style="dark"
              :data-tooltip="'https://jira.ors.aero/browse/' + feature.ticket"
            >
              {{ feature.ticket }}
            </a>
          </span>
          <h6 class="twx-feature__name">{{ feature.name }}</h6>
        </header>
        <blockquote v-if="feature.description" class="twx-feature__name-desc">
          {{ feature.description }}
        </blockquote>

        <div v-if="tags && tags.length" class="twx-feature__tags">
        <span
          v-for="tag in tags"
          class="twx-feature__tag"
          @click="onClickTag(tag)"
        >
          {{ tag }}
        </span>
        </div>
      </div>
      <button
        :title="messages.copyFeatureName"
        class="twx-features__copy-btn"
        @click="copyFeatureName"
      />
    </div>
    <div class="twx-feature__section">
      <ul class="twx-feature__versions">
        <template v-for="item in feature.versions">
          <template v-if="item.default">
            <li class="twx-feature__version">
              <span
                class="twx-feature__version-num twx-feature__version-num_default"
                data-tooltip-position="bottom-left"
                data-tooltip-style="dark"
                :data-tooltip="labels.defaultVersion"
              >{{ item.version }}</span>
              <template v-if="item.description">
                - <p class="twx-feature__version-desc">{{ item.description }} </p>
              </template>
            </li>
          </template>
          <template v-else>
            <li class="twx-feature__version">
              <span class="twx-feature__version-num">{{ item.version }}</span>
              <template v-if="item.description">
                - <p class="twx-feature__version-desc">{{ item.description }}</p>
              </template>
            </li>
          </template>
        </template>
      </ul>
    </div>

    <div class="twx-feature__section">
      <div class="twx-feature__select-wrap">
        <header class="twx-feature__select-wrap-version">
          <h4 class="twx-feature__set-title">{{ labels.setStableVersion }}</h4>
          <div class="twx-features__flex">
            <feature-version-selector
              ref="stableVersionSelector"
              class="twx-feature__selector"
              :feature="feature"
              :mode="appModes.stable"
              :isDisabled="feature.notFound"
              @feature-version-changed="onStableChange"
            />
            <button
              :title="messages.copyFeatureNameAndStable"
              class="twx-features__copy-btn"
              @click="copyFeatureNameAndStable"
            />
            <dependencies-info
              v-if="stableVersion && stableVersion.dependencies && Object.keys(stableVersion.dependencies).length"
              :version="stableVersion"
              :mode="appModes.stable"
              @update-features="updateFeatures"
              @scroll-to-feature="scrollToFeature"
            />
          </div>
        </header>
        <footer class="twx-feature__select-wrap-version">
          <h4 class="twx-feature__set-title">{{ labels.setBetaVersion }}</h4>
          <div class="twx-features__flex">
            <feature-version-selector
              ref="betaVersionSelector"
              class="twx-feature__selector"
              :feature="feature"
              :mode="appModes.beta"
              :isDisabled="feature.notFound"
              @feature-version-changed="onBetaChange"
            />
            <button
              :title="messages.copyFeatureNameAndBeta"
              class="twx-features__copy-btn"
              @click="copyFeatureNameAndBeta"
            />
            <dependencies-info
              v-if="betaVersion && betaVersion.dependencies && Object.keys(betaVersion.dependencies).length"
              :version="betaVersion"
              :mode="appModes.beta"
              @update-features="updateFeatures"
              @scroll-to-feature="scrollToFeature"
            />
          </div>
        </footer>
      </div>
    </div>
    <div class="twx-feature__section">
      <div class="twx-feature__status">
        <template v-if="feature.hasErrors && !(feature.hasErrorStableDependencies || feature.hasErrorBetaDependencies)">
          <p class="twx-feature__status-par">
            {{ labels.hasErrors }}
          </p>
        </template>
        <template v-if="feature.hasErrorStableDependencies || feature.hasErrorBetaDependencies">
          <p class="twx-feature__status-par">
            {{ labels.hasErrorsDependencies }}
          </p>
        </template>
        <p v-if="feature.hasAllVersions && !(feature.hasErrorStableDependencies || feature.hasErrorBetaDependencies)">
          {{ labels.hasAllVersions }}</p>
        <p v-if="feature.missStable">{{ labels.missStable }}</p>
        <p v-if="feature.missBeta">{{ labels.missBeta }}</p>
        <p v-if="feature.notFound">{{ labels.notFound }}</p>
      </div>
    </div>
  </section>
</template>

<style lang="cssnext" scoped>
.twx-feature__version {
  grid-template-columns: auto auto auto 1fr;

  .br-button {
    padding: 2px;
  }

  .br-rule {
    &__action {
      margin-left: auto;
    }
  }
}
</style>
