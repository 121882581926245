/*
* --------------------------------------------------
* This file contains styles that are specific to the
* AUTHORIZATION screen of TWTt and it's elements.
* --------------------------------------------------
*/

/*
* AUTHORIZATION
*/

.screen_auth {
  position: relative;

  min-height: 0;
  overflow-y: visible;
}

.screen_auth .screen__header {
  display: flex;
  align-items: center;
  padding: 0;

  background: none;
  border-bottom: none;
}

.screen_auth .screen__body {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.auth {
  position: relative;
  z-index: calc( var(--z-main-content) + 1 );
  display: flex;
  flex-direction: column;
  min-width: 36.25rem;
  min-height: 21.125rem;
}

.auth__body {
  display: block;
  padding: 26px;

  background-color: #f5f5f5;
}

.auth__body::before {
    display: block;
    content: " ";
    height: 50px;
    margin-bottom: 30px;
    background-image: url("tais-styles/svg/ors-logo-new.svg");
    background-position: center center, center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.auth__form {
  display: grid;
  grid-template-columns: 23.375rem max-content;
  grid-gap: 1.625rem;
}

.auth__set {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.auth__input {
  display: block;
  margin-bottom: 26px;
}

.auth__input-field {
  transition: -webkit-box-shadow 0.2s;
}

.auth .auth__input-field:focus {
  background-color: rgba(76, 165, 255, 0.1);
}

.auth .auth__input-field:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px rgb(251, 252, 238) inset;
}

.auth input.auth__input-field:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px rgb(228, 239, 251) inset;
}

.auth .auth__input-field {
  height: unset;
  padding: 6px 4px;

  font-size: 20px;
  line-height: 30px;
}

.auth__server-cnt {
  margin-bottom: 12px;

  .select__control {
    height: 2.125rem;
    padding: 0.375rem 1.5rem 0.375rem 0.25rem;
    text-overflow: ellipsis;
    line-height: unset;
  }

  .select__control:hover {
    background-color: rgba(76, 165, 255, 0.1);
  }

  .select {
    width: 100%;
  }

  .input {
    width: 100%;
  }
}

.auth__title {
  display: block;

  text-align: center;
  color: #333333;
}

.auth__apps {
  position: relative;

  display: flex;
  flex-grow: 1;
  align-items: center;
}

.auth__apps-btn {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 20%;
  padding: 10px 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  color: var(--color-logo);
  text-transform: uppercase;

  background-color: #eeeeee;
  border: solid #bbbbbb;
  border-width: 0 1px 1px 0;
  border-radius: 0 0 5px 5px;

  transition: background-color 0.1s, border-color 0.1s;
}

.auth__apps-btn:first-of-type {
  border-left-width: 0;
}

.auth__apps-btn:last-of-type {
  border-right-width: 0;
}

.auth__apps-btn:hover {
  color: #666666;
  background-color: #e9e9e9;
}

.auth__apps-btn_current,
.auth__apps-btn_current:hover {
  color: #666666;
  text-shadow: 0 0 8px #ffffff;

  background-color: #dcdcdc;
  border: none;
  border-bottom-color: transparent;
  border-radius: unset;
  cursor: text;
}

.auth__apps-btn_current + .auth__apps-btn {
  border-left: 1px solid #bbbbbb;
}

.auth .auth__input-field::placeholder {
  height: auto;

  line-height: normal;
}

.auth__btn-cnt {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: baseline;
  grid-column: 1 / -1;
}

.auth__btn-cnt .btn-login,
.auth__footer .btn-login {
  display: inline-grid;
  align-items: center;
  padding: 0 50px;
  font-size: 18px;
  background-color: var(--brand-highlight);
  border-color: var(--brand-highlight);
  transition: background-color 0.1s, border-color 0.1s;
  grid-auto-flow: column;
  grid-column-gap: 0.625rem;
}

.auth__btn-cnt .btn-login:hover,
.auth__footer .btn-login:hover {
  background-color: var(--brand-highlight-sec);
  border-color: var(--brand-highlight-sec);
}

.auth__btn-cnt .btn-login[disabled],
.auth__btn-cnt .btn-login[disabled]:hover,
.auth__footer .btn-login[disabled],
.auth__footer .btn-login[disabled]:hover {
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-login .loading-spinner {
  display: none;
}

.btn-login-spinner[disabled] {
  padding: 0 30px 0 50px;

  .loading-spinner {
    display: inline-block;
    margin: 0;
  }

  .loading-spinner::after {
    background-color: #cccccc;
  }
}


.auth__btn-cnt .loading-spinner,
.auth__footer .loading-spinner {
  margin: 0 0 0 20px;
}

@media (max-width: 24rem) {
  .auth__body {
    width: auto;
  }
}

.auth-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-main-content);

  padding-bottom: 1.75rem;

  font-size: var(--font-size-s);
  text-align: center;
  color: var(--color-light);
}

.input-login {
  text-transform: uppercase;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

.auth-fieldset_grid {
  display: grid;
  align-content: start;
  grid-gap: 0.3125rem;
}

.auth__reset {
  margin: 0;
  text-align: center;
}

.auth__footer {
  display: grid;
  grid-column: 1 / -1;
  justify-content: center;
}
