/*
* --------------------------------------------------
* This file contains all styles related to the RT icon component.
* --------------------------------------------------
*/

/* default icon settings */
.twd-icon {
  --twd-icon-bg-default: #cccccc;
  --twd-icon-bg-primary: var(--brand-highlight);
  --twd-icon-bg-error: #ff0000;
  --twd-icon-bg-success: #16a803;
  --twd-icon-bg-attention: #e59700;
  --twd-icon-bg-changed: var(--brand-changed);
  --twd-icon-bg-disabled: rgba(0,0,0, 0.26);

  --twd-icon-prefix-color-default: #666666;
  --twd-icon-number-color-default: #000000;

  --twd-icon-prefix-color-active: #ffffff;
  --twd-icon-number-color-active: #ffffff;

  --twd-icon-size-small: 1.5rem; /* 24px */
  --twd-icon-size-default: 1.625rem; /* 26px */
  --twd-icon-size-large: 2.5rem; /* 40px */

  --twd-icon-fs-xsmall: 0.5rem;
  --twd-icon-fs-small: 0.625rem;
  --twd-icon-fs-medium: 0.75rem;
  --twd-icon-fs-default: 0.875rem;
  --twd-icon-fs-lagre: 1rem;
  --twd-icon-fs-xlagre: 1.25rem;
}

/* DO NOT REPLACE px with rem */
.twd-icon {
  --twd-icon-size: var(--twd-icon-size-default);
  --twd-icon-fs: var(--twd-icon-fs-default);

  --twd-icon-bg: var(--twd-icon-bg-default);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-default);
  --twd-icon-number-color: var(--twd-icon-number-color-default);

  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: var(--twd-icon-size);
  height: var(--twd-icon-size);
  min-height: var(--twd-icon-size);
  flex: 0 0 auto;
  font-weight: bold;
  font-size: 0.75rem;
  background-color: var(--twd-icon-bg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100%;
  border-radius: 16px;
  padding: 0.125rem;
  line-height: 1;
}

/* Icon variations */
.twd-icon.twd-icon_missing {
  background-image: none;
}

.twd-icon_adult {
  background-image: url(tais-styles/svg/full_mode/badge-adult.svg);
}

.twd-icon_child {
  background-image: url(tais-styles/svg/full_mode/badge-child.svg);
}

.twd-icon_infant {
  background-image: url(tais-styles/svg/full_mode/badge-infant.svg);
}

.twd-icon_flight {
  background-image: url(tais-styles/svg/full_mode/badge-flight.svg);
}

.twd-icon_light.twd-icon_adult {
  background-image: url(tais-styles/svg/light_mode/badge-adult.svg);
}

.twd-icon_light.twd-icon_child {
  background-image: url(tais-styles/svg/light_mode/badge-child.svg);
}

.twd-icon_light.twd-icon_infant {
  background-image: url(tais-styles/svg/light_mode/badge-infant.svg);
}

.twd-icon_light.twd-icon_flight {
  background-image: url(tais-styles/svg/light_mode/badge-flight.svg);
}

.twd-icon_light.twd-icon_handbag {
  background-image: url(tais-styles/svg/light_mode/badge-handbag.svg);
}

.twd-icon_light.twd-icon_holdbag {
  background-image: url(tais-styles/svg/light_mode/badge-holdbag.svg);
}

.twd-icon_light.twd-icon_service {
  background-image: url(tais-styles/svg/light_mode/badge-service.svg);
}

.twd-icon_light.twd-icon_print {
  background-image: url(tais-styles/svg/light_mode/badge-print.svg);
  background-size: initial;
}

/* Icon text */
.twd-icon__prefix {
  color: var(--twd-icon-prefix-color);
  font-size: inherit;
}

.twd-icon__number {
  color: var(--twd-icon-number-color);
  font-size: inherit;
}

/* Icon sizes */
.twd-icon {
  &.twd-icon_font-small {
    --twd-icon-fs: var(--twd-icon-fs-medium);
  }

  &.twd-icon_font-smallest {
    --twd-icon-fs: var(--twd-icon-fs-small);
  }
}

.twd-icon_small {
  --twd-icon-size: var(--twd-icon-size-small);
  --twd-icon-fs: var(--twd-icon-fs-medium);

  &.twd-icon_font-small {
    --twd-icon-fs: var(--twd-icon-fs-small);
  }

  &.twd-icon_font-smallest {
    --twd-icon-fs: var(--twd-icon-fs-xsmall);
  }
}

/* DO NOT REPLACE px with rem */
.twd-icon_large {
  --twd-icon-size: var(--twd-icon-size-large);
  --twd-icon-fs: var(--twd-icon-fs-xlagre);
  border-radius: 22px;

  &.twd-icon_font-small {
    --twd-icon-fs: var(--twd-icon-fs-lagre);
  }

  &.twd-icon_font-smallest {
    --twd-icon-fs: var(--twd-icon-fs-medium);
  }
}

/* Icon colors */
.twd-icon_active {
  --twd-icon-bg: var(--twd-icon-bg-primary);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-active);
  --twd-icon-number-color: var(--twd-icon-number-color-active);
}

.twd-icon_success {
  --twd-icon-bg: var(--twd-icon-bg-success);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-active);
  --twd-icon-number-color: var(--twd-icon-number-color-active);
}

.twd-icon_changed {
  --twd-icon-bg: var(--twd-icon-bg-changed);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-active);
  --twd-icon-number-color: var(--twd-icon-number-color-active);
}

.twd-icon_error {
  --twd-icon-bg: var(--twd-icon-bg-error);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-active);
  --twd-icon-number-color: var(--twd-icon-number-color-active);
}

.twd-icon_attention {
  --twd-icon-bg: var(--twd-icon-bg-attention);
  --twd-icon-prefix-color: var(--twd-icon-prefix-color-active);
  --twd-icon-number-color: var(--twd-icon-number-color-active);
}

.twd-icon_loading {
  background-image: url(tais-styles/svg/full_mode/badge-loading.svg) !important;

  animation: loading 5s linear infinite;
}

.twd-icon_loading .twd-icon__prefix,
.twd-icon_loading .twd-icon__number {
  display: none;
}

@keyframes loading {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg);
  }
}
