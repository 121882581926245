/*
* --------------------------------------------------
* This file contains all styles related to the icon component.
* --------------------------------------------------
*/

/* default icon settings */
.br-icon {
  --icon-color-default: #999999;
  --icon-color-primary: var(--brand-highlight);
  --icon-color-error: #ff0000;
  --icon-color-success: #16a803;
  --icon-color-disabled: rgba(0,0,0, 0.26);

  --icon-font-size-x-small: 0.625rem;
  --icon-font-size-small: 0.875rem;
  --icon-font-size-default: 1rem;
  --icon-font-size-medium: 1.125rem;
  --icon-font-size-large: 1.375rem;
  --icon-font-size-x-large: 1.625rem;
}

.br-icon {
  --icon-font-size: var(--icon-font-size-default);
  --icon-color: var(--icon-color-default);

  position: relative;

  display: inline-block;
  width: 1em;
  height: 1em;

  color: var(--icon-color);
  font-size: var(--icon-font-size);
}

.br-icon_rounded {
  border-radius: 50%;
}

.br-icon_primary {
  --icon-color: var(--icon-color-primary);
}

.br-icon_success {
  --icon-color: var(--icon-color-success);
}

.br-icon_error {
  --icon-color: var(--icon-color-error);
}

.br-icon_disabled {
  --icon-color: var(--icon-color-disabled);
}

.br-icon_inverted {
  color: #ffffff;

  background-color: var(--icon-color);
}

.br-icon_xs {
  --icon-font-size: var(--icon-font-size-x-small);
}

.br-icon_s {
  --icon-font-size: var(--icon-font-size-small);
}

.br-icon_m {
  --icon-font-size: var(--icon-font-size-medium);
}

.br-icon_l {
  --icon-font-size: var(--icon-font-size-large);
}

.br-icon_xl {
  --icon-font-size: var(--icon-font-size-x-large);
}
