.br-filter {
  position: relative;

  display: inline-block;
  width: 1.125rem;
  height: 1.375rem;

  background-image: url(tais-styles/svg/btn-rules-header-filter.svg);
  background-repeat: no-repeat;
  cursor: pointer;
}

.br-filter_expanded {
  width: 1.375rem;

  background-image: url(tais-styles/svg/btn-rules-header-filter__expanded.svg);
}

.br-filter_applied {
  width: 1.375rem;

  background-image: url(tais-styles/svg/btn-rules-header-filter__applied.svg);
}

.br-filter_block {
  display: block;
}
