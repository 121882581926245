.screen_sm2 {
  .screen__header-cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .page_with-sidebar & {
      justify-content: flex-start;

      @media(min-width: 1370px) {
        justify-content: space-between;
      }
    }
  }

  .screen__name-subtitle {
    white-space: nowrap;
  }

  .screen__body {
    display: flex;
    flex-direction: column;
  }

  .sm-bg {
    padding: 0;
    max-height: 100%;
    background-color: #eee;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;

      &:hover {
        background-color: #666;
      }
    }
  }

  .sm_deck__top {
    display: flex;
    flex-shrink: 0;
    flex-flow: row nowrap;
    width: 100%;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .sm-seattypes {
    display: flex;
    flex-wrap: wrap;
    max-width: 580px;
    margin-bottom: 20px;

    .sm-st__cell {
      display: flex;
      align-items: center;
      min-width: 33%;
      max-width: 33%;
      margin: 0 0 14px 0;
      padding-right: 10px;
    }

    .sm__place_title {
      display: flex;
      font-size: 10px;
      text-transform: uppercase;
    }

    .sm__place {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      width: 50px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    .sm__seat {
      width: 40px;
      margin-right: 8px;
      background-color: #bcbcbc;
      border-color: #bcbcbc;
    }

    .sm__seat_occupied {
      background: #fff;

      &::after {
        background: #bcbcbc;
      }
    }

    .sm__seat.sm-icon {
      background-color: transparent;
      border-color: transparent;

      &.sm-icon {
        &_buffet,
        &_restroom,
        &_exit {
          border: 2px solid #bcbcbc;

          &:after {
            display: none;
          }
        }

        &_exit {
          transform: rotate(90deg);
        }
      }
    }

    .sm-icon.sm-icon_wing {
      width: 10px;
      height: 50px;
      background-color: #999;
      border-radius: 0;
      justify-self: center;

      &:after {
        display: none;
      }
    }

    .sm-icon.sm-icon_window {
      display: inline-block;
      width: 20px;
      height: 24px;
      content: '';
      border: 2px solid #73dcff;
      border-radius: 10px;
      margin-top: 7px;

      &:after {
        display: none;
      }
    }
  }

  .sm-seattypes-header {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #666;
  }

  .sm-icon {
    &_buffet {
      background: url(tais-styles/svg/buffet.svg) 50% 50% no-repeat;
      background-size: 32px;
    }

    &_exit {
      background: url(tais-styles/svg/exit.svg) 50% 50% no-repeat;
      transform: rotate(90deg);
    }

    &_emergency-exit {
      background: url(tais-styles/svg/emergency-exit.svg) 50% 50% no-repeat;
      background-size: 34px;
    }

    &_restroom {
      background: url(tais-styles/svg/restroom.svg) 50% 50% no-repeat;
      background-size: 32px;
    }
  }

  .sm-legends {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    margin: 0 0 30px;
    flex-grow: 1;
    min-width: 362px;
  }

  .sm-legends__header {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: #666;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  .sm-legends__class-header {
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    padding: 4px 6px;
    font-weight: bold;
    border-radius: 2px;
    display: inline-block;
    color: #666;
  }

  .sm-legend {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    padding: 0 0 20px;
    list-style: none;
    margin: 0;
    overflow-y: auto;

    &__item {
      overflow: visible;
      color: #000;
      font-size: 10px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      white-space: normal;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        width: auto;
        height: auto;
        border: 0;
        margin-right: 0;
      }

      &[data-tooltip] {
        width: 100%;

        &:before {
          width: 100%;
          white-space: inherit;
        }
      }

      &-bullet {
        box-sizing: border-box;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        vertical-align: middle;
        border-radius: 2px;
        position: relative;
        flex-shrink: 0;
      }

      &-bullet:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% + 1px);
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: inherit;
      }

      &_business, &_economy {
        margin-right: 15px;
      }

      &_business .sm-legend__item-bullet {
        background-color: var(--color-orange);
      }

      &_economy .sm-legend__item-bullet {
        background-color: var(--color-green);
      }

      &_0 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-0);
      }

      &_1 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-1);
      }

      &_2 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-2);
      }

      &_3 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-3);
      }

      &_4 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-4);
      }

      &_5 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-5);
      }

      &_6 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-6);
      }

      &_7 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-7);
      }

      &_8 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-8);
      }

      &_st5 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st5);
      }

      &_st3 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st3);
      }

      &_bas .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-bas);
      }

      &_sta .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-sta);
      }

      &_0l8 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-0l8);
      }

      &_cmf .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-cmf);
      }

      &_0l9 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-0l9);
      }

      &_stp .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-stp);
      }

      &_spf .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-spf);
      }

      &_psa .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-psa);
      }

      &_prs .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-prs);
      }

      &_pfs .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-pfs);
      }

      &_0nk .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-0nk);
      }

      &_st7 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st7);
      }

      &_st6 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st6);
      }

      &_pss .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-pss);
      }

      &_st4 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st4);
      }

      &_pps .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-pps);
      }

      &_st2 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st2);
      }

      &_ob5 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-ob5);
      }

      &_st1 .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-st1);
      }

      &_frf .sm-legend__item-bullet {
        background-color: var(--bg-seat-price-frf);
      }

      &_unavailable .sm-legend__item-bullet {
        background: #ccc;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: 20px;
          height: 20px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: url(tais-styles/svg/unavailable-seat.svg) no-repeat;
        }

        &:after {
          top: calc(100% + 2px);
          width: 100%;
          left: 0;
          background-color: #ccc;
        }
      }

      &_occupied .sm-legend__item-bullet {
        background: #fff;
        border: 1px solid #bcbcbc;

        &:after {
          border: 1px solid #bcbcbc;
          top: calc(100% + 2px);
          width: calc(100% + 2px);
          left: -1px;
        }
      }
    }

    &__item-count {
      font-size: 12px;
      line-height: 12px;
      margin-right: 5px;
    }

    &__item-count-desc {
      font-style: normal;
    }
  }

  /* Дополнительное место для ног */
  .st-L {

    .sm__seat {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: '';
      }
    }
  }

  /* С люлькой */
  .st-B .sm__seat .sm__stuff {
    display: block;
    position: absolute;
    height: 6px;
    width: 72%;
    left: 14%;
    bottom: calc(100% + 5px);
    margin-right: 18px;
    background-color: #000;
  }

  /* С не откидывающейся спинкой */
  .st-1D .sm__seat::after {
    height: 6px;
    margin-top: 4px;
    width: calc(100% + 4px);
    left: -2px;
    background: transparent;
    border-style: solid;
    border-color: inherit;
    border-width: 0 2px 2px;
    border-radius: 0 0 7px 7px;
  }

  .st-1D .sm__seat_bas.sm__seat_occupied::after {
    background: transparent;
    left: -3px;
    width: calc(100% + 6px);
  }

  /* Недоступно = Ограничено по продаже */
  .st-1 .sm__seat {
    .sm__stuff {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &_in {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }

      &::before,
      &::after,
      &_in::before,
      &_in::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 2px;
        border-radius: 1px;
        background: #ffffff;
      }

      &::before {
        left: 1px;
        top: 1px;
        transform: rotate(45deg);
        transform-origin: 0 100%;
      }
      &::after {
        right: 2px;
        top: 1px;
        transform: rotate(-45deg);
        transform-origin: 100% 100%;
      }
      &_in::before {
        left: 1px;
        bottom: 1px;
        transform: rotate(-45deg);
        transform-origin: 0 0;
      }
      &_in::after {
        right: 2px;
        bottom: 1px;
        transform: rotate(45deg);
        transform-origin: 100% 0;
      }
    }
  }

  /* Вместо кресла */
  .st-D, .st-EX, .st-GN, .st-LA {
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  /**
  * PLANE SCHEME
  */
  .plane {
    margin: 0 20px 0 0;
    width: auto;
    flex-grow: 1;
    flex-basis: 0;

    &__head {
      width: 79px;
      margin-right: 12px;

      &-img {
        width: 79px;
        height: 130px;
      }
    }

    &__rows-nums {
      height: 100%;
      flex: 1 1 1%;
    }

    &__rows-nums_econom {
      background-color: transparent;
    }

    &__rows-nums-wrap {
      margin: 0 36px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .sm__row-num {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__cabin {
      &-heading {
        margin: 0 0 0 36px;
        font-size: var(--font-size-md);
      }
    }

    &__tail {
      margin-left: 12px;

      &-img {
        width: 46px;
        height: 129px;
      }
    }
  }

  .plane__cabin {
    max-width: unset;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .plane__cabin-head {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin: 0 0 10px;
  }

  /**
  * SEAT MAP arrows
  */
  .sm-nav {
    &__cnt {
      position: relative;

      &.active {
        cursor: pointer;

        &:hover {
          background-color: lightyellow;
        }
      }
    }

    &__arr {
      position: absolute;
      top: calc(50% - 12px);

      display: block;
      width: 24px;
      height: 24px;

      transform-origin: 50% 50% 0;

      &-in {
        display: block;

        font-size: .9375rem;
        font-weight: bold;
        text-align: center;

        color: var(--bg-dark);
        line-height: 24px;
      }

      &_prev {
        right: -9px;

        border-top: 2px solid var(--border-sm-nav);
        border-left: 2px solid var(--border-sm-nav);

        transform: rotate(-45deg);

        &-in {
          transform: rotate(45deg);
        }
      }
      &_next {
        left: -9px;

        border-top: 2px solid var(--border-sm-nav);
        border-right: 2px solid var(--border-sm-nav);

        transform: rotate(45deg);

        &-in {
          transform: rotate(-45deg);
        }
      }

      &_disabled {
        border-color: var(--border-sm-nav-disabled);
      }
    }
  }

  .st__codes {
    display: none;
  }

  /**
  * SEAT MAP
  */
  .sm {
    overflow: auto;
    padding: 0 20px;
    flex-direction: column;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 9px;
    }

    &-deck {
      &-list {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        min-width: 1378px;
      }

      &-list::-webkit-scrollbar {
        width: 9px;
      }

      &-item {
        display: none;
        align-self: center;
        max-height: 100%;
        background-color: #fff;
        padding:10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &_active {
          display: flex;
        }

        .plane__head {
          width: auto;
        }
      }

      &-tabs {
        display: flex;
        margin-bottom: 6px;
      }

      &-tab {
        border: 1px solid var(--color-disabled);
        color: var(--color-disabled);
        padding: 3px 24px;
        cursor: pointer;

        &:first-child {
          margin-right: calc(var(--border-radius) * -1);
          border-top-left-radius: var(--border-radius);
          border-bottom-left-radius: var(--border-radius);
        }

        &:last-child {
          border-top-right-radius: var(--border-radius);
          border-bottom-right-radius: var(--border-radius);
        }

        &_active {
          background-color: var(--color-disabled);
          color: #fff;
        }
      }
    }

    &__row {
      /* One row */
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 auto;
      height: auto;

      /* У перегородки */
      &_near-division {
        border-top: 2px solid var(--color-light);
      }
    }

    &__row:first-child {
      .sm__row-num {
        line-height: 16px;
        height: auto;
        padding-bottom: 10px;
      }
    }

    .sm__wing-indicator {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -25px;
      width: 10px;
      height: 102px;
      background-color: #999;
      text-decoration: none;
    }

    .sm__wing-indicator_left {
      left: -10px;
    }

    .sm__wing-indicator_right {
      left: auto;
      right: -10px;
    }

     &__window {
      padding: 6px;

      text-align: center;

      &:after {
        box-sizing: border-box;
        display: inline-block;
        width: 20px;
        height: 24px;

        content: '';

        border: 2px solid #73dcff;
        border-radius: 10px;
      }

      .sm__row_first & {
        /* Place in a first row after a bulkhead in economy class */
        padding-left: 9px;
        margin-left: -1px;
      }

      .sm__row_first-business & {
        /* Place in a first row in a business class */
        padding-left: 8px;
      }

      .sm__row_last & {
        /* Last row in economy class */
        padding-right: 5px;
      }

      .sm__row_last-business & {
        /* Last row in business class */
        padding-right: 9px;
      }

      .sm__row_extra & {
        /* Row with an extra legroom */
        padding-left: 21px;
      }
    }

    &__row_emergency-exit_L .sm__window.left,
    &__row_emergency-exit_R .sm__window.right {
      &::after {
        background: url(tais-styles/svg/emergency-exit.svg) center center no-repeat;
        border: 0;
        border-radius: 0;
      }
    }

    &__row_emergency-exit_R .sm__window.right {
      transform: rotate(180deg);
    }

    &__cab {
      /* One row of seats */
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 0 8px;

      &_business {
        /* Row in business class */
        background: var(--bg-orange);
      }
      &_economy {
        /* Row in economy class */
        background: var(--bg-green);
      }
    }

    &__row_last-in-class .sm__cab {
      padding-bottom: 10px;
    }

    &__class-title-business,
    &__class-title-economy {
      display: inline-flex;
      flex-direction: column;
      padding-left: 0;
      border-left: 1px solid;
      min-width: 172px;
      max-width: calc(50% - 5px);
    }

    &__class-title-business {
      color: var(--color-sm-business-class);
      border-left: none;
      min-height: 100%;
      max-height: 100%;

      .sm-legends__class-header {
        background-color: var(--bg-orange);
      }
    }

    &__class-title-economy {
      color: var(--color-green);
      border-left: none;
      margin-left: 0;
      min-height: 100%;
      max-height: 100%;
      width: auto;

      .sm-legends__class-header {
        background-color: var(--bg-green);
      }
    }

    &__row-num, &__place {
      width: 40px;
      height: 40px;
      padding: 10px 1px 10px 1px;
    }


    &__row-num {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: content-box;
      font-weight: bold;
      font-size: 16px;
      color: var(--color-light);

      .sm__aisle ~ & {
        margin-top: 2px;
        margin-bottom: 0;
      }
    }

    &__aisle {
      /* Aisle */
      min-width: 24px;
      height: 18px;
    }


    &__stuff {
      display: none;
      &_in {
        display: none;
      }
    }

    &__place {
      /* Block of one place */
      box-sizing: content-box;
      position: relative;

      .sm-icon {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border: 2px solid #bcbcbc;
        border-radius: 4px;
      }

      .sm__aisle ~ & {
        padding-top: 2px;
        padding-bottom: 0;
      }

      .sm__row_first & {
        /* Place in a first row after a bulkhead in economy class */
        padding-left: 8px;
        margin-left: -1px;
      }

      .sm__row_first-business & {
        /* Place in a first row in a business class */
        padding-left: 8px;
      }

      .sm__row_last & {
        /* Last row in economy class */
        padding-right: 5px;
      }

      .sm__row_last-business & {
        /* Last row in business class */
        padding-right: 9px;
      }

      .sm__row_extra & {
        /* Row with an extra legroom */
        padding-left: 21px;
      }
    }

    .st__codes {
      display: none;
      font-size: 0.65em;
      font-weight: 400;
      opacity: 0.7;
    }

    .st_filtered {
      .st__codes {
        display: flex;
        flex-flow: column wrap;

        &-itm {
          /* PAY_SEAT */
          &__1 {
            font-size: 0.9em;
          }
        }
      }

      .sm__seat_codes-4:not(.sm__seat_hovered) {
        font-size: 11px;
      }
    }

    .st_muted {
      .sm__seat {
        opacity: .32;
      }
    }

    &_pnr .sm__seat {
      cursor: pointer;

      &_unavailable, &_occupied, &_non-selectable {
        cursor: default;
      }
    }

    &__seat {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      justify-content: center;
      position: relative;
      font-size: 13px;
      line-height: 13px;
      border-radius: 4px;
      color: #fff;
      width: 100%;
      height: 100%;
      transform: unset;

      &-label {
        font-weight: normal;
      }

      &::before {
        position: absolute;
        height: 8px;
        width: calc(100% + 4px);
        bottom: 100%;
        border-radius: 4px 4px 0 0;
        margin-left: 0;
        border-style: solid;
        border-width: 2px 2px 0;
        border-color: inherit;
        background: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: inherit;
        border-color: inherit;
        left: 0;
        top: 100%;
        width: 100%;
        height: 10px;
        border-top: 2px solid;
        border-radius: 4px;
      }

      &.sm__seat_occupied::before {
        width: calc(100% + 6px);
        bottom: calc(100% + 1px);
      }

      &-type {
        opacity: .7;
        font-size: 6px;
        width: 100%;
        display: inline-block;
        text-align: center;
      }

      & > b {
        color: var(--color-seat-letter);
      }

      &_business {
        background-color: var(--color-orange);
        border-color: var(--color-orange);
      }

      &_business.sm__seat_occupied {
        color: var(--color-orange);

        &::after {
          background: var(--color-orange);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: #bcbcbc;;
        }
      }

      &_economy {
        background-color: var(--color-green);
        border-color: var(--color-green);
      }

      &_economy.sm__seat_occupied {
        color: var(--color-green);

        &::after {
          background: var(--color-green);
          border-top-color: #fff;
          top: calc(100% + 1px);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: #bcbcbc;
        }
      }

      &_0 {
        background-color: var(--bg-seat-price-0);
        border-color: var(--bg-seat-price-0);
      }

      &_0.sm__seat_occupied {
        color: var(--bg-seat-price-0);

        &::after {
          background: var(--bg-seat-price-0);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-0);
        }
      }

      &_1 {
        background-color: var(--bg-seat-price-1);
        border-color: var(--bg-seat-price-1);
      }

      &_1.sm__seat_occupied {
        color: var(--bg-seat-price-1);

        &::after {
          background: var(--bg-seat-price-1);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-1);
        }
      }

      &_2 {
        background-color: var(--bg-seat-price-2);
        border-color: var(--bg-seat-price-2);
      }

      &_2.sm__seat_occupied {
        color: var(--bg-seat-price-2);

        &::after {
          background: var(--bg-seat-price-2);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-2);
        }
      }

      &_3 {
        background-color: var(--bg-seat-price-3);
        border-color: var(--bg-seat-price-3);
      }

      &_3.sm__seat_occupied {
        color: var(--bg-seat-price-3);

        &::after {
          background: var(--bg-seat-price-3);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-3);
        }
      }

      &_4 {
        background-color: var(--bg-seat-price-4);
        border-color: var(--bg-seat-price-4);
      }

      &_4.sm__seat_occupied {
        color: var(--bg-seat-price-4);

        &::after {
          background: var(--bg-seat-price-4);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-4);
        }
      }

      &_5 {
        background-color: var(--bg-seat-price-5);
        border-color: var(--bg-seat-price-5);
      }

      &_5.sm__seat_occupied {
        color: var(--bg-seat-price-5);

        &::after {
          background: var(--bg-seat-price-5);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-5);
        }
      }

      &_6 {
        background-color: var(--bg-seat-price-6);
        border-color: var(--bg-seat-price-6);
      }

      &_6.sm__seat_occupied {
        color: var(--bg-seat-price-6);

        &::after {
          background: var(--bg-seat-price-6);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-6);
        }
      }

      &_7 {
        background-color: var(--bg-seat-price-7);
        border-color: var(--bg-seat-price-7);
      }

      &_7.sm__seat_occupied {
        color: var(--bg-seat-price-7);

        &::after {
          background: var(--bg-seat-price-7);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-7);
        }
      }

      &_8 {
        background-color: var(--bg-seat-price-8);
        border-color: var(--bg-seat-price-8);
      }

      &_8.sm__seat_occupied {
        color: var(--bg-seat-price-8);

        &::after {
          background: var(--bg-seat-price-8);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-8);
        }
      }

      &_11 {
        background-color: var(--bg-seat-price-8);
        border-color: var(--bg-seat-price-8);
      }

      &_11.sm__seat_occupied {
        color: var(--bg-seat-price-8);

        &::after {
          background: var(--bg-seat-price-8);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-8);
        }
      }

      &_st5 {
        background-color: var(--bg-seat-price-st5);
        border-color: var(--bg-seat-price-st5);
      }

      &_st5.sm__seat_occupied {
        color: var(--bg-seat-price-st5);

        &::after {
          background: var(--bg-seat-price-st5);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st5);
        }
      }

      &_st3 {
        background-color: var(--bg-seat-price-st3);
        border-color: var(--bg-seat-price-st3);
      }

      &_st3.sm__seat_occupied {
        color: var(--bg-seat-price-st3);

        &::after {
          background: var(--bg-seat-price-st3);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st3);
        }
      }

      &_bas {
        background-color: var(--bg-seat-price-bas);
        border-color: var(--bg-seat-price-bas);
      }

      &_bas.sm__seat_occupied {
        color: var(--bg-seat-price-bas);

        &::after {
          background: currentColor;
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-bas);
        }
      }

      &_sta {
        background-color: var(--bg-seat-price-sta);
        border-color: var(--bg-seat-price-sta);
      }

      &_sta.sm__seat_occupied {
        color: var(--bg-seat-price-sta);

        &::after {
          background: var(--bg-seat-price-sta);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-sta);
        }
      }

      &_0l8 {
        background-color: var(--bg-seat-price-0l8);
        border-color: var(--bg-seat-price-0l8);
      }

      &_0l8.sm__seat_occupied {
        color: var(--bg-seat-price-0l8);

        &::after {
          background: var(--bg-seat-price-0l8);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-0l8);
        }
      }

      &_cmf {
        background-color: var(--bg-seat-price-cmf);
        border-color: var(--bg-seat-price-cmf);
      }

      &_cmf.sm__seat_occupied {
        color: var(--bg-seat-price-cmf);

        &::after {
          background: var(--bg-seat-price-cmf);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-cmf);
        }
      }

      &_0l9 {
        background-color: var(--bg-seat-price-0l9);
        border-color: var(--bg-seat-price-0l9);
      }

      &_0l9.sm__seat_occupied {
        color: var(--bg-seat-price-0l9);

        &::after {
          background: var(--bg-seat-price-0l9);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-0l9);
        }
      }

      &_stp {
        background-color: var(--bg-seat-price-stp);
        border-color: var(--bg-seat-price-stp);
      }

      &_stp.sm__seat_occupied {
        color: var(--bg-seat-price-stp);

        &::after {
          background: var(--bg-seat-price-stp);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-stp);
        }
      }

      &_spf {
        background-color: var(--bg-seat-price-spf);
        border-color: var(--bg-seat-price-spf);
      }

      &_spf.sm__seat_occupied {
        color: var(--bg-seat-price-spf);

        &::after {
          background: var(--bg-seat-price-spf);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-spf);
        }
      }

      &_psa {
        background-color: var(--bg-seat-price-psa);
        border-color: var(--bg-seat-price-psa);
      }

      &_psa.sm__seat_occupied {
        color: var(--bg-seat-price-psa);

        &::after {
          background: var(--bg-seat-price-psa);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-psa);
        }
      }

      &_prs {
        background-color: var(--bg-seat-price-prs);
        border-color: var(--bg-seat-price-prs);
      }

      &_prs.sm__seat_occupied {
        color: var(--bg-seat-price-prs);

        &::after {
          background: var(--bg-seat-price-prs);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-prs);
        }
      }

      &_pfs {
        background-color: var(--bg-seat-price-pfs);
        border-color: var(--bg-seat-price-pfs);
      }

      &_pfs.sm__seat_occupied {
        color: var(--bg-seat-price-pfs);

        &::after {
          background: var(--bg-seat-price-pfs);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-pfs);
        }
      }

      &_0nk {
        background-color: var(--bg-seat-price-0nk);
        border-color: var(--bg-seat-price-0nk);
      }

      &_0nk.sm__seat_occupied {
        color: var(--bg-seat-price-0nk);

        &::after {
          background: var(--bg-seat-price-0nk);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-0nk);
        }
      }

      &_st7 {
        background-color: var(--bg-seat-price-st7);
        border-color: var(--bg-seat-price-st7);
      }

      &_st7.sm__seat_occupied {
        color: var(--bg-seat-price-st7);

        &::after {
          background: var(--bg-seat-price-st7);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st7);
        }
      }

      &_st6 {
        background-color: var(--bg-seat-price-st6);
        border-color: var(--bg-seat-price-st6);
      }

      &_st6.sm__seat_occupied {
        color: var(--bg-seat-price-st6);

        &::after {
          background: var(--bg-seat-price-st6);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st6);
        }
      }

      &_pss {
        background-color: var(--bg-seat-price-pss);
        border-color: var(--bg-seat-price-pss);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_pss.sm__seat_occupied {
        color: var(--bg-seat-price-pss);

        &::after {
          background: var(--bg-seat-price-pss);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-pss);
        }
      }

      &_st4 {
        background-color: var(--bg-seat-price-st4);
        border-color: var(--bg-seat-price-st4);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_st4.sm__seat_occupied {
        color: var(--bg-seat-price-st4);

        &::after {
          background: var(--bg-seat-price-st4);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st4);
        }
      }

      &_pps {
        background-color: var(--bg-seat-price-pps);
        border-color: var(--bg-seat-price-pps);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_pps.sm__seat_occupied {
        color: var(--bg-seat-price-pps);

        &::after {
          background: var(--bg-seat-price-pps);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-pps);
        }
      }

      &_st2 {
        background-color: var(--bg-seat-price-st2);
        border-color: var(--bg-seat-price-st2);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_st2.sm__seat_occupied {
        color: var(--bg-seat-price-st2);

        &::after {
          background: var(--bg-seat-price-st2);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st2);
        }
      }

      &_ob5 {
        background-color: var(--bg-seat-price-ob5);
        border-color: var(--bg-seat-price-ob5);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_ob5.sm__seat_occupied {
        color: var(--bg-seat-price-ob5);

        &::after {
          background: var(--bg-seat-price-ob5);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-ob5);
        }
      }

      &_st1 {
        background-color: var(--bg-seat-price-st1);
        border-color: var(--bg-seat-price-st1);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_st1.sm__seat_occupied {
        color: var(--bg-seat-price-st1);

        &::after {
          background: var(--bg-seat-price-st1);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-st1);
        }
      }

      &_frf {
        background-color: var(--bg-seat-price-frf);
        border-color: var(--bg-seat-price-frf);
        color: #000;

        & > b {
          color: #000;
        }

        &::after {
          color: #fff;
        }
      }

      &_frf.sm__seat_occupied {
        color: var(--clr-seat-price-frf);

        &::after {
          background: var(--bg-seat-price-frf);
        }

        .sm__stuff::before,
        .sm__stuff::after,
        .sm__stuff_in::before,
        .sm__stuff_in::after {
          background-color: var(--bg-seat-price-frf);
        }
      }

      &_unavailable {
        cursor: default;
        color: var(--color-disabled);
        border: 1px solid var(--color-disabled);

        & > b { color: inherit; }
      }

      &_occupied {
        cursor: default;
        background: #fff;

        & > b { color: inherit; }

        &__pointer:hover {
          cursor: pointer;
        }
      }

      &_non-selectable {
        cursor: default;
      }
    }

    &-passengers {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;
      width: auto;
      flex-grow: 1;
      flex-basis: 0;

      .sm__seat_occupied {
        &:after {
          position: absolute;
          top: calc(100% + 2px);
          left: 0;
          content: '';
          display: block;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          background-color: currentColor;
          border: none;
        }
      }
    }

    &-legends + &-passengers {
      margin-top: 12px;
    }

    &-note {
      margin: 0 0 10px;
      padding: 0;
      list-style-position: inside;
    }

    &-note__item {
      color: #666;
      font-size: 9px;
      text-transform: uppercase;
    }

    .plane_deck-upper.sm-deck-item_active ~ &-note,
    .plane_deck-upper.sm-deck-item_active ~ &-legends,
    .plane_deck-upper.sm-deck-item_active ~ &-passengers {
      margin-left: 36px;
    }

    &__psgr {
      &-h {
        display: inline-block;
        width: 100%;
        margin: 0 0 10px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        color: #666;
      }

      &-itm {
        position: relative;
        background-color: #eee;
        border: 2px solid transparent;
        margin: 0 0 5px 0;
        padding: 6px;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        min-width: 278px;

        &_seated {
          background-color: #eee;
          color: #000;
        }

        &_active {
          border-color: var(--brand-highlight);
        }

        .sm__seat_unavailable {
          background: #fff;
          border: 0;
        }

        .tag-group {
          display: inline-flex;
          flex-shrink: 0;
          flex-grow: 1;
          justify-content: flex-end;
          margin: 0 5px;
        }
      }

      &-itm:last-child {
        margin-bottom: 0;
      }

      &-name {
        display: inline-block;
        line-height: normal;
        width: 180px;
        margin-left: 0;
        font-size: 11px;
        margin-right: 10px;
      }

      &-card {
        max-width: 215px;
        font-size: 11px;
      }

      &-locator {
        font-weight: bold;
        cursor: pointer;
        border-bottom: 1px dashed;
        font-size: 11px;
        line-height: 12px;
      }

      &-locator:hover {
        border-bottom-color: transparent;
      }

      &-place {
        &:first-child,
        &:last-child {
          margin-bottom: 0;
        }
      }

      &-seat {

        &::after {
          display: none;
        }
      }
    }

    &__psgr-list {
      border-left: none;
      padding-right: 5px;
      overflow-y: auto;

      .sm__psgrs-wrap {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    &__psgr-list::-webkit-scrollbar {
      width: 9px;
    }

    &__filter {
      display: flex;
      flex-direction: column;

      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      &-h {
        font-size: 12px;
        font-weight: bold;
        color: #666;
        margin: 0 20px 0 0;
      }

      &-itm {
        .check {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
        }

        .check__box {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }

        .check__text {
          font-weight: normal;
          display: inline-flex;
          flex-direction: column;
        }

        &-code {
          font-weight: bold;
          font-size: 10px;
          line-height: 11px;
        }

        &-text {
          display: inline-block;
          color: #666;
          text-transform: uppercase;
          font-size: 10px;
          line-height: 10px;
        }
      }

      &-aside {
        display: flex;
        align-items: flex-start;

        .sm__filter-itm {
          margin-right: 10px;
        }

        .sm__filter-itm:last-child {
          margin-right: 0;
        }

        .sm__filter-itm-btn {
          font-size: 10px;
          font-weight: bold;
          padding: 0;
          background-color: transparent;
          text-transform: uppercase;
          border-bottom-style: dashed;
          border-bottom-color: inherit;
          border-width: 0 0 1px;
          color: var(--brand-highlight);
          cursor: pointer;

          &:hover {
            border-color: transparent;
            color: unset;
          }
        }

        .sm__shown-filters-popup {
          .sm__filter-itm-btn {
            display: inline-block;
            line-height: 1.15;

            .select__control {
              height: inherit;
              padding: 0 14px 0 0;
              border: none;
              color: currentColor;
            }

            .select__tick {
              border-color: currentColor;
            }
          }
        }
      }
    }

    &__filter-group {
      margin: 0 0 10px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      > .sm__filter-itm {
        margin-bottom: 8px;
      }

      > .sm__filter-itm:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sm-pop {
    position: fixed;
    z-index: 10000;
    background: #fff;
    border-radius: 5px;
    padding: 8px 12px;
    width: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .4);

    .page_with-sidebar & {
      @media (min-width: 1540px) {
        z-index: 100;
      }
    }

    &__price {
      font-size: 12px;
      font-weight: bold;
      color: #47a3ff;
      text-transform: uppercase;
      font-style: normal;
      display: inline-block;
      margin-bottom: 5px;
    }

    &__blocking {
      padding-left: 0;
      list-style-type: none;
    }

    &__blocking-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &::before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: 12px;
        height: 4px;
        background-color: #727272;
        margin-right: 10px;
      }

      em {
        font-style: normal;
        color: #adadad;
        font-weight: bold;
      }
    }

    &__blocking-item:last-child {
      margin-bottom: 0;
    }

    &__blocking-item-data-wrap {
      flex-grow: 1;
      line-height: normal;
    }

    &__arrow {
      position: absolute;
      border-style: solid;
      left: -10px;
      top: 20px;
      transform: translateY(-50%);
      border-width: 8px 10px 8px 0;
      border-color: transparent #fff transparent transparent;
    }
  }

  .sm-pop__header-name {
    margin: 0 0 5px;
  }

  .sm-pop__price-name {
    color: #666;
  }

  .sm-por__price-pnr-locator {
    text-decoration: none;
  }

  .sm-pop__blocking-item-data-desc {
    font-size: 9px;
    text-transform: uppercase;

  }

  .sm__row_near-division .sm-pop[x-placement="left"] {
    right: 94%;
  }

  .sm__unselect {
    display: none;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    color: #666;
    z-index: 10;

    &:hover {
      color: #000;
      text-shadow: 0 1px #fff;
    }
  }

  .pnr-show-badge & {
    .sm__psgr-itm_selected .sm__unselect {
      display: inline-block;
      text-decoration: none;
    }
  }

  .sm__wrap {
    display: inline-flex;
    align-items: center;
    flex-grow: 1;

    .sm__place {
      padding: 0;
      margin-right: 6px;
      width: 26px;
      height: 26px;
    }

    &_infant {
      .sm__seat {
        display: none;
      }

      .sm__psgr-place {
        height: 20px;
      }
    }
  }

  .sm-deck-legend-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
  }

  .sm-deck-legend-wrap__header {
    margin-bottom: 20px;
  }

  .sm-flight-info {
    display: inline-flex;
    align-items: baseline;
    margin: 0;
    color: #666;
    border-bottom: 1px dashed #666;
  }

  .sm-flight-info:hover {
    border-color: transparent;
  }

  .sm-flight-info__date {
    font: bold 14px var(--font-family-sans-serif-arial);
    text-transform: uppercase;
  }

  .sm-flight-info__ak-flight {
    font: bold 14px var(--font-family-sans-serif-arial);
    margin: 0 5px;
  }

  .sm-flight-info__dest {
    font: bold 14px var(--font-family-sans-serif-arial);
  }

  .screen__name-title {
    white-space: nowrap;
  }

  .page_with-sidebar & {
    .pager-centered-wrap__header {
      max-width: 28%;
    }

    .sm__pager {
      position: static;
      transform: none;

      @media(min-width: 1370px) {
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }

    .pager-centered.sm__pager {
      top: calc(50% + 7px);
      left: calc(50% - 6px);
    }
  }

  .st__codes-itm {
    line-height: 9px;
    font-size: 10px;
    text-transform: lowercase;
  }

  .sm__seat-n {
    line-height: 10px;
  }
}
