.pwd-config-top__headers-wrap {
  background-color: #aaa;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.625rem;
}

.pwd-config-top__controls {
  display: grid;
}

.pwd-config-top__extra-wrap {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.875rem;
  justify-content: start;
  align-items: start;
}

.pwd-config-top__extra-wrap_centered {
  align-items: center;
  padding: 0 0.625rem;
}

.pwd-config-header__search-field {
  outline: none;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #333;
  min-width: 258px;
  background-image: url(tais-styles/svg/icon-search.svg);
  background-repeat: no-repeat;
  background-position: 0.125rem 50%;
}

.pwd-config-top__group-colors {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.pwd-config-top__group-color-item {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.625rem;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-style: solid;
  border-color: transparent;
  border-width: 0.125rem 0.125rem 0;
}

.pwd-config-top__group-color-item:hover {
  .pwd-config-top__color {
    border-color: blue;
  }

  .pwd-config-top__group-color-text {
    color: blue;
  }
}

.pwd-config-top__group-color-item_selected {
  background-color: #aaa;
  border-color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  position: relative;
  top: 2px;
  cursor: default;

  .pwd-config-top__color {
    border-color: #000 !important;
  }

  .pwd-config-top__group-color-text {
    color: #000 !important;
    display: inline-block;
    margin-top: -4px;
  }
}

.pwd-config-top__group-color-item_selected:first-child {
  border-left-color: transparent;
}

.pwd-config-top__group-color-text {
  text-transform: uppercase;
  font-style: normal;
  font-size: 0.6875rem;
  font-weight: bold;
  line-height: 0.625rem;
}

.pwd-config-top__checkbox-wrap {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1.25rem;
  margin: 0.625rem 0;
}

.pwd-config-top__checkbox-item {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.3125rem;
  align-items: center;
}

.pwd-config-top__checkbox-label {
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
}

.pwd-config-top__checkbox-input {
  margin: 0 4px 0 0;
}

.pwd-config-header__search-field::placeholder {
  color: #999;
  font-style: italic;
  font-size: 0.8125rem;
}

.pwd-config-top__headers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  border-top: 2px solid #fff;
}

.pwd-config-top__headers-after,
.pwd-config-top__headers-before {
  grid-template-columns: repeat(3, 1fr) .5fr;
}

.pwd-config-top__section-head {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: bold;
  white-space: nowrap;
  margin: 0;
}

.pwd-config-top__color {
  width: 34px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 0.1875rem;
}

.pwd-config-top__color_has-error {
  background-color: rgba(221, 0, 0, .5);
}

.pwd-config-top__color_no-beta {
  background-color: rgba(255, 233, 211, 1);
}

.pwd-config-top__color_no-stable {
  background-color: rgba(243, 168, 168, .5);
}

.pwd-config-top__color_on-login-changed {
  background-color: rgba(255, 165, 0, .3);
}

.pwd-config-top__color_local-changed {
  background-color: rgba(255, 165, 0, .8);
}

.pwd-config-top__color_all {
  background-color: transparent;
}

.pwd-config-top__color_different {
  background-color: rgba(194, 194, 194, 1);
}

.pwd-config-header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.pwd-config-header__wrapper {
  display: flex;
  gap: 30px;
}

.pwd-config-header__search {
  justify-self: end;
}

.pwd-config-header__search-label {
  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 0.8125rem;
}

.pwd-config-top__columns-wrap {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  background: #aaa;
  padding: 0.625rem 1.25rem;
  border-bottom: 2px solid #999;
}

.pwd-config-top__column-title {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
}

.pwd-config-top__buttoons-wrap {
  display: grid;
  grid-column-gap: 0.625rem;
  grid-auto-flow: column;
}
