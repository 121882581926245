.br-modal {
  --modal-header-text-color: #666666;
  --modal-z-index: 1;
}

.br-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--modal-z-index);

  display: flex;
  justify-content: center;
  align-items: center;
}

/*для не правильной последовательности подключения стилей*/
.br-help-complicate {
  z-index: var(--z-above-content);
}

.br-modal__dimmer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(55, 55, 55, 0.5);
}

.br-modal__wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  max-width: calc( 90vw - var(--stack-panel-width) );
  max-height: 90vh;
  padding: 1.25rem;

  background-color: #ffffff;
  border-radius: 1.5rem;
}

.br-modal__close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;

  width: 1.25rem;
  height: 1.25rem;

  cursor: pointer;
}

.br-modal__header {
  padding: 0 1.875rem 1rem 0;
  font-weight: bold;
  font-size: 0.875rem;
  color: var(--modal-header-text-color);
  text-transform: uppercase;
}

.br-modal__body {
  overflow-y: auto;

  scrollbar-width: thin;
}

.br-modal__body::-webkit-scrollbar {
  width: 0.5rem;
}

.br-modal__subheader {
  padding-bottom: 1rem;

  font-size: 0.875rem;
  text-align: center;
  color: var(--modal-header-text-color);
  text-transform: uppercase;
}

.br-modal__footer {
  padding-top: 1rem;

  font-size: 0.875rem;
  text-align: center;
  color: var(--modal-header-text-color);
  text-transform: uppercase;
}

.br-modal__error {
  color: var(--brand-warning);
  font-size: 0.75rem;
  font-weight: bold;
  text-align: left;
}
