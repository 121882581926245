/*
* --------------------------------------------------
* This file contains all styles related to the select component.
* --------------------------------------------------
*/

/* default select settings */

.vc-select {
  --select-border-radius: 0;
  --select-text-transform: uppercase;

  --select-color-normal: #000000;
  --select-color-primary: var(--brand-highlight);
  --select-color-error: #ff0000;
  --select-color-success: #16a803;
  --select-color-changed: var(--brand-changed);
  --select-color-disabled: rgba(0,0,0, 0.26);

  --select-label-color-normal: #999999;
  --select-label-color-error: #ff0000;

  --select-border-color-normal: #cccccc;

  --select-font-size-small: 0.875rem;
  --select-font-size-default: 1rem;
  --select-font-size-large: 1.125rem;
}

.vc-select {
  position: relative;

  display: inline-block;
  max-width: 100%;
  padding-top: 0.75em;

  font-size: var(--select-font-size-default);
  line-height: 1.3;
  text-align: left;
}

.vc-select_label-down {
  padding-top: 0;
  padding-bottom: 0.75em;
}

.vc-select_font_s {
  font-size: var(--select-font-size-small);
}

.vc-select_font_l {
  font-size: var(--select-font-size-large);
}

.vc-select_uppercase {
  text-transform: uppercase;
}

.vc-select_full-width {
  width: 100%;
}

.vc-select_height_s {
}

.vc-select_height_l {
}

.vc-select__label {
  position: absolute;
  top: 0.75em;
  right: auto;
  left: 0;

  display: block;
  min-height: 0.5em;
  max-height: 1.5em;
  padding: 0.25em 0.125rem;
  overflow: hidden;

  font-size: inherit;
  color: var(--select-label-color-normal);
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;

  transition: 0.3s cubic-bezier(0.25,0.8,0.5,1);

  pointer-events: none;
}

.vc-select__label_active {
  top: 0;
  bottom: auto;

  font-size: 0.6em;
}

.vc-select_label-down .vc-select__label {
  top: auto;
  right: auto;
  bottom: 0.75em;
  left: 0;
}

.vc-select_label-down .vc-select__label_active {
  top: auto;
  bottom: 0;
}

.vc-select_label-left {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
}

.vc-select__label-left {
  position: relative;

  display: inline-block;
  margin-right: 0.875rem;
  margin-bottom: 0.125rem;

  font-weight: normal;
  font-size: 0.625rem;
  line-height: 1.4;
  color: #525252;
  text-transform: uppercase;
}

.vc-select__box {
  position: relative;

  display: block;
}

.vc-select__control {
  max-height: 1.5em;
  padding: 0.125em 0.875rem 0.25em 0;

  font-size: inherit;
  line-height: 1.25;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  min-width: 0;
  max-width: 100%;

  font-weight: bold;
  text-align: center;
  text-overflow: ellipsis;

  background-color: transparent;
  border: solid var(--select-border-color-normal);
  border-width: 0 0 1px;

  -webkit-appearance: none;
  appearance: none;
}

.vc-select__control option {
  cursor: pointer;
}

.vc-select_uppercase .vc-select__control {
  text-transform: uppercase;
}

.vc-select__control_emplty {
  color: var(--select-label-color-normal);
}

.vc-select_primary .vc-select__control {
  color: var(--select-color-primary);
}

.vc-select_success .vc-select__control {
  color: var(--select-color-success);
}

.vc-select_required .vc-select__control {
  border-bottom-color: var(--select-color-primary);
}

.vc-select_changed  .vc-select__control {
  color: var(--select-color-changed);
}

.vc-select__toggler {
  position: absolute;
  top: calc(50% - 0.5em);
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.75rem;
  height: 1em;
  padding: 0;

  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;

  pointer-events: none;
}

.vc-select__toggler-icon {
  color: #999999;
}

.vc-select_error {
  .vc-select__label {
    color: var(--select-color-error);
  }

  .vc-select__control {
    color: var(--select-color-error);

    border-bottom-color: currentColor;
  }
}

.vc-select_text .vc-select__control {
  border-bottom-color: transparent;
}

.vc-select__error {
  font-size: 0.75rem;
  color: var(--select-color-error);
}

.vc-select_disabled {
  cursor: default;
  opacity: 0.5;
}

.vc-select__option {
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
}

.vc-select_left-align .vc-select__control {
  text-align: left;
}

.vc-select_right-align .vc-select__control {
  text-align: right;
}

.vc-select_no-outline .vc-select__control {
  outline: none;
}
