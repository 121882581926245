<template>
  <div class="tais-vue-popup__curtain"
    ref="curtain"
    :style="{'top':opScrollTop}"
    tabindex="0"
    @click.self="$emit('curtain-click')"
    @keydown.esc="$emit('curtain-click')"
    >
    <div class="tais-vue-popup__popup" :class="popupClass">
      <slot />
    </div>
  </div>
</template>

<script>
"use strict";

var classes = {
  body: "page"
};

module.exports = {
  props: {
    popupClass: {
      type: String
    }
  },

  data: function() {
    return {
      opScrollTop: "0px"
    };
  },

  methods: {
    getTopOffset: function() {
      var b = this.$el.closest("." + classes.body);

      if (b && typeof b.scrollTop === "number") {
        this.opScrollTop = b.scrollTop + "px";
      } else {
        this.opScrollTop = "0px";
      }

      return b;
    }
  },

  mounted: function() {
    var b = this.getTopOffset();

    if (b) {
      b.style.overflowY = "hidden";
    }

    window.addEventListener('resize', this.getTopOffset);

    if (this.$refs.curtain && typeof this.$refs.curtain.focus === "function") {
      this.$refs.curtain.focus();
    }
  },

  beforeDestroy: function() {
    var b = document.querySelectorAll("." + classes.body);
    for (var i = 0; i < b.length; i++) {
      b[i].style.removeProperty("overflow-y");
    }
    window.removeEventListener('resize', this.getTopOffset);
  }
};
</script>

<style scoped>
.tais-vue-popup__curtain {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height:100%;
  overflow: auto;
  background: rgba(0,0,0,0.5);
  z-index: var(--z-index-popup);
  scrollbar-width: thin;
}

.tais-vue-popup__popup {
  max-width: 800px;
  margin: 20px auto 40px;
  background-color: #fff;
  border: 1px solid #444;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 9px;
}

.tais-vue-popup__popup_wider {
  max-width: 1000px;
}
</style>
