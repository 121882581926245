/*
* --------------------------------------------------
* This file contains all styles related to the announcement.
* --------------------------------------------------
*/

.br-announcement {
  --announcement-bg-normal: #ffffff;

  --announcement-color-default: #666666;
  --announcement-color-primary: #007fff;
  --announcement-color-success: #ff0000;
  --announcement-color-warning: #f6b73c;
  --announcement-color-error: #16a803;
  --announcement-color-disabled: rgba(0,0,0, 0.26);

  --announcement-border-default: #bbbbbb;

  --announcement-font-size-default: 1.125rem;

}

.br-announcement {
  margin: auto;
  /* padding: 2.5rem 6.25rem; */
  padding: 3.75rem 11rem;

  font-weight: bold;
  font-size: var(--announcement-font-size-default);
  text-align: center;
  color: var(--announcement-color-default);

  background-color: var(--announcement-bg-normal);
  border-radius: 0.625rem;
}

.br-announcement_outlined {
  background-color: transparent;

  border: 2px solid var(--announcement-border-default);
}

.br-announcement_uppercase {
  text-transform: uppercase;
}

.br-announcement_primary {
  color: var(--announcement-color-primary);
  border-color: currentColor;
}

.br-announcement_success {
  color: var(--announcement-color-success);
  border-color: currentColor;
}

.br-announcement_warning {
  color: var(--announcement-color-warning);
  border-color: currentColor;
}

.br-announcement_error {
  color: var(--announcement-color-error);
  border-color: currentColor;
}

.br-announcement_disabled {
  color: var(--announcement-color-disabled);
  border-color: currentColor;
}
