/*
* --------------------------------------------------
* This file contains all styles related to the tooltip-portal component.
* --------------------------------------------------
*/

/* default tooltip settings */
.br-tooltip-portal,
.br-tooltip-portal__alignment {
  --tooltip-animation-duration: 0.2s;
  --tooltip-bg: #666666;
}

.br-tooltip-portal__activator {
  min-width: 1rem;
  min-height: 1rem;
}

.br-tooltip-portal__alignment {
  --arrow-shift: 0px;
  position: absolute;
  z-index: 7001;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: tooltip-portal-bottom;
  animation-duration: var(--tooltip-animation-duration);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  /* pointer-events: none; */
}

.br-tooltip-portal__body {
  position: relative;
  background-color: var(--tooltip-bg);
  border-radius: 0.3125rem;
  padding: 0.5rem;
  font-size: 0.75rem;
  color: #ffffff;
}

.br-tooltip-portal__body.bottom {
  margin-top: 0.625rem;
}

.br-tooltip-portal__body.top {
  margin-bottom: 0.625rem;
}

.br-tooltip-portal__body.bottom:before {
  content: '';
  position: absolute;
  display: block;
  top: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-bottom: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;

  transform: translateX(var(--arrow-shift));
}

.br-tooltip-portal__body.top:before {
  content: '';
  position: absolute;
  display: block;
  bottom: -0.5625rem;
  left: calc(50% - 0.625rem);
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid var(--tooltip-bg);
  border-left: 0.625rem solid transparent;

  transform: translateX(var(--arrow-shift));
}

@keyframes tooltip-portal-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
