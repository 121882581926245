.rbd-selector-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.rbd-selector-popup-content {
  display: flex;
  position: absolute;
  max-width: 700px;
  min-height: 130px;
  max-height: 70%;
  background-color: white;
  padding: 20px 52px 20px 20px;
  border-radius: 24px;
  margin-top: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.rbd-selector-popup-content_top::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color:  white transparent transparent transparent;
}

.rbd-selector-popup-content_bottom::before {
  content: '';
  position: absolute;
  top: -1.2rem;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.rbd-selector-popup__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-top-right-radius: 1.25rem;
  width: 3.75rem;
  height: 3.75rem;
  background-color: #000;
  cursor: pointer;
  mask-image: url('tais-styles/svg/twb-cross-slim.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 1.25rem;
  transition-property: background-color;
  transition-duration: .2s;
  &:hover {
    background-color: var(--brand-highlight);
    transition-duration: .1s;
  }
}

.rbd-selector-book-codes-selector-portal {
  width: 100%;
}

.rbd-selector-book-codes-selector-list__btn {
  width: 1.8rem;
  height: 1.8rem;
  background: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid #fff;
}

.rbd-selector-book-codes-selector-list__btn:hover {
  background: var(--brand-highlight);
  border: 2px solid var(--brand-highlight);
  color: #fff
}

.rbd-selector-book-codes-selector-list__btn-active {
  background: var(--brand-highlight);
  border: 2px solid var(--brand-highlight);
  color: #fff
}

.rbd-selector-book-codes {
  display: grid;
  grid-template-columns: 1.5fr 3fr;
}

.rbd-selector-business-codes {
  margin-bottom: 0.7rem;
}

.rbd-selector-book-codes-selector__title {
  text-transform: uppercase;
  display: flex;
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
  padding-top: 0.4rem;
}

.rbd-selector-book-codes-selector-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  padding-left: 1.5rem;
}

.rbd-selector-book-codes-selector-list__item {
  list-style-type: none;
}

.rbd-selector-book-codes-selector-portal__body {
  margin-top: 1rem;
}

@keyframes selector-portal-bottom {
  0% {
    transform: translate(0, 0.5em);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.rbd-one-selector {
  color: var(--brand-highlight);
  cursor: pointer;
  font-weight: bold;
  font-size: 0.875rem;
}
