/*
size of the icon depends on the setting:
xsmall - 0.5rem / 8px x 8px
small - 0.75rem / 12px x 12px
default - 1rem / 16px x 16px
medium - 1.125rem / 18px x 18px
large - 1.375rem / 22px x 22px
xlarge - 1.5rem / 24px x 24px
*/
.br-icon-arr {
  display: block;
  width: 1em;
  height: 1em;

  font-size: inherit;
  color: inherit;
}

.br-icon-arr__icon {
  position: absolute;
  top: calc( 50% - 1px );

  width: 1em;
  height: 2px;

  background-color: currentColor;
}

.br-icon-arr__icon::after {
  content: '';
  position: absolute;
  top: calc( 50% - 0.33em );
  right: 1px;

  display: block;
  width: 0.67em;
  height: 0.67em;

  color: inherit;

  border: solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}
