/*
* --------------------------------------------------
* This file contains styles that are specific to the
* UPLOAD screen and it's elements.
* --------------------------------------------------
*/

/*
* UPLOAD TOURISTS SCREEN FOR TWTt
*/

.upload-buttons {
  display: flex;
  align-items: flex-start;

  padding: 1.875rem 1.875rem 0.875rem;

  .screen_panels-fixed & {
    padding-top: 0;
    padding-bottom: 0;

    transition: padding 0.2s ease;
    transform-origin: 50% 0 0;
  }

  &_init {
    padding-bottom: 0.5rem;
  }

  &__col {
    flex: 0 1 auto;
    width: 45%;
    margin-top: 0;
    text-align: center;
  }

  &__or {
    flex: 0 1 auto;
    width: 10%;
    padding-top: 0.9rem;
    margin-top: 0;

    font-weight: bold;
    color: var(--color-light);
    text-align: center;
  }

  &__link {
    margin-top: 0.3rem;
  }
}

.upload-buttons-footer {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding: 0.25rem 1.875rem;
}

.screen_upload {

  .passenger__info_main {
    flex-wrap: wrap;
    flex-basis: auto;
    flex-grow: 1;
    max-width: 54%;
  }

  .passenger__info_add {
    flex-basis: auto;
    flex-grow: 1;
    max-width: calc(46% - 10px);
  }

  min-width: 800px;

  & .tourists-list__errors_common{
    text-decoration: none;
    cursor: auto;
  }

  & .tourist-country {
    width: 2.5%;
  }

  & .tourist-name {
    white-space: normal;
    word-wrap: break-word;
    margin-right: 10px;
    flex-grow: 1;

    .input {
      padding-top: 0;
      margin-bottom: 10px;
    }
  }

  .tourist-contacts {
    display: flex;
  }

  .passenger_form {
    .tourist-name {
      width: 24%;
    }

    .tourist-contacts {
      display: block;
      margin-bottom: 10px;
    }
  }

  & .tourist-dob {
    min-width: 100px;
    margin-right: 10px;

    .input {
      padding-top: 0;
    }
  }

  & .tourist-doc {
    width: 11%;

    .input {
      padding-top: 0;
      display: flex;
      flex-direction: column;
    }

    .tourist-doc__type {
      position: static;
    }
  }

  & .tourist-country {
    margin-right: 10px;

    .input {
      padding-top: 0;
    }
  }

  & .tourist-doc-expire {
    width: 100px;

    .input {
      padding-top: 0;
    }
  }

  .passenger_form {
    .tourist-doc-expire {
      width: 80px;
    }
  }

  & .tourist-contacts:empty {
    display: none;
  }

  & .tourist-cat {
    width: auto;
    margin-right: 10px;
    min-width: 40px;
  }

  & .tourist-seat {
    margin: 0 10px 0 0;
    min-width: 58px;
  }

  & .tourist-flights {
    width: 15%;
  }

  & .radio-group_2els {
    min-width: 13%;
  }

  & .tourist-ticket {
    width: 12%;
  }

  & .tourists-export-xls {
    margin-right: 10px;
  }

  & .tourist-ssr-ico {
    min-width: 20px;
  }

  & .tourist-no-baggage {
    background-image: url("tais-styles/svg/ssr/XBAG-no-baggage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
  }

  & .file-dragover {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-top);
    transition: all 500ms;
    color: #fff;
    background-color: #4ca5ff;
    border-color: #4ca5ff;
  }

  & .uploadFile {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1300px) {
    .panel__info-flex {
      font-size: 10px;
    }
  }

  select::-ms-expand {
      display: none;
  }

  .tourist-badges {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .badge_adt {
      flex-shrink: 0;
      margin: 0 0 0 2px;
    }

    .ssr-upload {
      font-size: 11px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .ssr-upload:last-child {
      margin-bottom: 0;
    }

    .ssr-upload__label {
      text-align: left;
    }

    .tourist-ssr-forward,
    .tourist-ssr-reverse {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 1;
      min-width: calc(50% - 4px);
      max-width: calc(50% - 4px);
    }

    .tourist-ssr-reverse:empty {
      display: none;
    }

    .tourist-ssr-forward {
      margin-right: 8px;

      &:empty {
        display: none;
      }

      &.full-width {
        flex-basis: 100%;
      }
    }
  }

  .tourist-badges__ssr-wrap {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 32px);
    flex-direction: column;

    @media (min-width: 1400px) {
      flex-direction: row;
    }
  }

  .passenger_form {
    .tourist-badges {
      align-items: flex-start;
    }

    .tourist-badges__select {
      min-width: 77px;
    }

    .tourist-baggage {
      margin-top: 8px;
    }
  }

  .ssr-code {
    &__upload {
      margin: 0 8px;
      display: inline-flex;
      align-items: center;
    }
  }

  .ssr-subcode {
    &__upload {
      font-size: 60%;
      text-align: center;
      margin-top: -.3rem;
    }
  }

  .ssr-full-name {
    &__upload {
      text-align: left;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
    }
  }

  .remove-ssr {
    font-size: 24px;
    line-height: 15px;
    cursor: pointer;
    background: none;
    border-color: transparent;
    border-radius: 4px;
    padding: 1px 2px;
  }

  .remove-ssr:hover {
    border-color: var(--brand-warning);
  }

  & .popover__body {
    & .menu {
      margin: auto;
    }
  }

  .ssr-invalid {
    color: red;
  }

  .tourist-ssrs {
    margin-right: 10px;
    flex-grow: 1;
    flex-direction: column;

    @media(min-width: 1479px) {
      flex-direction: row;
    }
  }

  .passenger_form {
    .tourist-ssrs {
      margin-right: 10px;
      flex-grow: 1;
      flex-direction: column;

      .tourist-ssr-reverse {
        margin-top: 5px;
      }

      @media(min-width: 1479px) {
        flex-direction: row;

        .tourist-ssr-reverse {
          margin-top: 0;
        }
      }
    }
  }

  .segment-upload {
    display: inline-flex;
    margin: 0;
    padding: 16px;

    .panel__control_badge,
    .panel__info-flex,
    .segment__flight-info {
      padding: 0;
    }

    & > .flex {
      margin-bottom: 20px;
    }

    .panel__control_badge {
      width: auto;
      margin-right: 30px;
    }

    .segment__flight-info {
      margin-right: 30px;
      flex-basis: unset;
    }

    .segment__date {
      padding: 0;
      width: auto;
      margin-right: 30px;
    }

    .segment__departure {
      padding: 0;
      margin-right: 30px;
    }

    .segment__duration {
      margin: 0 30px 0 0;
    }

    .segment__arrival {
      margin-right: 30px;
      padding: 0;
    }

    .segment__notes {
      padding: 0;
      align-self: unset;
    }

    .flt-contract-num {
      margin-top: 0;
      white-space: nowrap;
      margin-bottom: 4px;
    }

    .panel__row_indent_badge {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tag-group {
        margin: 0;
        width: 46%;
      }
    }

    .panel__row {
      padding: 0;
    }
  }

  .screen__hide-on-fix {
    text-align: center;
  }

  .two-sides {
    display: flex;
    justify-content: space-between;

    .segment {
      width: calc(50% - 10px);
    }

    .panel__row_indent_badge {
      display: flex;
      justify-content: space-between;
    }

    .tag-group {
      width: calc(50% - 20px);
    }
  }

  .tourist-phones {
    padding: 0;
    margin-bottom: 4px;
    margin-right: 8px;
  }

  .tourist-emails {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .tourist-contact-info {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .tourist-contact-ico {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    vertical-align: middle;
    margin-right: 3px;

    &__phone {
      background-image: url(tais-styles/images/phone-call.svg);
    }

    &__email {
      background-image: url(tais-styles/svg/mail_27x19-grey.svg);
    }
  }

  .passenger_form {
    .tourist-contact-ico {
      display: none;
    }
  }

  .btn-remove-contact {
    font-size: 24px;
    line-height: 15px;
    cursor: pointer;
    background: none;
    border-color: transparent;
    border-radius: 4px;
    padding: 1px 2px;
    margin-right: 4px;
  }

  .btn-remove-contact:hover {
    border-color: var(--brand-warning);
  }

  .input__contact {
    font-weight: normal;
    font-size: 12px;
    width: 80%;
  }
}

.file-drop__zone {
  &-txt {
    display: inline-block;
  }
}
